import React from 'react';
import PropTypes from 'prop-types';
import MathJaxTranspiler from '../MathJax/MathJaxTranspiler'
/**
 * A react component used by the CbaRichTextField to display Mathematical expressions via MathJax.
 */
class MathJaxEntity extends React.Component {

  getPropertyData = (property) => {
    const { contentState, entityKey } = this.props;
    return contentState.getEntity(entityKey).getData()[property];
  }

  render() {
    const fontSize = this.getPropertyData("fontSize");
    const color = this.getPropertyData("fontColor");

    const style = {
      fontSize,
      color
    }

    return (
      <span style={style} suppressContentEditableWarning="true" contentEditable="false">
        <MathJaxTranspiler tex={this.getPropertyData("tex")} />
      </span>
    );
  }

}

const findMathJaxEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'TEX'
      );
    },
    callback
  );
}

MathJaxEntity.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
}

export { findMathJaxEntities, MathJaxEntity };
