import PropTypesHelper from '../components/PropTypesHelper';
import CommonConfigHelper from './CommonConfigHelper';

// TODO: add constant values for attributes

export default class AutoLayoutHelper {

  static buildStyleForAutoLayout(configProps, orientation, pathState) {

    const { autoLayoutLayer } = configProps;

    // split after the autoLayout keywords
    let result = {};

    switch (autoLayoutLayer) {
      case "panel": result = Object.assign(
        CommonConfigHelper.buildAbsoluteStyleFromConfig(configProps, orientation, pathState),
        AutoLayoutHelper.buildStyleForAutoLayoutPanel(configProps, orientation, pathState)
      );
        break;
      case "cell": result = AutoLayoutHelper.buildStyleForAutoLayoutCell(configProps);
        break;
      case "element": result = AutoLayoutHelper.buildStyleForAutoLayoutElement(configProps, orientation, pathState);
        break;
      case "panel-element": result = Object.assign(
        AutoLayoutHelper.buildStyleForAutoLayoutPanel(configProps, orientation, pathState),
        AutoLayoutHelper.buildStyleForAutoLayoutElement(configProps, orientation, pathState)
      );
        break;
      case "none":
        result = CommonConfigHelper.buildAbsoluteStyleFromConfig(configProps, orientation, pathState);
        break;
      default:
        // missing autoLayout value, probably using old/manual config. Using "none" case;
        result = CommonConfigHelper.buildAbsoluteStyleFromConfig(configProps, orientation, pathState);
        PropTypesHelper.raiseError("autoLayoutLayer value missing");
        break;
    }

    return result
  }

  static transformTemplateKeywords(keywords) {
    return keywords.map((keyword) => {

      if (keyword === "fill") return "auto";
      if (keyword.endsWith("%") || keyword.endsWith("px")) return keyword;
      if (keyword === "auto") return "min-content";

      return null;
    }).join(" ");
  }

  static buildStyleForAutoLayoutPanel(configProps, orientation, pathState) {

    const { rows, columns, blockAlignVertically, blockAlignHorizontally, gap } = configProps.autoLayout;

    return {
      display: "grid",
      gridTemplateRows: AutoLayoutHelper.transformTemplateKeywords(rows),
      gridTemplateColumns: AutoLayoutHelper.transformTemplateKeywords(columns),
      alignContent: blockAlignVertically,
      justifyContent: blockAlignHorizontally,
      rowGap: gap.row,
      columnGap: gap.column,
      // border: "1px solid tomato"
    };
  }

  static buildStyleForAutoLayoutCell(configProps) {

    const { columnStart, rowStart, columnSpan, rowSpan, alignVertically, alignHorizontally } = configProps.autoLayout;

    return {
      position: "relative",
      gridColumnStart: columnStart,
      gridRowStart: rowStart,
      gridColumnEnd: `span ${columnSpan}`,
      gridRowEnd: `span ${rowSpan}`,
      alignItems: AutoLayoutHelper.transformFlexAlignmentKeyword(alignVertically),
      justifyContent: AutoLayoutHelper.transformFlexAlignmentKeyword(alignHorizontally),
      display: "flex",
      // border: "1px dashed blue"
    }
  }

  static transformFlexAlignmentKeyword(alignment) {
    switch (alignment) {
      case "start": return "flex-start";
      case "end": return "flex-end";
      case "center":
      case "none":
      case "stretch":
        return alignment;
      default: return PropTypesHelper.raiseError("autoLayoutLayer -> cell alignment value invalid");
    }
  }

  static addHorizontalElementValues(resultStyle, configProps, useElementPosition, orientation, pathState) {
    const positionFromState = CommonConfigHelper.buildAbsoluteStyleFromConfig(configProps, orientation, pathState);

    if (useElementPosition) {
      resultStyle.left = positionFromState.left;
      resultStyle.right = positionFromState.right;
    }

    resultStyle.width = positionFromState.width;
  }

  static addVerticalElementValues(resultStyle, configProps, useElementPosition, orientation, pathState) {
    const positionFromState = CommonConfigHelper.buildAbsoluteStyleFromConfig(configProps, orientation, pathState);

    if (useElementPosition) {
      resultStyle.top = positionFromState.top;
    }

    resultStyle.height = positionFromState.height;
  }

  static buildStyleForAutoLayoutElement(configProps, orientation, pathState) {

    const { alignVertically, alignHorizontally } = configProps.autoLayout;

    const result = {
      position: "relative"
    };

    switch (alignVertically) {
      case "start":
      case "center":
      case "end": AutoLayoutHelper.addVerticalElementValues(result, configProps, false, orientation, pathState);
        break;
      case "none": AutoLayoutHelper.addVerticalElementValues(result, configProps, true, orientation, pathState);
        break;
      case "stretch": result.height = "100%";
        break;
      default: PropTypesHelper.raiseError("autoLayoutLayer -> alignVertically value invalid")

    }

    switch (alignHorizontally) {
      case "start":
      case "center":
      case "end": AutoLayoutHelper.addHorizontalElementValues(result, configProps, false, orientation, pathState);
        break;
      case "none": AutoLayoutHelper.addHorizontalElementValues(result, configProps, true, orientation, pathState);
        result.position = "relative";
        break;
      case "stretch": result.width = "100%";
        break;
      default: PropTypesHelper.raiseError("autoLayoutLayer -> alignHorizontally value invalid")
    }

    return result;
  }

}
