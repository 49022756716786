
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './media.css'

const MediaPreview = ({ type, stream, style, pauseRecording, resumeRecording, shouldPause }) => {
  const mediaRef = useRef();

  useEffect(() => {
    if (mediaRef.current && stream) {
      mediaRef.current.srcObject = stream;
    }
    if (mediaRef.current !== null && mediaRef.current !== undefined
      && shouldPause) {
      mediaRef.current.pause();
    }
  }, [stream, shouldPause]);
  if (!stream) {
    return null;
  }
  let media;
  if (type === "video") {
    media = (
      <video
        ref={mediaRef}
        style={style}
        onPause={pauseRecording}
        onPlay={resumeRecording}
        autoPlay
        controls
      />
    )
  } else {
    media = (
      <div style={style}>
        <audio
          ref={mediaRef}
          onPause={pauseRecording}
          onPlay={resumeRecording}
          autoPlay
          controls
          className="audio"
        />
        {!shouldPause && <span className="recording" />}
      </div>
    )
  }
  return media;
};

MediaPreview.propTypes = {
  stream: PropTypes.object,
  style: PropTypes.object.isRequired,
  pauseRecording: PropTypes.func.isRequired,
  resumeRecording: PropTypes.func.isRequired
}

MediaPreview.defaultProps = {
  stream: null
}

export default MediaPreview;
