import React from 'react';
import { EditorState, RichUtils, convertFromRaw, convertToRaw, DefaultDraftBlockRenderMap } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import PropTypes from 'prop-types';

import './css/Draft.css';
import './css/plugin.css';
import './css/custom.css';
import './css/repeatable.css';

import PropTypesHelper from '../PropTypesHelper';
import CommonActionsHelper from '../CommonActionsHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import SelectGroupHelper from '../SelectGroupHelper';
import RenderingHelper from '../RenderingHelper';
import IndexPathHelper from '../../state/IndexPathHelper';
import TermEvaluator from '../../eval/TermEvaluator';
import Utils from '../../utils/Utils';
import SelectionHelper from './SelectionHelper';
import PositionCalculationHelper from './PositionCalculationHelper';
import EditorStateHelper from './EditorStateHelper';
import DraftToolbarHighlightButton from './DraftToolbarHighlightButton';
import { DraftLink, findLinkEntities } from './DraftLink';
import { Media } from './Media'
import { findBulletEntities, DraftBullet } from './DraftBullet'
import StateManagerHelper from '../../state/StateManagerHelper';
import TableHelper from '../table/TableHelper';
import { findMathJaxEntities, MathJaxEntity } from './MathJaxEntity';

/**
 * A display component for rich text content.
 * 
 * We use this to implement the HTMLTextField and the RichText known on the Java side.
 * On the long run we hope to merge HTMLTextField and RichText on the Java side.
 * 
 * The component supports 'highlighting', 'embedded links' and 'content modifiers'.
 * 
 * An 'embedded link' is a selectable section of the text that will trigger a page switch on selection.
 * 
 * There are two types of 'content modifiers': 'EvaluatedInject' and 'DynamicFragment'. 
 * An 'Evaluated Inject' is a text snippet that we calculate at runtime as result of an operator call
 * and insert into the displayed text. 
 * A 'Dynamic Fragment' is a text snippet with a 'selected' status flag and a User Defined ID. We will display 
 * the text snippet as long as it is in 'selected'==true state. 
 * 
 * The 'content modifiers' and the 'embedded links' have their own index paths: 
 *  - embedded links append a pseudo index 1 and their own index in the list of content modifiers to the component's path, e.g.:
 *     <rich text field index path>/index=1/index=3 for the fourth content modifier of the component
 *  - content modifiers append a pseudo index 2 and their own index in the list of content modifiers to the component's path, e.g.:
 *     <rich text field index path>/index=2/index=7 for the eighth content modifier of the component
 * Lookups for these index paths in the component directory will return the rich text field component, i.e. re-rendering calls on 
 * these index paths are delegated to the rich text field component.
 * The page configurations manager will return configuration data for the embedded link paths (but not for the content modifiers)
 * for use by the DraftLink components. 
 * The component state manager will return state data for the embedded link index paths and the content modifier index paths. 
 * 
 */
export default class CbaRichTextField extends React.Component {

  constructor(props) {
    super(props);

    // build toolbar plugin (provides the 'highlight' button on touch screen devices)
    const inlineToolbarPlugin = createInlineToolbarPlugin({
      structure: [propsParam => <DraftToolbarHighlightButton {...propsParam} doHighlight={(event) => { this.onHighlightClick(event) }} />]
    });
    this.InlineToolbar = inlineToolbarPlugin.InlineToolbar;
    this.plugins = [inlineToolbarPlugin];
  }


  // ------------ lifecycle methods ---------------------------------------------------------------------------

  componentDidMount() {
    RenderingHelper.onMount(this);

    // In addition to registering myself I register myself as component to be re-rendered in lieu of all my content modifiers:
    const { config, path, row, column, runtime } = this.props;
    const editorComponent = this;
    CbaRichTextField.forEachContentModifier(config, path, (modifier, modifierPath) => {
      editorComponent.props.runtime.componentDirectory.registerComponent(modifierPath, editorComponent);
    });

    // register for deselect event
    if (row !== undefined && column !== undefined) {
      runtime.eventEmitter.addListener(`${TableHelper.buildTablePath(path)}-removeLastSelection-[${row},${column}]`, this.onRemoveLastSelection.bind(this));
    }

    // register for flash text events
    if (config.fromSecond !== undefined) {
      runtime.eventEmitter.addListener("currentTimeUpdate", this.onCurrentTimeUpdate.bind(this));
      runtime.eventEmitter.addListener("clearFlashTexts", this.onClearFlashTexts.bind(this));
    }
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);

    // Deregister myself as component to be re-rendered in lieu of all my content modifiers:
    const { config, path } = this.props;
    const editorComponent = this;
    CbaRichTextField.forEachContentModifier(config, path, (modifier, modifierPath) => {
      editorComponent.props.runtime.componentDirectory.deregisterComponent(modifierPath);
    });

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);

    // In addition to my own registration I update the registration of all my content modifiers:
    const editorComponent = this;

    const { config: oldConfig, path: oldPath } = this.props;
    CbaRichTextField.forEachContentModifier(oldConfig, oldPath, (modifier, modifierPath) => {
      editorComponent.props.runtime.componentDirectory.deregisterComponent(modifierPath);
    });

    const { config: newConfig, path: newPath } = nextProps;
    CbaRichTextField.forEachContentModifier(newConfig, newPath, (modifier, modifierPath) => {
      editorComponent.props.runtime.componentDirectory.registerComponent(modifierPath, editorComponent);
    });

  }

  // ------------ component state initialization ---------------------------------------------------------------------------


  /**
   * Build the initial state in the component state manager. 
   * 
   * The component state manager will call this method to initialize state for new display component instances.
   */
  static addAttributesToInitialState(initialState, configProps) {
    const styleMap = CbaRichTextField.findStylesMapInConfig(configProps);

    // deactivate deep copy in state manager since editor state cannot be cloned. 
    StateAttributeAccess.setDeepCopy(initialState, false);
    StateAttributeAccess.setComponentClassName(initialState, "CbaRichTextField");

    initialState.editorState = CbaRichTextField.buildInitialEditorState(configProps);

    // We keep the currently 'highlighted' areas in the text as 'selections' attribute in the component state. 
    // (In the editor state these areas have a 'HIGHLIGHTED' style setting which is a kind of duplication of the information here.)
    initialState.selections = [];

    // style map is not defined for HTMLTextField without any text content -> insert default value (highlight color is never used in this case)
    initialState.defaultHighlightColor = styleMap === undefined ? "rgba(0,0,0,0)" : styleMap.HIGHLIGHT.backgroundColor;

    initialState.modifiersInEditorState = {};

    // keep an unformatted representation of our text content in the component state
    StateAttributeAccess.setTextValue(initialState, CbaRichTextField.calculateTextValue(initialState.editorState));

    // keep highlightable flag in component state manager
    StateAttributeAccess.setHighlightable(initialState, configProps.highlightText);

  }

  /**
   * (Internal helper) Build an initial editor state for the Draft editor. 
   */
  static buildInitialEditorState(configProps) {
    const contentState = convertFromRaw(configProps.value);
    return EditorState.createWithContent(contentState);
  }


  /**
   * Add the specific pseudo index for 'embedded links' to the index path of the rich text field.  
   */
  static addLinkIndex(richTextFieldPageSegment) {
    return IndexPathHelper.appendIndexToPageSegment(richTextFieldPageSegment, 1);
  }

  /**
   * Add the specific pseudo index for 'content modifier children' to the index path of the rich text field.  
   */
  static addContentModifierIndex(richTextFieldPageSegment) {
    return IndexPathHelper.appendIndexToPageSegment(richTextFieldPageSegment, 2);
  }

  /**
   * Build an array of 'standard' configuration structures for the links defined 
   *  - in the editor state and
   *  - in the dynamic fragment content modifiers.
   * The page configurations manager expects a structure with the attributes type and config
   * when it adds configuration entries for the embedded link index paths.  
   */
  static buildLinkConfigurationsArray(configProps) {
    const result = [];
    CbaRichTextField.insertLinkConfigurationsFromEntityMap(configProps.value.entityMap, result);
    if (configProps.contentModifiers !== undefined) {
      configProps.contentModifiers.forEach((modifier) => {
        if (modifier.type === 'DynamicFragment') {
          CbaRichTextField.insertLinkConfigurationsFromEntityMap(modifier.config.entityMap, result);
        }
      })
    }
    return result;
  }


  /**
   * (Internal helper) Scan the given entity map from the Draft editor state and create an entry in the given 
   * result array for each entity of type 'LINK':
   *  - the key in the array is the entity's data.id attribute -> the data.id should be a number!
   *  - the value in the array is the usual config structure expected by the configurations manager: 
   *     { type: 'RichTextEmbeddedLink', config: { ... } }
   */
  static insertLinkConfigurationsFromEntityMap(entityMap, resultArray) {
    Object.values(entityMap).forEach((entity) => {
      if (entity.type === 'LINK') {
        resultArray[entity.data.id] = {
          type: 'RichTextEmbeddedLink',
          config: {
            state: {
              disabled: false,
              selected: false,
              hidden: false
            },
          }
        };
      }
    });
  }

  /**
   * Internal helper. Transforms from an 'HIGHLIGHT key to it's value
   * 
   * HIGHLIGHT_r_g_b_a -> rgba(r,g,b,a)
   * 
   * @param {String} key Highlight Key
   * @param {String} path RichTextComponent Path 
   * @param {Object} runtime runtime object
   * @returns {String} value
   */
  static convertHighlightKeyToValue(key, path, runtime) {
    const defaultHighlightColor = ComponentStateHelper.getStateAttributeByPathId(StateAttributeAccess.extractDefaultHighlightColor, path, runtime);

    const rgba = key.split('_').slice(1);
    const convertedValue = `rgba(${rgba.join(',')})`

    return key !== "HIGHLIGHT" ? convertedValue : defaultHighlightColor.replace(/\s+/g, '');
  }

  /**
   * Internal helper. Transforms from an rgba value to the HIGHLIGHT key
   * 
   * rgba(r,g,b,a) -> HIGHLIGHT_r_g_b_a
   * 
   * @param {String} value 
   * @returns {String} key
   */
  static convertHighlightValueToKey(value) {
    const rgba = value.substring(5, value.length - 1)
      .replace(/\s+/g, '')
      .split(',');

    return `HIGHLIGHT_${rgba.join('_')}`;
  }

  // ----------- snapshot related ------------------------------------------------------------------------

  /**
   * Transform the component state as stored in the component state manager into its snapshot representation.
   * 
   * The component state manager will call this method when creating a snapshot.
   */
  static toSnapshot(indexPath, state) {
    const result = {};
    Object.entries(state).forEach((entry) => {
      const [key, value] = entry;
      switch (key) {
        case 'editorState':
          result[key] = CbaRichTextField.toSnapshotForEditorState(value);
          break;
        default:
          result[key] = StateManagerHelper.deepCopy(value);
      }
    });
    return result;
  }


  /**
   * Transform the state in snapshot representation to the format used in the component state manager.
   * 
   * The component state manager will call this method when preloading state from a snapshot.
   */
  static fromSnapshot(indexPath, state) {
    const result = {};
    Object.entries(state).forEach((entry) => {
      const [key, value] = entry;
      switch (key) {
        case 'editorState':
          result[key] = CbaRichTextField.fromSnapshotForEditorState(value);
          break;
        default:
          result[key] = StateManagerHelper.deepCopy(value);
      }
    });
    return result;
  }

  /**
   * (Internal helper) Transform the given selections state into its snapshot representation.
   */
  static toSnapshotForEditorState(editorState) {
    return convertToRaw(editorState.getCurrentContent());
  }

  /**
   * (Internal helper) Transform the given snapshot representation of the editor state 
   * into the editorState attribute value in the state used at runtime.
   */
  static fromSnapshotForEditorState(editorState) {
    return EditorState.createWithContent(convertFromRaw(editorState));
  }


  // --------- table related------------------------------------------------------------------------------

  /**
   * This will automatically deselect the field.
   */
  onRemoveLastSelection() {
    const pathState = ComponentStateHelper.getState(this);

    this.updateStateAndTriggerRendering(pathState, false);
  }

  // ----------- text flash related -------------------------------
  onCurrentTimeUpdate(currentTime) {
    const { config, runtime } = this.props;
    const { fromSecond, toSecond } = config;
    const pathState = ComponentStateHelper.getState(this);
    if (currentTime >= fromSecond && currentTime <= toSecond) {
      runtime.eventEmitter.emit("clearFlashTexts");
      StateAttributeAccess.setHidden(pathState, false);
    } else {
      StateAttributeAccess.setHidden(pathState, true);
    }

    ComponentStateHelper.registerState(this, pathState);
    RenderingHelper.triggerRendering(this);
  }

  onClearFlashTexts() {
    const pathState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setHidden(pathState, true);
    ComponentStateHelper.registerState(this, pathState);
    RenderingHelper.triggerRendering(this);
  }

  // ----------- public interaction API ------------------------------------------------------------------

  /**
   * Trigger a re-render after a change in the highlight background color.
   * 
   * Due to a bug Draft will not re-render if the only change is a modification of the styles map.
   * see: https://github.com/facebook/draft-js/issues/999 
   * 
   * We 'redefine' the current selection without changing its values to force the re-render.
   */
  highlightColorChanged() {
    const pathState = ComponentStateHelper.getState(this);
    const editorState = StateAttributeAccess.extractEditorState(pathState);
    const finalEditorState = EditorState.forceSelection(editorState, editorState.getSelection());
    this.saveEditorStateInPathState(finalEditorState);
    RenderingHelper.triggerRendering(this);
  }

  /**
   * Process a click on the temporarily appearing 'highlight' button on touch screen devices.
   */
  onHighlightClick() {
    const pathState = ComponentStateHelper.getState(this);
    const editorState = StateAttributeAccess.extractEditorState(pathState);
    const { config } = this.props;
    const blockKeysList = SelectionHelper.getListOfBlockKeysInBlockOrder(config.value.blocks);
    const currentHighlightKey = this.getHighlightColorKey(pathState);

    this.processHighlightClick(editorState, blockKeysList, currentHighlightKey);

    RenderingHelper.triggerRendering(this);
  }

  /**
   * Grab the focus.
   */
  focus = () => {
    this.editor.focus();
  }


  // --------------- our own event handlers -------------------------------------------------------------------------

  onClickHandler = (event) => {
    const { runtime, config, path, onParentClick, checkSelectable } = this.props;

    // Silently forget event if we are child of a CbaTableCell that is not selectable currenty:
    if (checkSelectable !== undefined && !checkSelectable()) {
      CommonActionsHelper.stopEventPropagation(event);
      return;
    }

    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, path, runtime);
    const { fromSecond, toSecond } = config;
    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    if (fromSecond !== undefined) {
      const traceDetails = {
        from: fromSecond,
        to: toSecond
      }
      CommonActionsHelper.traceUserInteractionPerConfig(config, path, traceDetails, event, runtime);
    } else {
      CommonActionsHelper.traceUserInteractionPerConfig(config, path,
        CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState),
        event, runtime);
    }

    // Flip the selected state: 'yes' <-> 'no'
    if (config.toggleType !== 'none') {
      this.updateStateAndTriggerRendering(pathState);
    }

    // Call parent click handler if available, needed for standard table single select feature
    if (onParentClick !== undefined) {
      onParentClick(event);
    }

    SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);

    // send statemachine event depending on state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);

  }

  /**
   * (Internal helper) Update our component state in the components state manager and trigger rendering.
   */
  updateStateAndTriggerRendering(pathState, isRemoveSelection) {
    const { runtime, path } = this.props;
    const oldSelected = SelectGroupHelper.extractSelectedState(pathState, path, runtime);
    StateAttributeAccess.setVisited(pathState, true);

    SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);
  }


  /**
   * The Draft editor will call this method when the editor state
   * is modified due to a user interaction in the editor.
   * 
   * We should make sure that we update our structures to make sure that
   * we will provide the modified editor state at the next 
   * render call to the Draft editor.
   */
  onChange = (editorState) => {
    const pathState = ComponentStateHelper.getState(this);
    const oldEditorState = StateAttributeAccess.extractEditorState(pathState);
    const oldSelections = StateAttributeAccess.extractSelections(pathState);
    const highlightActive = StateAttributeAccess.extractHighlightable(pathState);

    if (!SelectionHelper.contentStateEqual(oldEditorState, editorState)) {
      // block modifications of the editor's text content: do not persist onChange editor state.
    } else if (highlightActive) {
      // we are in highlighting mode -> combine new selection in editor state with the selections in our component state.
      this.doHighlightActions(editorState, oldSelections);
    } else {
      if (SelectionHelper.isSelection(editorState)) {
        // we are not in highlighting mode -> selections in our component state will not change
        this.editor.blur();
      }

      this.saveEditorStateInPathState(editorState);
    }

    RenderingHelper.triggerRendering(this);
  }

  /**
   * (Internal helper) Combine the current selection in the editor state with our catalog of highlight selections in the component state.
   */
  doHighlightActions = (editorState, oldSelections) => {
    const { config } = this.props;
    const blockKeysList = SelectionHelper.getListOfBlockKeysInBlockOrder(config.value.blocks);
    const pathState = ComponentStateHelper.getState(this);
    const currentHighlightKey = this.getHighlightColorKey(pathState);

    if (Utils.isMobile()) {
      // For touch screen devices the temporarly appearing 'highlight' button will do the highlight section processing
      // -> Just save the new editor state but do not modify the selections in our component state yet.
      this.saveEditorStateInPathState(editorState);
    } else if (SelectionHelper.isSelection(editorState)) {
      // Some new area was selected in the editor state -> add this to the selections in our component state.
      this.processHighlightClick(editorState, blockKeysList, currentHighlightKey);
    } else {
      // Nothing is selected in the editor state -> the selections in our component state remain as they are.
      this.saveEditorStateInPathState(editorState);
    }

    // special case: a simple click (i.e. the selected area is empty) occured on a HIGHLIGHTED area 
    // --> deselect the HIGHLIGHTED area hit by the click
    //     Processing and dropping the selected area in the editor state (by moving focus to the end?) 
    //     makes sure the 'highlight' button will not appear on touch screen devices.
    const clickedSelection = SelectionHelper.getSelectionHitByClick(editorState.getSelection(), oldSelections, blockKeysList);
    if (!SelectionHelper.isSelection(editorState) && clickedSelection !== undefined) {
      const { startKey, startOffset, endKey, endOffset, highlightKey } = clickedSelection;
      let newEditorState = EditorStateHelper.forceSelection(editorState, startKey, startOffset, endKey, endOffset);
      newEditorState = RichUtils.toggleInlineStyle(newEditorState, highlightKey);
      newEditorState = EditorState.moveFocusToEnd(newEditorState);
      const selections = SelectionHelper.removeSelectionFromSelectionsArray(oldSelections, clickedSelection);
      this.saveEditorStateInPathState(newEditorState);
      this.saveSelectionsInPathState(selections);
      this.triggerHighlightEvent();
    }

  }

  /**
   * (Internal helper) Update the highlight style settings in the editor state to include the new selected area 
   * and add the new selected area to our list of highlight selections in the component state.
   */
  processHighlightClick = (currentEditorState, blockKeysList, currentHighlightKey) => {
    const pathState = ComponentStateHelper.getState(this);
    const oldSelections = StateAttributeAccess.extractSelections(pathState);
    const currentSelection = SelectionHelper.getSelection(currentEditorState, currentHighlightKey, blockKeysList);

    // Clear old highlights from curently selected area.
    const toggleOffOperationsList = [];

    oldSelections.forEach((oldSelection) => {
      const intersection = SelectionHelper.getIntersect(currentSelection, oldSelection, blockKeysList);
      switch (intersection.type) {
        case "left":
          toggleOffOperationsList
            .push(SelectionHelper.buildSelection(
              oldSelection.startKey, currentSelection.endKey, oldSelection.startOffset,
              currentSelection.endOffset, oldSelection.highlightKey
            ));
          break;
        case "right":
          toggleOffOperationsList
            .push(SelectionHelper.buildSelection(
              currentSelection.startKey, oldSelection.endKey, currentSelection.startOffset,
              oldSelection.endOffset, oldSelection.highlightKey
            ));
          break;
        case "inside":
          toggleOffOperationsList
            .push(Object.assign({}, currentSelection, {
              highlightKey: oldSelection.highlightKey
            }));
          break;
        case "cover":
          toggleOffOperationsList
            .push(Object.assign({}, oldSelection));
          break;
        case "none":
          break;
        default:
          break;
      }
    });

    toggleOffOperationsList.forEach((operation) => {
      currentEditorState = EditorStateHelper.toggleEditorStateStyleOnSelection(currentEditorState, operation);
    });

    // Apply the HIGHLIGHT style to the current selection -> makes newly selected area part of the currently highlighted areas.
    currentEditorState = EditorStateHelper.toggleEditorStateStyleOnSelection(currentEditorState, currentSelection);

    // Deduce the new highlighted areas by inspecting the HIGHLIGHT style setting in the toggled editor state.
    const selections = SelectionHelper.calculateSelectionsArray(currentEditorState, blockKeysList);

    // Move the focus to the end 
    currentEditorState = EditorState.moveFocusToEnd(currentEditorState);

    // update the editor state and the highlighted selections in our component state 
    this.saveEditorStateInPathState(currentEditorState);
    this.saveSelectionsInPathState(selections);
    this.triggerHighlightEvent();
  }

  /**
   * (Internal helper) Trigger the optional highlight click event on the current state machine. 
   */
  triggerHighlightEvent = () => {
    const { config, runtime } = this.props;
    const { highlightChangeEvent } = config;
    if (highlightChangeEvent !== undefined) {
      runtime.statemachinesManager.triggerEvent(highlightChangeEvent);
    }
  }


  // --------------- content modifier activities -------------------------------------------------------------------------

  /**
   * (Internal helper) Apply the modifications to the editor's content defined by our content modifiers.
   * 
   * The method returns the modified editor state and a new modifier-effects-in-editor-state extract.
   * 
   * The caller should use the new editor state for rendering the Draft editor. 
   * The caller should store both structures in the rich text field's component state in the component state manager
   * and provide these to a future applyContentModifiers call. 
   * 
   * The concept behind this is:
   * We keep an extract of the modifications applied to the edtitor's content by the content modifiers.
   * This extract is stored in the modifiersInEditorState attribute of the rich text field's state.
   * We use this extract to decide whether we need to update the editor state due to changes in the content modifiers.
   * Example: 
   *  - Dynamic fragments can be 'activated' by setting their 'selected' attribute in their component manager state (e.g. by the TermEvaluator). 
   *    When we render the rich text field we 'apply' the content modifiers to the editor's state. There we check whether the 
   *    current 'selected' setting in the component state of the modifier matches the current modification implanted in the editor's state by comparing
   *    with the 'active' attribute in the extract.
   * Since modifying the editor state might destroy the current highlight selections we try to avoid modifying the editor state without need.
   * 
   * 
   * @param editorState: The editor state that was used for the last render. We will modify this editor state and return it at the end.
   * @param modifiersInEditorState: The extract of the modifications done by the content modifiers in the given editor state. 
   *   We will return a new version of this extract that reflects the modifications implanted the the modified editor state. 
   *   We need the extract representing the modifications in the old editor state to decide whether we have to modify the editor state
   *   at all.
   * @param editorPath: The index path of the rich text field display component instance.
   * @param propsConfig: The static 'config' structure of the rich text field diplay component.
   * @param runtime: The common runtime context structure.
   * 
   */
  static applyContentModifiers(editorState, modifiersInEditorState, editorPath, propsConfig, runtime) {
    const { contentModifiers } = propsConfig;

    let modifiedEditorState = editorState;
    const newModifiersInEditorState = StateManagerHelper.deepCopy(modifiersInEditorState);

    // we need the ordering of the block keys for position comparisons:
    const configBlocks = propsConfig.value.blocks;
    const blockKeyToIndexMap = SelectionHelper.getBlockKeysToBlockIndexMap(SelectionHelper.getListOfBlockKeysInBlockOrder(configBlocks));

    CbaRichTextField.forEachContentModifier(propsConfig, editorPath, (modifierInConfig, modifierPath, modifierParentPath) => {
      const modifierState = runtime.componentStateManager.findOrBuildStateForPathId(modifierPath, runtime);
      if (newModifiersInEditorState[modifierPath] === undefined) {
        newModifiersInEditorState[modifierPath] = CbaRichTextField.buildNonActiveModifierInEditorState(modifierInConfig.type);
      }
      const actualPosition = PositionCalculationHelper.calculateActualPosition(
        modifierInConfig,
        configBlocks,
        blockKeyToIndexMap,
        contentModifiers,
        newModifiersInEditorState,
        modifierParentPath,
        modifiedEditorState
      );
      const modifierInEditorState = newModifiersInEditorState[modifierPath];
      let newModifierInEditorState;
      switch (modifierInConfig.type) {
        case 'EvaluatedInject':
          [modifiedEditorState, newModifierInEditorState] = CbaRichTextField.applyEvaluatedInjectModifier(modifiedEditorState, modifierState, modifierInEditorState, modifierInConfig.config, actualPosition, runtime);
          break;
        case 'DynamicFragment':
          [modifiedEditorState, newModifierInEditorState] = CbaRichTextField.applyDynamicFragmentModifier(modifiedEditorState, modifierState, modifierInEditorState, modifierInConfig.config, actualPosition, runtime);
          break;
        default:
          console.error(`Unknown modifier type: ${modifierInConfig.type}`);
      }
      newModifiersInEditorState[modifierPath] = newModifierInEditorState;
    });

    return [modifiedEditorState, newModifiersInEditorState];
  }


  /**
   * (Internal helper) Build the extract of the modifications done to the editor's content by an inactive content modifier.
   */
  static buildNonActiveModifierInEditorState(contentModifierType) {
    return (contentModifierType === 'EvaluatedInject')
      ? {
        injectText: ""
      }
      : {
        active: false
      };
  }

  /**
   * (Internal helper) Apply a content modifier of type 'evaluated inject' to the editor's state and calculate the new extract structure
   * for the content modifier.
   */
  static applyEvaluatedInjectModifier(editorState, modifierState, modifierInEditorState, modifierConfig, actualPosition, runtime) {
    // avoid unnecessary modification of editor state
    const currentInjectText = modifierState.selected === true ? `${TermEvaluator.evaluateTerm(modifierConfig.insertString, runtime, [], 'inject')}` : "";
    const oldInjectText = modifierInEditorState.injectText;
    if (oldInjectText === currentInjectText) {
      return [editorState, modifierInEditorState];
    }

    const newModifierInEditorState = StateManagerHelper.deepCopy(modifierInEditorState);
    newModifierInEditorState.injectText = currentInjectText;

    // TODO: second step improvement: if a modification is necessary do a best effort to modify the selections too
    let modifiedEditorState = EditorStateHelper.replaceTextInBlock(editorState,
      actualPosition.blockKey,
      actualPosition.offset,
      actualPosition.blockKey,
      actualPosition.offset + oldInjectText.length,
      currentInjectText);

    modifiedEditorState = EditorStateHelper.applyInlineStyles(modifiedEditorState,
      actualPosition.blockKey,
      actualPosition.offset,
      actualPosition.blockKey,
      actualPosition.offset + currentInjectText.length,
      modifierConfig.inlineStyles);

    modifiedEditorState = EditorStateHelper.applyEntities(modifiedEditorState,
      actualPosition.blockKey,
      actualPosition.offset,
      actualPosition.blockKey,
      actualPosition.offset + currentInjectText.length,
      modifierConfig.entities);

    return [modifiedEditorState, newModifierInEditorState];
  }

  /**
   * (Internal helper) Apply a content modifier of type 'dynamic fragment' to the editor's state and calculate the new extract structure
   * for the content modifier.
   */
  static applyDynamicFragmentModifier(editorState, modifierState, modifierInEditorState, modifierConfig, actualPosition, runtime) {
    // avoid unnecessary modification of editor state
    if (modifierState.selected === modifierInEditorState.active) {
      return [editorState, modifierInEditorState];
    }

    const newModifierInEditorState = StateManagerHelper.deepCopy(modifierInEditorState);
    newModifierInEditorState.active = modifierState.selected;

    // TODO: second step improvement: if a modification is necessary do a best effort to modify the selections too
    let modifiedEditorState;
    if (modifierState.selected === true) {
      modifiedEditorState = EditorStateHelper.replaceWithBlocks(editorState,
        actualPosition.blockKey,
        actualPosition.offset,
        actualPosition.blockKey,
        actualPosition.offset,
        modifierConfig.blocks,
        modifierConfig.entityMap);
    } else {
      const numberInjectedBlocks = modifierConfig.blocks.length - 1;

      modifiedEditorState = EditorStateHelper.removeBlocks(editorState,
        actualPosition.blockKey,
        actualPosition.offset,
        PositionCalculationHelper.calculateBlockKey(actualPosition.blockKey, numberInjectedBlocks, editorState),
        (numberInjectedBlocks === 0 ? actualPosition.offset : 0) + modifierConfig.blocks[numberInjectedBlocks].text.length);
    }

    return [modifiedEditorState, newModifierInEditorState];
  }


  // ------------ common helper methods ---------------------------------------------------------------------------

  /**
   * Run in a loop through all content modifiers specified in our static config structure and execute the given callback for each.
   * 
   * We will provide the following parameters to the callback
   *  - modifier: The content modifier configuration from the 'contentModifiers' array in the rich text field configuration.
   *  - indexPathOfModifier: The index path of the content modifier 
   *    (i.e. the index path of the rich text field extended with a pseudo index to discern between content-modifier and embedded-link children and the index of the content modifier itself.)
   *  - indexPathOfModifierParent: The index path of the node containing all content-modifier children. 
   *    (i.e. the index path of the rich text field extended with a pseudo index to discern between content-modifier and embedded-link children.)
   * 
   * @param {object} propsConfig The config structure of the rich text field that contains the content modifier definitions in the 'contentModifiers' attribute.
   * @param {String} editorPath The index path of the rich text field that contains the content modifiers.
   * @param { callback(modifier, indexPathOfModifier) } doForEach The method to call for each content modifier. 
   */
  static forEachContentModifier(propsConfig, editorPath, doForEach) {
    const { contentModifiers } = propsConfig;
    if (contentModifiers !== undefined) {
      const modifierParentPath = CbaRichTextField.addContentModifierIndex(editorPath);
      contentModifiers.forEach((modifier, index, all) => {
        const modifierPath = IndexPathHelper.appendIndexToPageSegment(modifierParentPath, index);
        doForEach(modifier, modifierPath, modifierParentPath);
      });
    }
  }

  /**
   * Extract an unformatted plain text representation from our editor state.
   */
  static calculateTextValue(editorState) {
    // stripping white space character, including space, tab, form feed, line feed.
    // The content is stripped to the plain text (i.e. all formatting is stripped, newlines are replaced by blanks).
    return editorState.getCurrentContent().getPlainText('').replace(/\s/g, ' ');
  }

  /**
   * Save the given content modifiers state in our component state object in the components state manager.
   */
  saveModifiersStateInPathState = (modifiersInEditorState) => {
    const pathState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setModifiersInEditorState(pathState, modifiersInEditorState)
    const { runtime, path } = this.props;
    runtime.componentStateManager.registerStateByPathId(path, pathState);
  }

  /**
   * Save the given Draft editor state in our component state object in the components state manager.
   * We also keep an unformatted plain text representation of our current editor's text content in our component state object.
   */
  saveEditorStateInPathState = (editorState) => {
    const pathState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setEditorState(pathState, editorState);
    StateAttributeAccess.setTextValue(pathState, CbaRichTextField.calculateTextValue(editorState));
    const { runtime, path } = this.props;
    runtime.componentStateManager.registerStateByPathId(path, pathState);
  }

  /**
   * Save the given selections in our component state object in the components state manager
   * and create a trace log entry.
   */
  saveSelectionsInPathState = (newSelections) => {
    const { runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selections = StateAttributeAccess.extractSelections(pathState);

    CbaRichTextField.traceHighlightChange(path, selections, newSelections, runtime);

    StateAttributeAccess.setSelections(pathState, newSelections);
    runtime.componentStateManager.registerStateByPathId(path, pathState);
  }


  /**
   * Trace a change in the highlight selections.
   */
  static traceHighlightChange(path, oldSelectionsRaw, newSelectionsRaw, runtime) {
    const oldSelections = SelectionHelper.TransformSelectionsToTraceSelectionsDTO(oldSelectionsRaw, path, runtime);
    const newSelections = SelectionHelper.TransformSelectionsToTraceSelectionsDTO(newSelectionsRaw, path, runtime);
    CommonActionsHelper.traceUserInteraction('RichTextHighlight', path,
      {
        oldSelections,
        newSelections
      },
      undefined, undefined, runtime);
  }

  // ------------ rendering ---------------------------------------------------------------------------------------

  myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type.substr(0, 3) === 'cba') {
      return type.replace(/_/g, " ");
    } else {
      if (type === 'atomic') {
        return 'cbaImageLeft'
      }
      if (type === 'atomic_rtl') {
        return 'cbaImageRight'
      }
      if (type === 'empty_block') {
        return 'cbaClearImage'
      }

    }

    return "";
  }

  mediaBlockRenderer = (block) => {
    const { runtime } = this.props;
    if (block.getType() === 'atomic' || block.getType() === 'atomic_rtl') {
      return {
        component: Media(runtime),
        editable: false,
      };
    }
    return null;
  }

  /**
   * Calculate the styles map to use for rendering.
   * 
   * We start with the styles map from the fixed component configuration 
   * and inject the current highlight background color setting from the task state.
   */
  getStylesMap = () => {
    const { config, runtime } = this.props;
    const { highlightColors } = runtime.taskManager.getTopLevelConfiguration();
    const originalStylesMap = CbaRichTextField.findStylesMapInConfig(config);

    const highlightStyleMap = highlightColors.reduce((accum, rgbaColor) => {
      const rgbaKey = CbaRichTextField.convertHighlightValueToKey(rgbaColor);
      accum[rgbaKey] = {
        backgroundColor: rgbaColor
      };
      return accum;
    }, {});

    return Object.assign({}, originalStylesMap, highlightStyleMap);
  }

  /**
   * The generator for the RichTextField puts the styles map into the value attribute itself.
   * Our test items and the generator for the HTMLTextField put the styles map besides the value attribute.
   * On the long run want to have it besides the value attribute since the value attribute is used by the Editor component.
   * 
   * This method finds the style map in both places.
   * 
   * @param {*} config The config structure for the component given in the page description.
   */
  static findStylesMapInConfig(config) {
    return config.stylesMap === undefined ? config.value.stylesMap : config.stylesMap;
  }

  /**
   * Get the current highlight color. 
   * 
   * Precedence: 
   *  - current task state setting
   *  - our static config setting
   *  - undefined
   */
  getHighlightColor = (pathState) => {
    const { runtime, config } = this.props;
    const highlightable = StateAttributeAccess.extractHighlightable(pathState);
    if (!highlightable) return undefined;
    return (
      runtime.taskManager.getTopLevelConfiguration().itemHighlightColor
      || config.stylesMap.HIGHLIGHT.backgroundColor
      || undefined);
  }

  /**
   * Get the current highlight color. 
   * 
   */
  getHighlightColorKey = (pathState) => {
    const currentColor = this.getHighlightColor(pathState);
    const currentColorKey = CbaRichTextField.convertHighlightValueToKey(currentColor);
    const defaultHighlightKey = "HIGHLIGHT";
    const styleMap = this.getStylesMap();

    return styleMap[currentColorKey] ? currentColorKey : defaultHighlightKey;
  }

  /**
   * Generate a style tag to put into our top level div tag.
   * 
   * This is the only way we found to inject a ::selection pseudo selector.
   */
  generateSelectionStyle = (isHighlightActive, highlightColor, wrapperId) => {
    const selectionColor = isHighlightActive ? highlightColor : "transparent";

    return (
      // eslint-disable-next-line react/no-danger
      <style dangerouslySetInnerHTML={
        {
          __html: `
            #${wrapperId} .DraftEditor-root span::selection  { background-color: ${selectionColor}; }
            #${wrapperId} .DraftEditor-root span::-moz-selection  { background-color: ${selectionColor}; }
          `
        }
      }
      />
    );
  }

  handleKeyDown(event) {
    if (event.keyCode === 32) {
      // space key
      this.onClickHandler(event);
    }
  }

  render() {
    const { runtime, path, config, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const highlightColor = this.getHighlightColor(pathState);
    const highlightActive = StateAttributeAccess.extractHighlightable(pathState);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, path, runtime);
    const wrapperId = IndexPathHelper.getValidCssIdFromPath(path);

    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, selectedState, orientation, runtime);
    if (config.toggleType === "upDown") {
      CommonConfigHelper.setStyleAttribute(style, "borderStyle", selectedState ? "inset" : "outset");
    }

    const styleMap = this.getStylesMap();

    const oldEditorState = StateAttributeAccess.extractEditorState(pathState);
    const modifiersInEditorState = StateAttributeAccess.extractModifiersInEditorState(pathState);

    const [editorState, newModifiersInEditorState] = CbaRichTextField.applyContentModifiers(oldEditorState, modifiersInEditorState, path, config, runtime);

    this.saveEditorStateInPathState(editorState);
    this.saveModifiersStateInPathState(newModifiersInEditorState);

    const linkReceiver = CommonActionsHelper.getDefaultLinkReceiver(this);
    const customDecorators = [
      {
        strategy: findLinkEntities,
        component: DraftLink,
        props: {
          linkReceiver,
          parentPath: CbaRichTextField.addLinkIndex(path),
          runtime,
          tabIndex: config.tabIndex
        }
      },
      {
        strategy: findBulletEntities,
        component: DraftBullet
      },
      {
        strategy: findMathJaxEntities,
        component: MathJaxEntity
      }
    ];

    return (
      <div
        id={wrapperId}
        style={style}
        data-cba-id={config.pageEditId}
        onClick={this.onClickHandler}
        title={CommonConfigHelper.buildTitle(config)}
        onKeyDown={e => this.handleKeyDown(e)}
      >
        {this.generateSelectionStyle(highlightActive, highlightColor, wrapperId)}
        <Editor
          runtime={runtime}
          blockStyleFn={this.myBlockStyleFn}
          blockRendererFn={this.mediaBlockRenderer}
          blockRenderMap={DefaultDraftBlockRenderMap}
          handleBeforeInput={() => 'handled'}
          handlePastedText={() => true}
          keyBindingFn={() => 'not-handled-command'}
          customStyleMap={styleMap}
          editorState={editorState}
          decorators={customDecorators}
          onChange={this.onChange}
          plugins={this.plugins}
          ref={(element) => { this.editor = element; }}
          tabIndex={config.tabIndex}
        />
        {highlightActive && Utils.isMobile() && <this.InlineToolbar ref={(element) => { this.toolbar = element; }} />}
      </div>
    )
  }

}

CbaRichTextField.propTypes = {
  runtime: PropTypes.object.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        value: PropTypes.object.isRequired,
        stylesMap: PropTypes.object,
        contentModifiers: PropTypes.array,
        highlightText: PropTypes.bool.isRequired,
        toggleType: PropTypes.oneOf(['none', 'upDown', 'colorChange']).isRequired,
        highlightChangeEvent: PropTypes.string
      }
    )
  ).isRequired,
  path: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  row: PropTypes.number,
  column: PropTypes.number,
  onParentClick: PropTypes.func,
  checkSelectable: PropTypes.func,
  fromSecond: PropTypes.number,
  toSecond: PropTypes.number
}

CbaRichTextField.defaultProps = {
  row: undefined,
  column: undefined,
  onParentClick: undefined,
  checkSelectable: undefined,
  fromSecond: undefined,
  toSecond: undefined
}
