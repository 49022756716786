import CommonConfigHelper from "./CommonConfigHelper";
import IndexPathHelper from '../state/IndexPathHelper';
import CbaComboBox from '../components/CbaComboBox';
import CbaRichTextField from '../components/CbaRichTextField/CbaRichTextField';
import CbaCalculatorInput from "../components/calculator/CbaCalculatorInput";
import CbaTreeChildArea from "../components/CbaTree/CbaTreeChildArea";
import CbaTableCell from "../components/table/CbaTableCell";
import CbaList from "../components/list/CbaList";

/**
 * Provide access to the configuration of the currently active item.
 */
export default class PageConfigurationsManager {

  /**
   * Create a configuration manager to provide access to the configuration of the given item.
   * 
   * @param {*} item The configuration of the item. 
   */
  constructor(item) {
    this.pagesList = item.pages;
    this.buildMappings(item.pages);

    this.findPage = this.findPage.bind(this);
    this.findPageSegmentForUserDefId = this.findPageSegmentForUserDefId.bind(this);
    this.findConfigurationForPageSegment = this.findConfigurationForPageSegment.bind(this);
  }

  /**
   * Get the configuration object for the specified page.
   */
  findPage(pageName) {
    return this.pagesList.find((value, index, theArray) => value.name === pageName);
  }

  /**
   * Get page segment (for use in index paths) of the display component specified by the given UserDefinedId.
   * 
   * The page segment starts with the name of the parent page followed by an index 
   * into the children array for each CbaContainer from the page root to the display component identified
   * by the UserDefinedId, e.g. 
   *   /page=pageA/index=3/index=1
   */
  findPageSegmentForUserDefId(userDefinedId) {
    const result = this.userDefinedIdCatalog[userDefinedId];
    if (result === undefined) {
      console.warn(`Cannot find page segment for user defined id ${userDefinedId}`)
    }
    return result;
  }

  /**
   * Get page segment (for use in index paths) of the text block specified by the given text block name.
   * 
   * The page segment starts with the name the parent page followed by an index 
   * into the children array for each CbaContainer from the page root to the rich text component 
   * owning the text block, e.g. 
   *   /page=pageA/index=3/index=1
   */
  findPageSegmentForTextBlockOwner(blockName) {
    // The method is called to test whether an ID is a block name, therefore not finding any hit is no error.
    return this.textBlockNameCatalog[blockName];
  }

  /**
   * Get the configuration object for the display component specified by the given page segment. 
   * 
   * You may obtain the page segment for a display component by calling findPageSegmentForUserDefId(...).
   */
  findConfigurationForPageSegment(pageSegment, supressWarning) {
    const result = this.pageSegmentCatalog[pageSegment];
    if (!supressWarning && result === undefined) {
      console.warn(`Cannot find configuration for page segment ${pageSegment}`)
    }
    return result;
  }


  // private stuff --------------------------------------------------------------------------
  buildMappings(pages) {

    // map (userDefinedId) -> (page segment for component to be used in index path)
    this.userDefinedIdCatalog = {};

    // map (text block name) -> (page segment for CbaRichText component containing the text block to be used in index path)
    this.textBlockNameCatalog = {}

    // map (page segment from index path) -> (description structure for component as given in item description JSON)
    this.pageSegmentCatalog = {};

    pages.forEach((page, index, all) => {
      this.addMappingsForPage(page);
    })
  }

  addMappingsForPage(page) {
    const pageName = page.name;
    this.addMappingsForComponent(IndexPathHelper.buildPageSegment(pageName), page.content);
  }


  addMappingsForComponent(pageSegment, component) {
    if (component === undefined || component.config === undefined) {
      console.warn(`Found invalid component configuration at page segment ${pageSegment}`);
    }

    this.addMappings(pageSegment, component);

    const { type, config } = component;
    switch (type) {
      case 'CbaContainer':
        this.addMappingsForComponentList(pageSegment, component.config.cbaChildren);
        break;
      case 'CbaRegionMap':
        this.addMappingsForComponentList(pageSegment, component.config.regions);
        break;
      case 'CbaComboBox':
        this.addMappingsForComponentList(pageSegment, CbaComboBox.buildComboBoxItemsArray(component.config.items));
        break;
      case 'CbaList':
        this.addMappingsForComponentList(pageSegment, CbaList.buildListItemsArray(component.config.items));
        break;
      case 'CbaRichTextField':
        this.addMappingsForComponentList(CbaRichTextField.addLinkIndex(pageSegment), CbaRichTextField.buildLinkConfigurationsArray(component.config));
        if (config.contentModifiers !== undefined) {
          this.addMappingsForComponentList(CbaRichTextField.addContentModifierIndex(pageSegment), component.config.contentModifiers);
        }
        if (config.textBlocks !== undefined) {
          component.config.textBlocks.forEach((textBlock, index, all) => {
            this.textBlockNameCatalog[textBlock.name] = pageSegment;
          });
        }
        break;
      case 'CbaTable':
        this.addMappingsForComponentList(pageSegment, config.cbaChildren);
        break;
      case 'CbaTableCell':
        if (config.isSpreadsheet === true) {
          if (config.items !== undefined) {
            const comboboxCopy = CbaTableCell.createCellCopy(component, pageSegment, "CbaComboBox");
            this.addMappings(comboboxCopy.path, comboboxCopy);
            this.addMappingsForComponentList(comboboxCopy.path, CbaComboBox.buildComboBoxItemsArray(config.items));
          } else {
            const inputCopy = CbaTableCell.createCellCopy(component, pageSegment, "CbaSingleLineInputField");
            this.addMappings(inputCopy.path, inputCopy);
          }
        }
        this.addMappingsForComponentList(pageSegment, config.cbaChildren);
        break;
      case 'CbaCalculatorInput':
      {
        const { delegatePath, delegateComponent } = CbaCalculatorInput.createDelegateComponent(component, pageSegment, 'CbaSimpleTextField')
        this.addMappings(delegatePath, delegateComponent);
        break;
      }
      case 'CbaCalculatorHistory':
      {
        const { delegatePath, delegateComponent } = CbaCalculatorInput.createDelegateComponent(component, pageSegment, 'CbaSimpleTextField')
        this.addMappings(delegatePath, delegateComponent);
        break;
      }
      case 'CbaTreeChildArea':
      {
        const pageAreaConfig = {
          type: 'CbaPageArea',
          config: CbaTreeChildArea.getPageAreaConfig(config)
        }
        this.addMappings(CbaTreeChildArea.getPageAreaPath(pageSegment), pageAreaConfig);
        break;
      }
      case 'CbaMedia':
        this.addMappingsForComponentList(pageSegment, config.cbaChildren);
        break;
      default:
        // nothing to do for other component types.
    }
  }

  addMappings(pageSegment, component) {
    this.pageSegmentCatalog[pageSegment] = component;
    const userDefinedId = CommonConfigHelper.getUserDefinedId(component.config);
    if (userDefinedId !== undefined) {
      this.userDefinedIdCatalog[userDefinedId] = pageSegment;
    }
  }

  addMappingsForComponentList(rootPath, components) {
    if (components !== undefined) {
      components.forEach((component, index, all) => {
        const path = IndexPathHelper.appendIndexToPageSegment(rootPath, index);
        this.addMappingsForComponent(path, component);
      });
    }
  }

}
