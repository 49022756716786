import { EditorState, Modifier, SelectionState, convertFromRaw, RichUtils } from 'draft-js';

/**
 * Helper methods to work with the Draft.js editor state.
 */
export default class EditorStateHelper {


  static replaceTextInBlock(editorState, startBlockKey, startOffset, endBlockKey, endOffset, toBeInserted) {
    return EditorState.push(editorState,
      Modifier.replaceText(
        editorState.getCurrentContent(),
        EditorStateHelper.buildDraftSelectionState(startBlockKey, startOffset, endBlockKey, endOffset),
        toBeInserted
      ),
      'insert-characters')
  }

  static replaceWithBlocks(editorState, startBlockKey, startOffset, endBlockKey, endOffset, rawBlocksArrayToInsert, entityMapToInsert) {
    const rawValue = {
      blocks: rawBlocksArrayToInsert,
      entityMap: entityMapToInsert
    };
    const blockMap = convertFromRaw(rawValue).getBlockMap();
    return EditorState.push(editorState,
      Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        EditorStateHelper.buildDraftSelectionState(startBlockKey, startOffset, endBlockKey, endOffset),
        blockMap
      ),
      'insert-fragment')
  }

  static removeBlocks(editorState, startBlockKey, startOffset, endBlockKey, endOffset) {
    return EditorState.push(editorState,
      Modifier.removeRange(
        editorState.getCurrentContent(),
        EditorStateHelper.buildDraftSelectionState(startBlockKey, startOffset, endBlockKey, endOffset),
        'forward'
      ),
      'remove-range')
  }

  static applyInlineStyles(editorState, startBlockKey, startOffset, endBlockKey, endOffset, styleNames) {
    let modifiedEditorState = editorState;
    if (styleNames !== undefined) {
      const targetSelection = EditorStateHelper.buildDraftSelectionState(startBlockKey, startOffset, endBlockKey, endOffset);
      styleNames.forEach((styleName, index, all) => {
        modifiedEditorState = EditorState.push(modifiedEditorState,
          Modifier.applyInlineStyle(modifiedEditorState.getCurrentContent(), targetSelection, styleName),
          'change-inline-style');
      });
    }
    return modifiedEditorState;
  }

  static applyEntities(editorState, startBlockKey, startOffset, endBlockKey, endOffset, entityNames) {
    let modifiedEditorState = editorState;
    if (entityNames !== undefined) {
      const targetSelection = EditorStateHelper.buildDraftSelectionState(startBlockKey, startOffset, endBlockKey, endOffset);
      entityNames.forEach((entityName, index, all) => {
        modifiedEditorState = EditorState.push(modifiedEditorState,
          Modifier.applyEntity(modifiedEditorState.getCurrentContent(), targetSelection, entityName),
          'apply-entity');
      });
    }
    return modifiedEditorState;
  }


  static forceSelection(editorState, startBlockKey, startOffset, endBlockKey, endOffset) {
    return EditorState.forceSelection(editorState, EditorStateHelper.buildDraftSelectionState(startBlockKey, startOffset, endBlockKey, endOffset))
  }

  /**
   * 
   * Toggle style on a selection in editor state
   * 
   * @param {*} editorState 
   * @param {*} selection 
   * 
   * @returns {EditorState} EditorState
   */
  static toggleEditorStateStyleOnSelection(editorState, selection) {
    const { startKey, startOffset, endKey, endOffset, highlightKey } = selection;
    let newEditorState = EditorStateHelper.forceSelection(editorState, startKey, startOffset, endKey, endOffset);
    newEditorState = RichUtils.toggleInlineStyle(newEditorState, highlightKey);

    return newEditorState;
  }

  static buildDraftSelectionState(startBlockKey, startOffset, endBlockKey, endOffset) {
    return SelectionState.createEmpty('notUsed').merge({
      anchorKey: startBlockKey,
      anchorOffset: startOffset,
      focusKey: endBlockKey,
      focusOffset: endOffset
    });
  }

}
