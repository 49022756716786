import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from "react-data-grid";
import PropTypesHelper from '../PropTypesHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import RenderingHelper from '../RenderingHelper';
import TreeUtils from './TreeUtils';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import { CbaTreeRow } from './DataGridRenderers';
import TreeTraceHelper from './TreeTraceHelper';
import CommonActionsHelper from '../CommonActionsHelper';
import Utils from '../../utils/Utils';

import './CbaTree.css';


export default class CbaTree extends Component {

  constructor(props) {
    super(props);

    const { path, runtime } = props;

    this.eventEmitter = runtime.eventEmitter;
    this.subscription = this.eventEmitter.addListener(TreeUtils.getTreeEventChangeName(path), this.triggerRendering);
  }

  static addAttributesToInitialState(initialState, config, path, runtime) {
    const nodes = TreeUtils.initTreeNodes(config.nodes, config.nodeTypes, path, runtime, {
      startCollapsed: config.startCollapsed
    });
    const treeColumns = TreeUtils.initTreeColumns(config.columns, path);
    const treeState = TreeUtils.initTreeState(treeColumns);
    const nodeTypes = Utils.deepCopy(config.nodeTypes);
    const treeReadOnly = Utils.deepCopy(config.readOnly);

    StateAttributeAccess.setTreeNodes(initialState, nodes);
    StateAttributeAccess.setTreeColumns(initialState, treeColumns);
    StateAttributeAccess.setTreeState(initialState, treeState);
    StateAttributeAccess.setNodeTypes(initialState, nodeTypes);
    StateAttributeAccess.setTreeReadOnly(initialState, treeReadOnly);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);

  }

  componentWillUnmount() {
    this.subscription.remove();
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onRowExpandClick = (event, rowData, expandOnly) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { path, runtime } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const nodes = StateAttributeAccess.extractTreeNodes(pathState);

    const node = TreeUtils.getNode(nodes, rowData.path);
    if (node.isLeaf && node.nodes.length === 0) return;

    const oldExpandedValue = node.expanded;
    node.expanded = expandOnly || !node.expanded;

    if (oldExpandedValue !== node.expanded) {
      const actionType = node.expanded ? "expandNode" : "collapseNode";
      TreeTraceHelper.traceNodeAction(actionType, path, rowData, runtime);
      ComponentStateHelper.registerState(this, pathState);
      this.triggerRendering();
    }
  }

  onRowSelect = (event, rowData) => {
    event.preventDefault();
    event.stopPropagation();

    const { path } = this.props;

    TreeUtils.selectNodeAction(path, rowData);
  }

  onClickHandler = (event) => {
    const { path, runtime } = this.props;
    TreeTraceHelper.traceNodeAction("emptySelection", path, {
      isTreeView: false,
      cells: []
    }, runtime);
    CommonActionsHelper.stopEventPropagation(event);
  }

  onContextMenuHandler = (event) => {
    const { path, runtime } = this.props;
    TreeTraceHelper.traceNodeAction("emptySelection", path, {
      isTreeView: false,
      cells: []
    }, runtime);
  }

  addRowReferences = (row = {}) => {
    const { config, runtime, path } = this.props;
    const { updateOnSingleClick, nodeTypes } = config;

    row.onRowExpandClick = this.onRowExpandClick;
    row.onRowSelect = this.onRowSelect;

    row.updateOnSingleClick = updateOnSingleClick;
    row.isTreeView = false

    row.runtime = runtime;
    row.nodeTypes = nodeTypes;
    row.treePath = path;

    row.isReadOnly = config.readOnly;
    return row;
  }

  triggerRendering = () => {
    RenderingHelper.triggerRendering(this);
  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const { hideLeaves } = config;
    const pathState = ComponentStateHelper.getState(this);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);
    const { treeNodes, treeColumns } = pathState;
    const treeState = StateAttributeAccess.extractTreeState(pathState) || {};

    const hideColumnsHeader = config.hideColumns || TreeUtils.isDefaultColumn(treeColumns);

    const displayNodes = TreeUtils.getTreeDisplayNodes(treeNodes, path, hideLeaves);
    const displayTreeColumns = TreeUtils.getDisplayColumns(treeColumns, false, treeState.modifiedColumns, path, runtime);

    if (config.hideColumns) {
      // removes all columns except first
      displayTreeColumns.length = 1;
      delete displayTreeColumns[0].width;
    }

    return (
      <div
        style={style}
        data-cba-id={config.pageEditId}
        className={hideColumnsHeader ? "hideColumns" : ""}
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        onScroll={event => event.stopPropagation()} // prevent scroll propagation (stops tracing)
        title={CommonConfigHelper.buildTitle(config)}
      >
        <ReactDataGrid
          columns={displayTreeColumns}
          headerRowHeight={TreeUtils.const.COLUMN_HEIGHT}
          rowHeight={TreeUtils.const.ROW_HEIGHT} // this needs to be provided by generator by computing font in when no columns available
          rowGetter={index => this.addRowReferences(displayNodes[index])}
          rowsCount={displayNodes.length}
          rowRenderer={CbaTreeRow}
          onColumnResize={(columnIndex, newWidth) => TreeUtils.handleColumnResize(displayTreeColumns[columnIndex].key, newWidth, this)}
        />
      </div>
    )
  }

}

CbaTree.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        hideLeaves: PropTypes.bool.isRequired,
        hideColumns: PropTypes.bool.isRequired,
        updateOnSingleClick: PropTypes.bool.isRequired,
        "drag&dropMode": PropTypes.string.isRequired,
        userDefinedId: PropTypes.string.isRequired,
        nodes: PropTypes.array.isRequired,
        nodeTypes: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
