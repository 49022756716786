import StateAttributeAccess from '../state/StateAttributeAccess';
import CommonActionsHelper from './CommonActionsHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import RenderingHelper from './RenderingHelper';
import CbaComboBox from './CbaComboBox';
import PathTranslationHelper from '../state/PathTranslationHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';

/**
 * Helper methods to implement the 'select-group' mechanism.
 * 
 * The select-group mechanism is based on two state structures kept in the index path state in the ComponentStateManager:
 * 
 * A container managing select-groups for its descendants (the 'controller') keeps an array of structures configuring these select-groups:
 * selectGroupControllerState: [
 *  {
 *    name: String (The name of the select-group, unique among all select-groups of this controller)
 *    selectionChangesBlocked: bool (Do we block changes to the selection state of our group members currently?)
 *    singleSelectActive: bool (Is the single-select restriction active, i.e. do we enforce a single select behavior for participating members?)
 *    allowDeselect: bool (Do we allow the currently selected member to be deselected by a click? If not the selected member can change only by clicking on a currently not selected member.)
 *    currentlySelected: string (The last page segment of the index path of the currently selected member of the select-group.)
 *    deselectTarget: string (The last page segment of the index path of the member to select if the currently selected member is deselected.)
 *  },
 *  ...
 * ]
 * 
 * 
 * Each descendant participating in the select-group keeps this structure:
 * selectGroupMemberInfo: {
 *  contollerPathId: String (The full index path of the controller managing the select-group.)
 *  groupName: String (The name of the select-group.)
 * }
 * 
 * For children participating in a select-group in 'single-select-mode' the 'selected' attribute in the index path state is not relevant. 
 * It is 'shadowed' by the data kept in the selectGroupControllerState structure. 
 * 
 * For components participating in a select-group in 'single-select' mode the 'select-group' mechanism runs the following actions on behalf of the onClick handler of the component:
 *  - Set the 'selected' status of the component: The controller might decide to select another component than the one that runs the onClick handler.
 *  - Do the appropriate page switch: The controller will execute the page switch specified for the component that becomes actually selected.
 * 
 * For components participating in a select-group in 'single-select' mode the setSelected operator in the TermEvaluator will trigger the page switch configured for the actually selected component. 
 * (For other components the operator will not trigger a page switch configured for the component.) 
 * 
 */
export default class SelectGroupHelper {

  // methods for select group members:  ---------------------------------------------------------------------

  /**
   * Calculate the select flag for a member component by investigating the path state.
   * 
   * The method uses the select-group structures to determine the select status if the member component is controlled by a select-group controller in 'single-select' mode. 
   * Otherwise it falls back to the plain 'selected' flag in the path state of the child component. 
   */
  static extractSelectedState(pathState, controlledPathId, runtime) {
    const selectGroupMemberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(pathState);
    if (selectGroupMemberInfo === undefined) {
      return StateAttributeAccess.extractSelected(pathState);
    }

    const controllerState = StateAttributeAccess.extractSelectGroupControllerState(runtime.componentStateManager.findOrBuildStateForPathId(selectGroupMemberInfo.controllerPathId, runtime));
    const groupState = SelectGroupHelper.extractMatchingGroupState(controllerState, selectGroupMemberInfo.groupName);
    if (groupState === undefined || !groupState.singleSelectActive) {
      return StateAttributeAccess.extractSelected(pathState);
    }

    return IndexPathHelper.getLastPageSegmentFromPath(controlledPathId) === groupState.currentlySelected;
  }


  /**
   * Is the component member of a select-group (i.e. it is controlled by a select-group controller)?
   */
  static isSelectGroupMember(pathState) {
    return StateAttributeAccess.extractSelectGroupMemberInfo(pathState) !== undefined;
  }

  /**
   * Is the component controlled by a select-group controller in 'single-select' mode?
   */
  static isSingleSelectControlled(pathState, runtime) {
    const selectGroupMemberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(pathState);
    if (selectGroupMemberInfo === undefined) {
      return false;
    }

    const controllerState = StateAttributeAccess.extractSelectGroupControllerState(runtime.componentStateManager.findOrBuildStateForPathId(selectGroupMemberInfo.controllerPathId, runtime));
    const groupState = SelectGroupHelper.extractMatchingGroupState(controllerState, selectGroupMemberInfo.groupName);
    return groupState !== undefined && groupState.singleSelectActive;
  }


  /**
   * Handle a request to set the 'selected' status for a component that might be controlled by a select-group controller in 'single-select' mode: 
   * Update the controller's state and the component's state in the ComponentStateManager and trigger rendering on an appropriate scope.
   * 
   * For components that are in fact controlled by a select-group controller in 'single-select' mode
   * the method also does the page switch for the actually selected component if the 'singleSelectWithPageSwitch' parameter is set to true.
   * 
   * Components control themselves which page switch they do if they happen to be not controlled by a select-group controller in 'single-select' mode: 
   * They should use doPageSwitchOrLetTheContainerDoIt(...) to trigger a page switch.
   * 
   * @param {boolean} requestedSelectState The 'selected' state that is requested for the component that might be controlled by a select-group controller in 'single-select' mode.
   * @param {*} controlledPathId The index path of the component that might be controlled by a select-group controller in 'single-select' mode.
   * @param {*} controlledPathState The path state of the component that might be controlled by a select-group controller in 'single-select' mode.
   * @param {boolean} singleSelectWithPageSwitch Should we do the page switches of the actually selected component (for single-select mode case only)?
   * @param {*} runtime The common runtime context structure.
   */
  static setSelectedForPossiblyControlledComponent(requestedSelectState, controlledPathId, controlledPathState, singleSelectWithPageSwitch, runtime) {
    const selectGroupMemberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(controlledPathState);
    if (selectGroupMemberInfo === undefined) {
      // do standard selection flag setting for non-members:
      SelectGroupHelper.doStandardSelectFlagSetting(requestedSelectState, controlledPathId, controlledPathState, runtime);
    } else {
      const controllerState = StateAttributeAccess.extractSelectGroupControllerState(runtime.componentStateManager.findOrBuildStateForPathId(selectGroupMemberInfo.controllerPathId, runtime));
      const groupState = SelectGroupHelper.extractMatchingGroupState(controllerState, selectGroupMemberInfo.groupName);
      // do nothing for members of a blocked group:
      if (groupState !== undefined && !groupState.selectionChangesBlocked) {
        if (SelectGroupHelper.isSingleSelectControlled(controlledPathState, runtime)) {
          // notify parent container and let it trigger the new rendering:
          const { controllerPathId, groupName } = selectGroupMemberInfo;
          SelectGroupHelper.delegateSetSelectedToController(
            requestedSelectState,
            groupName,
            controllerPathId,
            controlledPathId,
            controlledPathState,
            singleSelectWithPageSwitch,
            runtime
          );
        } else {
          // do standard selection flag setting for members in a non-blocked multiple-select mode group:
          SelectGroupHelper.doStandardSelectFlagSetting(requestedSelectState, controlledPathId, controlledPathState, runtime);
        }
      }
    }
  }


  /**
   * Change the currently selected component in a select-group in 'single-select' mode.
   * 
   * The method manages the controller's state in the ComponentStateManager and registers the given state for the controlled component in the ComponentStateManager.
   * Finally it triggers a rendering on the controller level. 
   */
  static delegateSetSelectedToController(requestedSelectState, groupName, controllerPathId, controlledPathId, controlledPathState, withPageSwitch, runtime) {

    const controllerState = runtime.componentStateManager.findOrBuildStateForPathId(controllerPathId, runtime);

    SelectGroupHelper.handleSelectedChildChanges(requestedSelectState, groupName, controlledPathId, controllerState, controllerPathId, withPageSwitch, runtime);

    runtime.componentStateManager.registerStateByPathId(controllerPathId, controllerState);
    // We should register the given controlled state in any case to have a consistent contract with the caller:
    if (controlledPathId !== undefined && controlledPathState !== undefined) {
      runtime.componentStateManager.registerStateByPathId(controlledPathId, controlledPathState);
    }
    RenderingHelper.triggerRenderingViaPath(controllerPathId, runtime);

  }

  /**
   * Do the standard page switch for a component if the component is not controlled by a select-group controller in 'single-select' mode. 
   */
  static doPageSwitchOrLetTheContainerDoIt(component, pathState) {
    if (!SelectGroupHelper.isSingleSelectControlled(pathState, component.props.runtime)) {
      CommonActionsHelper.doPageSwitchForComponent(component);
    }
  }

  // methods for state initialization ------------------------------------------------------------------------------------------

  /**
   * Build the select settings in the initial state for a possibly controlled component. 
   */
  static addSelectGroupMemberInfo(pathState, componentType, pathId, runtime) {

    const selectGroupMemberConfig = SelectGroupHelper.getSelectGroupMemberPerConfig(pathId, runtime);

    if (
      (SelectGroupHelper.isControlledType(componentType) && selectGroupMemberConfig !== undefined)
    ) {

      const containerPageSegment = selectGroupMemberConfig.controllerPathId;
      const containerConfiguration = runtime.pageConfigurationsManager.findConfigurationForPageSegment(containerPageSegment).config;
      if (containerConfiguration.selectGroups !== undefined) {
        const containerPathId = IndexPathHelper.appendPageSegmentsToPath(IndexPathHelper.dropPageSegmentFromPath(pathId), containerPageSegment);
        const selectGroupMemberInfo = {
          controllerPathId: containerPathId,
          groupName: selectGroupMemberConfig.groupName
        };
        StateAttributeAccess.setSelectGroupMemberInfo(pathState, selectGroupMemberInfo);
      }
    } else if (SelectGroupHelper.isComboBoxItem(componentType)) {
      // combo box is pseudo select group controller -> use immediate parent (= combo box) if we are not a member of a real select-group
      const controlledPageSegment = IndexPathHelper.getLastPageSegmentFromPath(pathId);
      const containerPageSegment = IndexPathHelper.dropIndexFromPageSegment(controlledPageSegment);
      const containerPathId = IndexPathHelper.appendPageSegmentsToPath(IndexPathHelper.dropPageSegmentFromPath(pathId), containerPageSegment);
      const selectGroupMemberInfo = {
        controllerPathId: containerPathId,
        groupName: SelectGroupHelper.comboBoxGroup()
      };
      StateAttributeAccess.setSelectGroupMemberInfo(pathState, selectGroupMemberInfo);

    }
  }

  /**
   * Build the select-group controller settings for a potential select-group controller component.
   */
  static addSelectGroupControllerState(pathState, componentType, pathId, configProps) {
    const config = configProps.selectGroups;
    if (config !== undefined) {
      const controllerState = config.map((value, index, all) => ({
        name: value.name,
        selectionChangesBlocked: value.blockSelectionChanges,
        singleSelectActive: value.enforceSingleSelect,
        currentlySelected: value.initiallySelected,
        allowDeselect: value.allowDeselect,
        deselectTarget: value.deselectTarget
      }));
      StateAttributeAccess.setSelectGroupControllerState(pathState, controllerState);
    } else if (SelectGroupHelper.isComboBox(componentType)) {
      const initiallySelectedIndex = configProps.selectedEntry === undefined ? 0 : configProps.selectedEntry;
      const firstItemPath = IndexPathHelper.appendIndexToPageSegment(IndexPathHelper.getLastPageSegmentFromPath(pathId), initiallySelectedIndex);
      const controllerState = [{
        name: SelectGroupHelper.comboBoxGroup(),
        selectionChangesBlocked: false,
        singleSelectActive: true,
        currentlySelected: firstItemPath,
        allowDeselect: true,
        deselectTarget: firstItemPath
      }]
      StateAttributeAccess.setSelectGroupControllerState(pathState, controllerState);
    }
  }

  // methods for term evaluator ----------------------------------------------------------------------------------------------------------------------

  /**
   * Set the 'blockSelectionChanges' attribute in the select-group configuration for the group controller specified by the user defined id path.
   * 
   * The method propagates the value change to a parent select-group controller if the propagateChanges flag is set.
   */
  static setSelectionChangesBlockedForController(controllerUserDefPath, groupName, value, runtime) {
    SelectGroupHelper.setStateAttributeForControllerByUserDefPath(controllerUserDefPath, groupName, (groupState) => { groupState.selectionChangesBlocked = value; }, runtime);
  }

  /**
   * Set the 'singleSelectActive' attribute in the select-group configuration for the group controller specified by the user defined id path.
   * 
   * The method propagates the value change to a parent select-group controller if the propagateChanges flag is set.
   */
  static setSingleSelectActiveForController(controllerUserDefPath, groupName, value, runtime) {
    SelectGroupHelper.setStateAttributeForControllerByUserDefPath(controllerUserDefPath, groupName, (groupState) => { groupState.singleSelectActive = value; }, runtime);
  }

  // private stuff -----------------------------------------------------------------------------------------------------------------------------------

  /**
   * Helper method to find the select-group configuration for the group with the given name.
   */
  static extractMatchingGroupState(controllerState, groupName) {
    const matchingGroups = controllerState.filter(value => (value.name === groupName));
    return matchingGroups.length > 0 ? matchingGroups[0] : undefined;
  }

  /**
   * Private helper method.
   */
  static setStateAttributeForControllerByUserDefPath(controllerUserDefPath, groupName, controllerAttributeSetter, runtime) {
    const controllerIndexPath = PathTranslationHelper.getIndexPathForUserDefPath(controllerUserDefPath, runtime);
    SelectGroupHelper.setStateAttributeForControllerByIndexPath(controllerIndexPath, groupName, controllerAttributeSetter, runtime);
  }

  /**
   * Private helper method.
   */
  static setStateAttributeForControllerByIndexPath(controllerIndexPath, groupName, controllerAttributeSetter, runtime) {
    const { componentStateManager } = runtime;
    const fullControllerState = componentStateManager.findOrBuildStateForPathId(controllerIndexPath, runtime);
    const controllerSelectGroupsState = StateAttributeAccess.extractSelectGroupControllerState(fullControllerState);
    if (controllerSelectGroupsState === undefined) {
      console.log(`Ignored request to set controller mode for a component that isn't a select-group controller: ${controllerIndexPath}`);
      return;
    }
    const groupState = SelectGroupHelper.extractMatchingGroupState(controllerSelectGroupsState, groupName);
    const oldSingleSelectActive = groupState.singleSelectActive;
    const oldSelectedPageSegment = groupState.currentlySelected;

    controllerAttributeSetter(groupState);

    // Rearrange select flags of members and currentlySelected index in controller if we switch between single-select and multi-select mode:
    const newSingleSelectActive = groupState.singleSelectActive;
    if (newSingleSelectActive !== oldSingleSelectActive) {
      if (newSingleSelectActive === false) {
        // Set select flags to false for all members except the currently selected one according to the old selected page segment.
        SelectGroupHelper.applyToMembers(
          controllerIndexPath,
          (fullMemberState, memberPageSegment) => {
            StateAttributeAccess.setSelected(fullMemberState, oldSelectedPageSegment === memberPageSegment);
          },
          runtime
        );
      } else {
        // Set selected flags to false for all members.
        groupState.currentlySelected = undefined;
        SelectGroupHelper.applyToMembers(
          controllerIndexPath,
          (fullMemberState, memberPageSegment) => {
            StateAttributeAccess.setSelected(fullMemberState, false);
          },
          runtime
        );
      }
    }
    componentStateManager.registerStateByPathId(controllerIndexPath, fullControllerState, runtime);
  }

  /**
   * Private helper method.
   */
  static applyToMembers(controllerIndexPath, methodToApply, runtime) {
    const { componentStateManager } = runtime;
    const memberPaths = ComponentStateHelper.findIndexPathsOfDescendants(controllerIndexPath, runtime);
    memberPaths
      .forEach((memberPath) => {
        const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(memberPath);
        const { pageConfigurationsManager } = runtime;
        const memberConfiguration = pageConfigurationsManager.findConfigurationForPageSegment(pageSegment, true);

        if (memberConfiguration !== undefined) {
          const fullMemberState = componentStateManager.findOrBuildStateForPathId(memberPath, runtime);
          const memberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(fullMemberState);
          if (memberInfo !== undefined && memberInfo.controllerPathId === controllerIndexPath) {
            methodToApply(fullMemberState, pageSegment);
            componentStateManager.registerStateByPathId(memberPath, fullMemberState, runtime);
          }
        }

      });
  }


  /**
   * Private helper method.
   * 
   */
  static handleSelectedChildChanges(requestedSelectState, groupName, clickSelectedPathId, controllerState, controllerPath, withPageSwitch, runtime) {
    const selectGroupControllerState = StateAttributeAccess.extractSelectGroupControllerState(controllerState);
    const groupState = SelectGroupHelper.extractMatchingGroupState(selectGroupControllerState, groupName);
    const newIndexResult = SelectGroupHelper.calculateNewSelectedIndex(requestedSelectState, groupState, IndexPathHelper.getLastPageSegmentFromPath(clickSelectedPathId));

    if (newIndexResult.noChange === false) {
      groupState.currentlySelected = newIndexResult.newMember;
      StateAttributeAccess.setSelectGroupControllerState(controllerState, selectGroupControllerState);
    }

    // We have to do the page switches since we sometimes select another button 
    // than the one clicked on by the user. In such a case the button's onClick handler 
    // would do the wrong page switch.
    if (groupState.currentlySelected !== undefined && withPageSwitch) {
      SelectGroupHelper.doPageSwitchForSelectedSwitcher(controllerPath, groupState.currentlySelected, runtime);
    }

  }

  /**
   * Private helper method.
   * 
   * Calculate the page segment of the currently selected component. 
   * The method returns 'undefined' if the currently selected component does not change.
   */
  static calculateNewSelectedIndex(requestedSelectState, groupState, clickedSelectedPageSegment) {
    if (requestedSelectState === true) {
      if (groupState.currentlySelected === clickedSelectedPageSegment) {
        // Component was selected and selected is requested -> signal no change:
        return {
          noChange: true
        }
      } else {
        // Switching selection to another component is always possible:
        return {
          noChange: false,
          newMember: clickedSelectedPageSegment
        };
      }
    } else if (groupState.currentlySelected !== clickedSelectedPageSegment) {
      // Component was not selected and not selected is requested -> signal no change:
      return {
        noChange: true
      };
    } else {
      // Component was selected and not selected is requested.
      const { allowDeselect, deselectTarget } = groupState;
      if (!allowDeselect) {
        // Deselect is not allowed -> signal no change:
        return {
          noChange: true
        };
      } else if (deselectTarget !== undefined) {
        // Jump to deselect target if we aren't there anyhow:
        if (deselectTarget === clickedSelectedPageSegment) {
          return {
            noChange: true
          }
        } else {
          return {
            noChange: false,
            newMember: deselectTarget
          }
        }
      } else {
        // No deselect target defined -> set currently selected to undefined
        return {
          noChange: false,
          newMember: undefined
        }
      }
    }
  }

  /**
   * Private helper method.
   */
  static doPageSwitchForSelectedSwitcher(controllerPath, selectedSwitcherPageSegment, runtime) {
    // We don't do any switch if no switcher is selected:
    if (selectedSwitcherPageSegment !== undefined) {
      const selectedSwitcherPath = IndexPathHelper.appendPageSegmentsToPath(IndexPathHelper.dropPageSegmentFromPath(controllerPath), selectedSwitcherPageSegment);

      const pathState = runtime.componentStateManager.findOrBuildStateForPathId(selectedSwitcherPath, runtime);
      const defaultLinkReceiver = StateAttributeAccess.extractDefaultLinkReceiver(pathState);
      const selectedSwitcherConfig = runtime.pageConfigurationsManager.findConfigurationForPageSegment(selectedSwitcherPageSegment);

      CommonActionsHelper.doPageSwitch(selectedSwitcherConfig.config.link, runtime, defaultLinkReceiver, controllerPath);
    }
  }

  /**
   * Private helper method.
   */
  static doStandardSelectFlagSetting(requestedSelectState, controlledPathId, controlledPathState, runtime) {
    StateAttributeAccess.setSelected(controlledPathState, requestedSelectState);
    runtime.componentStateManager.registerStateByPathId(controlledPathId, controlledPathState);
    RenderingHelper.triggerRenderingViaPath(controlledPathId, runtime);
  }

  static isControlledType(componentType) {
    return componentType === 'CbaRadioButton'
      || componentType === 'CbaCheckbox'
      || componentType === 'CbaButton'
      || componentType === 'CbaLink'
      || componentType === 'CbaRichTextField'
      || componentType === 'CbaPolygon'
      || componentType === 'CbaRegionMap';
  }

  static isComboBoxItem(componentType) {
    return componentType === CbaComboBox.getItemType();
  }

  static isComboBox(componentType) {
    return componentType === 'CbaComboBox';
  }

  static comboBoxGroup() {
    return 'comboGroup';
  }

  static getSelectGroupMemberPerConfig(pathId, runtime) {
    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(pathId);
    const { config } = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    return config.selectGroupMember;
  }

}
