import React from 'react';
import PathTranslationHelper from '../state/PathTranslationHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
/**
 * Helper methods to manage the currently rendered components and trigger them to render.
*/
export default class RenderingHelper {

  // registration of currently rendered components ----------------------------------------------------

  /* not used methods work done for #5249
  static initialiseStateForComponents(aditionalState = {}) {
    return Object.assign(aditionalState, {
      componentPath: ""
    })
  }
  */

  static onMount(component) {
    if (component.props.path === undefined) {
      console.warn('Rendering helper cannot register component without index path', component.props);
      return;
    }
    RenderingHelper.doRegistrations(component, component.props.path);
  }

  static onUnmount(component) {
    if (component.props.path === undefined) {
      console.warn('Rendering helper cannot deregister component without index path', component.props);
      return;
    }
    RenderingHelper.doDeregistrations(component, component.props.path);
  }

  /* Depracted see #5249 */
  static onReceiveProps(component, nextProps) {
    if (component.props.path === undefined) {
      console.warn('Rendering helper cannot update from props without index path', component.props);
      return;
    }
    if (nextProps.path === undefined) {
      console.warn('Rendering helper cannot update to props without index path', nextProps);
      return;
    }
    if (!(component.props.path === nextProps.path)) {
      // component.props.runtime.componentDirectory.deregisterComponent(component.props.path);
      RenderingHelper.doDeregistrations(component, component.props.path);
      RenderingHelper.doRegistrations(component, nextProps.path);
    }
  }

  /* not used methods work done for #5249
  static onGetDerivedStateFromProps(nextProps, prevState) {
    if (prevState && prevState.componentPath !== "" && nextProps.path !== prevState.componentPath) {
      console.log("in get derived state from props", prevState)

      const { runtime } = nextProps;
      const component = runtime.componentDirectory.findComponent(prevState.componentPath);

      if (prevState.componentPath) {
        console.warn('Rendering helper cannot update to props without index path', prevState.componentPath);
        return null;
      }

      if (nextProps.path) {
        console.warn('Rendering helper cannot update to props without index path', nextProps.path);
        return null;
      }

      RenderingHelper.deregisterComponent(prevState.componentPath);
      RenderingHelper.registerComponent(nextProps.path, component);

      return Object.assign({}, prevState, {
        componentPath: nextProps.path
      });
    }

    return null;
  }

  static onComponentDidUpdate(component, prevProps, prevState) {
    if (component.props.path === undefined) {
      console.warn('Rendering helper cannot update from props without index path', component.props);
      return;
    }
    if (prevProps.path === undefined) {
      console.warn('Rendering helper cannot update to props without index path', prevProps);
      return;
    }

    if (prevProps.path !== component.props.path) {
      console.log("in update");
      component.setState({
        componentPath: component.props.path,
      });
    }
  }
  */

  static doRegistrations(component, path) {
    const { runtime, config } = component.props;
    runtime.componentDirectory.registerComponent(path, component);
    CommonConfigHelper.getObservedStatemachineVariableNames(config).forEach((variableName) => {
      runtime.variableManager.addVariableChangeObserver(path, variableName);
    })
    RenderingHelper.applyRegisteredActions(component, path, runtime);
  }

  static doDeregistrations(component, path) {
    const { runtime } = component.props;
    runtime.componentDirectory.deregisterComponent(path);
    runtime.variableManager.dropChangeObserverForPath(path);
  }

  // trigger rendering mechanism using the React state ----------------------------------------------------
  static triggerRenderingViaUserDefPath(userDefPath, runtime) {
    RenderingHelper.triggerRenderingViaPath(PathTranslationHelper.getIndexPathForUserDefPath(userDefPath, runtime), runtime);
  }

  static triggerRenderingViaPath(path, runtime) {
    const receiverComponent = runtime.componentDirectory.findComponent(path);
    if (receiverComponent !== undefined) {
      RenderingHelper.triggerRendering(receiverComponent);
    } else {
      console.log(`Receiver not mounted: ${path}`);
    }
  }

  static triggerRendering(component) {
    component.setState(previousState => RenderingHelper.buildCounterState(previousState));
  }

  static buildCounterState(previousState) {
    const oldUpdateCount = previousState === null ? 0 : previousState.updateCount;
    return {
      updateCount: RenderingHelper.incrementCount(oldUpdateCount)
    };
  }

  static incrementCount(oldCount) {
    return oldCount === undefined || oldCount > 10000 ? 1 : (oldCount + 1);
  }

  /**
   * Split a text containing line breaks into an array of spans separated with breaks.
   * 
   * @param {*} text Text containing line breaks.
   */
  static extractMultilineText(text) {
    return text.split('\r\n').map((line, index) => (
      // Use the index plus the text of the line to be displayed as key. If the text is too long just use the index and and a hash of the line.
      <span key={line.length > 32 ? `${index}_${RenderingHelper.hash64(line)}` : `${index}_${line}`}>
        {line}
        <br />
      </span>
    ));
  }

  /**
   * Calculate a 32 bit FNV-1a hash
   * Found here: https://gist.github.com/vaiorabbit/5657561
   * Ref.: http://isthe.com/chongo/tech/comp/fnv/
   *
   * @param {string} str the input value
   * @returns {string}
  */
  static hashFnv32a(str) {
    let hval = 0x811c9dc5;

    for (let i = 0; i < str.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      hval ^= str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    // eslint-disable-next-line no-bitwise
    return (`0000000${(hval >>> 0).toString(16)}`).substr(-8);
  }

  /**
   * Calculate a simple 64 bit hash to reduce collision probability.
   * 
   * @param {String} string to be hashed 
   */
  static hash64(str) {
    const shortHash = RenderingHelper.hashFnv32a(str);
    return shortHash + RenderingHelper.hashFnv32a(shortHash + str);
  }

  /**
   * Checks for registered actions and applies them to the component.
   * Once the actions were applied we clear them from the register.
   * @param {*} component the component instance 
   * @param {String} indexPath The index path of the calling display component instance.
   * @param {*} runtime The common runtime context structure
   */
  static applyRegisteredActions(component, indexPath, runtime) {
    if (runtime.actionRegister.hasActionsRegisteredForPath(indexPath)) {
      runtime.actionRegister.getRegisteredActionsForPath(indexPath).forEach(action => component[action].call());
      runtime.actionRegister.clearRegisteredActionsForPath(indexPath);
    }
  }

}
