import StateAttributeAccess from './StateAttributeAccess';
import ComponentStateHelper from './ComponentStateHelper';
import CommonActionsHelper from '../components/CommonActionsHelper';
import RenderingHelper from '../components/RenderingHelper';
import UserDefPathHelper from './UserDefPathHelper';
import PathTranslationHelper from './PathTranslationHelper';
import StateManagerHelper from './StateManagerHelper';

/**
 * Coordinate processing of recommendations, e.g. 
 * - highlight the test in the TaskNavigator, 
 * - jump to the recommended task once the recommended test is selected, 
 * - activate the recommended control once the recommended task is selected
 */
export default class RecommendationsManager {

  constructor(runtime) {
    this.runtime = runtime;

    this.recommendations = [];
    this.taskNavigator = undefined;
  }

  // public API -------------------------------------------------------------------

  /**
   * The method expects an array of recommendation objects. 
   * Each recommendation object has the attributes
   *  - testName
   *  - taskName
   *  - absoluteUserDefId (a UserDefinedIDPath like /pageType=standard/id=myPageAreaId/id=myComponentId)
   */
  setRecommendations = (recommendations) => {
    const oldRecommendations = this.recommendations;
    this.recommendations = StateManagerHelper.deepCopy(recommendations);

    RecommendationsManager.traceRecommendationChange(oldRecommendations, this.recommendations, this.runtime);

    if (this.taskNavigator !== undefined) {
      this.taskNavigator.highlightRecommendations(this.recommendations);
    }

    // refresh display components affected by dropping the old recommendations 
    // and by setting the new recommendations.
    // Note: Do this after setting the new recommendations to our this.recommendations member!
    RecommendationsManager.refreshRecommendedComponents(oldRecommendations, this.runtime)
    RecommendationsManager.refreshRecommendedComponents(this.recommendations, this.runtime)

  }


  /**
   * Initialize our reference to the TaskNavigator. 
   */
  setTaskNavigator = (taskNavigator) => {
    this.taskNavigator = taskNavigator;
  }

  /**
   * Is the given display component instance (which must belong to the current running task)
   * recommended currently?
   * 
   * The method obtains the currently running test/task from the task manager.
   * It translates the given index path to a userDefID path via PathTranslationHelper
   * (which works for the currently running task only).
   * 
   * For each recommendation the method checks the test/task match and the existence of a userDefIdPath specification 
   * first and only for a successful match it will run the path translation. 
   * Since we expect short recommendation lists the effort for checking the recommendations for each 
   * display component instance all the time should be small.
   */
  isRecommended = (indexPath) => {
    const { runtime } = this;
    const currentTaskInfo = runtime.taskManager.getCurrentTestTaskItemNames();
    let result = false;
    this.recommendations.forEach((value, index, array) => {
      if (value.testName === currentTaskInfo.test && value.taskName === currentTaskInfo.task && value.absoluteUserDefId !== undefined) {
        const userDefIDPath = PathTranslationHelper.getUserDefPathForIndexPath(indexPath, runtime);
        if (userDefIDPath !== undefined && value.absoluteUserDefId === userDefIDPath) {
          result = true;
        }
      }
    });
    return result;
  }

  /**
   * Do further processing for a task that was chosen by the
   * user in the task navigator.
   * 
   * The method will look for recommendations on this task and
   *  - for each recommendation it will set the background color of the display component instance specified to the 'recommendation' color.
   *  - for the display component instance specified in the first matching recommendation it will
   *    + set the selected state to true and
   *    + do the page switch according to the display component's configuration. 
   */
  processTaskSelected = (testName, itemName, taskName) => {
    const { runtime } = this;
    const firstRecommendation = RecommendationsManager.findFirstRecommendationForTask(this.recommendations, testName, taskName);
    RecommendationsManager.setSelectedOnRecommendedComponent(firstRecommendation, runtime);
    RecommendationsManager.doRecommendedPageSwitch(firstRecommendation, runtime);
  }

  /**
   * Find the first recommendation that applies to the given test name.
   */
  static findFirstRecommendationForTest(recommendations, testName) {
    return recommendations.find((value, index, theArray) => value.testName === testName);
  }

  /**
   * Find the first recommendation that applies to the given test name and task name.
   */
  static findFirstRecommendationForTask(recommendations, testName, taskName) {
    return recommendations.find((value, index, theArray) => (value.testName === testName) && (value.taskName === taskName));
  }

  /**
   * Get the standard background color for recommended items.
   */
  static getRecommendationColor() {
    return "rgba(206, 239, 253, 1)";
  }

  // private stuff --------------------------------------------------------------------

  /**
   * Report a recommendations change in the trace log.
   */
  static traceRecommendationChange(oldRecommendations, newRecommendations, runtime) {
    const { traceLogBuffer } = runtime;
    traceLogBuffer.reportEvent('Recommend', new Date(), {
      oldRecommendations: RecommendationsManager.buildRecommendationListForTrace(oldRecommendations),
      newRecommendations: RecommendationsManager.buildRecommendationListForTrace(newRecommendations),
    })
  }

  /**
   * Transform the recommendations objects in the recommendations list 
   * to the form used in the trace log.
   */
  static buildRecommendationListForTrace(recommendations) {
    return recommendations.map(recommendation => (
      {
        testName: recommendation.testName,
        taskName: recommendation.taskName,
        userDefIdPath: recommendation.absoluteUserDefId,
        userDefId: UserDefPathHelper.getLastUserDefIdFromPath(recommendation.absoluteUserDefId)
      }
    ));
  }

  /**
   * Set the selected status of the display component specified in the given recommendation to true. 
   */
  static setSelectedOnRecommendedComponent(recommendation, runtime) {
    if (recommendation !== undefined && recommendation.absoluteUserDefId !== undefined) {
      ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractSelected, StateAttributeAccess.setSelected, true, recommendation.absoluteUserDefId, runtime, true);
    }
  }

  /**
   * Perform the page switch specified in the link configuration of the display component 
   * 
   */
  static doRecommendedPageSwitch(recommendation, runtime) {
    if (recommendation !== undefined && recommendation.absoluteUserDefId !== undefined) {
      const { pageConfigurationsManager } = runtime;
      const userDefinedId = UserDefPathHelper.getLastUserDefIdFromPath(recommendation.absoluteUserDefId);
      const targetPageSegment = pageConfigurationsManager.findPageSegmentForUserDefId(userDefinedId);
      if (targetPageSegment === undefined) {
        console.warn(`Recommendation switch could not find page segment for user defined ID ${userDefinedId}`);
        return;
      }
      const targetComponentConfig = pageConfigurationsManager.findConfigurationForPageSegment(targetPageSegment);
      const pathState = runtime.componentStateManager.findOrBuildStateByUserDefPath(recommendation.absoluteUserDefId, runtime);
      const defaultLinkReceiver = StateAttributeAccess.extractDefaultLinkReceiver(pathState);
      CommonActionsHelper.doPageSwitch(targetComponentConfig.config.link, runtime, defaultLinkReceiver, undefined);
    }
  }

  static refreshRecommendedComponents(recommendations, runtime) {
    const currentTaskInfo = runtime.taskManager.getCurrentTestTaskItemNames();
    recommendations.forEach((value, index, array) => {
      if (value.testName === currentTaskInfo.test && value.taskName === currentTaskInfo.task && value.absoluteUserDefId !== undefined) {
        RenderingHelper.triggerRenderingViaUserDefPath(value.absoluteUserDefId, runtime);
      }
    });
  }

}
