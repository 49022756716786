import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TreeUtils from '../TreeUtils';
import TreeContextMenuHelper from '../TreeContextMenuHelper';
import CommonActionsHelper from '../../CommonActionsHelper';
import ComponentStateHelper from '../../../state/ComponentStateHelper';
import StateAttributeAccess from '../../../state/StateAttributeAccess';
import Utils from '../../../utils/Utils';
import TreeTraceHelper from '../TreeTraceHelper';
import PropTypesHelper from '../../PropTypesHelper';


export default class CbaTreeCell extends Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.inputRef = React.createRef();
  }

  handleContextMenuClick = (event, updateOnSingleClick) => {
    const { rowData, column } = this.props;
    const { onRowSelect, runtime, isReadOnly } = rowData;

    if (!runtime.contextMenu) console.error("Context menu not found in tree row", rowData);

    if (updateOnSingleClick) {
      onRowSelect(event, rowData);
    }

    if (!isReadOnly) {
      TreeContextMenuHelper.openContextMenu(event, rowData, column.key);
    }
    CommonActionsHelper.stopEventPropagation(event);
  }

  handleRenameCell = () => {
    // setTimeout needed because method is called from render function
    setTimeout(() => {
      this.setState({
        isRenameState: true,
        cellEditedValue: this.getInitialState().cellEditedValue
      }, () => {
        const { rowData } = this.props;
        const { treePath, runtime } = rowData;
        const treeInstance = runtime.componentDirectory.findComponent(treePath);
        const pathState = ComponentStateHelper.getState(treeInstance);
        const treeState = StateAttributeAccess.extractTreeState(pathState) || {};

        treeState.currentRenameNode = null;

        StateAttributeAccess.setTreeState(pathState, treeState);
        ComponentStateHelper.registerState(treeInstance, pathState);

        this.inputRef.current.focus();
      });
    }, 0)
  }

  handleCellInputFocus = (event) => {
    event.target.select();
  }

  handleCellInputBlur = (event) => {
    const { rowData, column } = this.props;
    const { cellEditedValue } = this.state;
    const initialState = this.getInitialState();

    if (initialState.cellEditedValue !== cellEditedValue) {
      TreeUtils.doTreeRename(rowData, column.key, cellEditedValue);
    }

    this.setState(this.getInitialState());
  }

  handleCellInputKeyDown = (event) => {
    event.stopPropagation();
    if (Utils.keyCodes.Enter === event.keyCode) {
      // blur to save state
      this.inputRef.current.blur();
    }

    if (Utils.keyCodes.Escape === event.keyCode) {
      // ESC to cancel saving state
      this.setState(this.getInitialState())
    }
  }

  handleCellInputChange = (event) => {
    event.stopPropagation();
    const newValue = event.target.value;

    this.setState(() => ({
      cellEditedValue: newValue
    }))

    return false;
  }

  onDoubleClick = (event, updateOnSingleClick, onRowSelect, rowData) => {
    event.stopPropagation();
    const { treePath, runtime } = rowData;
    TreeTraceHelper.traceNodeAction("doubleClick", treePath, rowData, runtime);

    if (!updateOnSingleClick) {
      onRowSelect(event, rowData)
    }
  }

  getCellData = () => {
    const { rowData, column } = this.props;
    const { cells } = rowData;
    return cells.find(cell => cell.columnId === column.key) || {};
  }

  getInitialState = () => {
    const cellData = this.getCellData();
    return {
      isRenameState: false,
      cellEditedValue: cellData ? cellData.label : ""
    }
  }

  render() {
    const { rowData, idx, column } = this.props;
    const { isRenameState, cellEditedValue } = this.state;
    const { expanded, nodes, imagePath, depth, isLeaf, onRowExpandClick, onRowSelect, updateOnSingleClick, isTreeView, path: rowPath, runtime, treePath } = rowData;
    const cellData = this.getCellData();

    // JSX Conditionals
    const hasSubRows = (nodes.length !== 0);

    // CellData
    const value = cellData.label || "";
    const title = cellData.mouseOver || "";

    // Styles
    const rowStartStyle = {
      paddingLeft: `${(depth - 1) * 16}px`
    }
    const rowImageStyle = {
      backgroundImage: imagePath ? `url(${imagePath})` : null
    }
    const cellOuterStyle = {
      width: column.width,
      height: rowData.height,
      left: column.left
    }
    const cellStyle = TreeUtils.buildTreeCellStyle(cellData);


    // click logic
    let updateOnClick;
    if (isTreeView) {
      updateOnClick = isLeaf;
    } else {
      updateOnClick = updateOnSingleClick;
    }

    // check if renamed
    const { treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const { currentRenameNode } = treeState;
    if (currentRenameNode && currentRenameNode.cellPath === TreeUtils.buildCellPath(rowPath, column.key) && currentRenameNode.isTreeView === isTreeView) {
      this.handleRenameCell();
    }

    return (
      <div className="react-grid-Cell" style={cellOuterStyle}>
        <div
          className="CbaTreeCellInner"
          onClick={event => (updateOnClick ? onRowSelect(event, rowData) : event.stopPropagation())}
          onDoubleClick={event => this.onDoubleClick(event, updateOnClick, onRowSelect, rowData)}
          onContextMenu={event => this.handleContextMenuClick(event, updateOnClick)}
          title={title}
          style={cellStyle}
        >
          {idx === 0 && (
            <div className="CbaTreeCellInner-rowStart" style={rowStartStyle}>
              <div
                className={`CbaTreeCellInner-expander ${expanded ? "expanded" : ""} ${hasSubRows ? "hasSubRows" : ""}`}
                onClick={event => onRowExpandClick(event, rowData)}
              />
              <div className="CbaTreeCellInner-image" style={rowImageStyle} />
            </div>
          )}

          {!isRenameState && <div className="CbaTreeCellInner-value" title={value}>{value}</div>}
          {isRenameState && (
            <input
              ref={this.inputRef}
              type="text"
              value={cellEditedValue}
              onBlur={this.handleCellInputBlur}
              onFocus={this.handleCellInputFocus}
              onKeyDown={this.handleCellInputKeyDown}
              onChange={this.handleCellInputChange}
            />
          )}
        </div>
      </div>
    )
  }

}

CbaTreeCell.propTypes = {
  column: PropTypes.shape(PropTypesHelper.getCbaTreeColumnConfig()),
  rowData: PropTypes.shape(PropTypesHelper.getCbaTreeRowConfig()).isRequired,
  idx: PropTypes.number.isRequired
}


CbaTreeCell.defaultProps = {
  column: {
    label: "default"
  }
}
