import React from 'react'
import { useDragLayer } from 'react-dnd'
import { DndItemTypes } from './DragAndDropHelper'
import Utils from '../utils/Utils';

/**
 * A component to use as custom made drag layer for the drag&drop library.
 * 
 * We use this for the touch screen scenario since the touch backend of the dnd library
 * does not show feedback during the drag operation out of the box (as the HTML5 backend does).
 */

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

const CustomDragLayer = (props) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  function renderItem() {
    switch (itemType) {
      case DndItemTypes.VALUE_DISPLAY:
      {
        // The drag source (a SimpleTextField in the value display case) 
        // fills the 'previewData' field in the item structure
        // so that we can show a similar textarea as the SimpleTextField does
        // during the drag operation.
        // The left/top coordinates should be zero since the div used
        // for all drag&drop item types follows the 
        // mouse pointer via the 'translate' technique already.
        const clippedStyle = Utils.safeDeepCopy(item.previewData.style);
        clippedStyle.left = 0;
        clippedStyle.top = 0;
        return (
          <textarea
            readOnly
            title={item.previewData.title}
            style={clippedStyle}
            value={item.previewData.value}
            className="hide-selection"
          />
        )
      }
      default:
        // We drag some item type that is not supported by the drag layer yet.
        // (Should never happen.)
        return <div>Dragging...</div>
    }
  }
  if (!isDragging) {
    return null
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset)}
      >
        {renderItem()}
      </div>
    </div>
  )
}
export default CustomDragLayer
