import React from 'react';
import PropTypes from 'prop-types';
import CommonActionsHelper from "../CommonActionsHelper";
import IndexPathHelper from '../../state/IndexPathHelper'

/**
 * A display component used by CbaRichTextField to display 'embedded links'. 
 */
class DraftLink extends React.Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    const { runtime, parentPath } = this.props;
    runtime.componentDirectory.registerComponent(DraftLink.buildMyPath(parentPath, this.getData()), this);
  }

  componentWillUnmount() {
    const { runtime, parentPath } = this.props;
    runtime.componentDirectory.deregisterComponent(DraftLink.buildMyPath(parentPath, this.getData()));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { runtime, parentPath } = this.props;
    if (!(parentPath === nextProps.parentPath)) {
      runtime.componentDirectory.deregisterComponent(DraftLink.buildMyPath(parentPath, this.getData()));
      runtime.componentDirectory.registerComponent(DraftLink.buildMyPath(nextProps.parentPath, nextProps.contentState.getEntity(nextProps.entityKey).getData()), this);
    }
  }

  onClickHandler(event) {
    const { runtime, parentPath, linkReceiver } = this.props;

    const data = this.getData();
    const myPath = DraftLink.buildMyPath(parentPath, data)

    CommonActionsHelper.doBasicOnClick(event, myPath, runtime);
    CommonActionsHelper.traceUserInteraction('EmbeddedLink', myPath, undefined, event, undefined, runtime);

    // Update the 'visited' state: 
    const state = DraftLink.getOrBuildState(parentPath, data, runtime);
    state.selected = true;
    DraftLink.persistState(state, parentPath, data, runtime);

    CommonActionsHelper.doPageSwitch(data.link, runtime, linkReceiver, parentPath);
  }

  handleKeyDown(event) {
    if (event.keyCode === 32) {
      // space key
      this.onClickHandler(event);
    }
  }

  getData() {
    const { contentState, entityKey } = this.props;
    return contentState.getEntity(entityKey).getData();
  }

  static getOrBuildState(parentPath, data, runtime) {
    return runtime.componentStateManager.findOrBuildStateForPathId(DraftLink.buildMyPath(parentPath, data), runtime);
  }

  static persistState(fullState, parentPath, data, runtime) {
    runtime.componentStateManager.registerStateByPathId(DraftLink.buildMyPath(parentPath, data), fullState);
  }

  static buildMyPath(parentPath, data) {
    return IndexPathHelper.appendIndexToPageSegment(parentPath, data.id);
  }

  static buildStyle(state, data) {
    return {
      color: state.selected ? data.color.visited : data.color.notVisited,
      cursor: "pointer"
    }
  }

  render() {
    const { runtime, parentPath, children, tabIndex } = this.props;
    const data = this.getData();
    const state = DraftLink.getOrBuildState(parentPath, data, runtime);
    const style = DraftLink.buildStyle(state, data);
    const className = style.color !== undefined ? 'cba-link' : undefined;

    return (
      <span
        className={`CbaLink ${className}`}
        style={style}
        onClick={this.onClickHandler}
        onKeyDown={e => this.handleKeyDown(e)}
        tabIndex={tabIndex}
      >
        {children}
      </span>
    );
  }

}

/*
  Function which specifies the strategy in finding link entities in DraftJS content
*/
const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

DraftLink.propTypes = {
  runtime: PropTypes.object.isRequired,
  parentPath: PropTypes.string.isRequired,
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  linkReceiver: PropTypes.string,
  tabIndex: PropTypes.number
}

DraftLink.defaultProps = {
  linkReceiver: undefined,
  tabIndex: -1
}

export { DraftLink, findLinkEntities }
