import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import CbaInterpreter from './CbaInterpreter';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import DragAndDropHelper, { DndItemTypes } from '../config/DragAndDropHelper';
import RenderingHelper from './RenderingHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import Utils from '../utils/Utils';

/**
 * A 'bare function component' used to manage the drag&drop functionality for the CbaContainer component.
 */
function ContainerRenderer({ onClick, onContextMenu, title, style, className, onScroll, children, config, path, runtime }) {

  const ref = useRef(null);
  const [dropCollectedProps, drop] = DragAndDropHelper.addDrop(config, path, runtime, DndItemTypes.VALUE_DISPLAY, true);
  drop(ref);

  const extendedStyle = Utils.safeDeepCopy(style);
  DragAndDropHelper.addCanDropStyleAttributes(extendedStyle, dropCollectedProps);

  return (
    <div
      ref={ref}
      onClick={onClick}
      onContextMenu={onContextMenu}
      title={title}
      style={extendedStyle}
      data-cba-id={config.pageEditId}
      className={className}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
}


export default class CbaContainer extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  render() {
    const { config, runtime, path: controllerPath, orientation } = this.props;

    const pathState = ComponentStateHelper.getState(this);
    const selectedState = StateAttributeAccess.extractSelected(pathState);

    const delegates = config.cbaChildren.map((child, index) => {
      const childPath = IndexPathHelper.appendIndexToPageSegment(controllerPath, index);
      return (
        <CbaInterpreter
          key={childPath}
          config={child}
          path={childPath}
          runtime={runtime}
          orientation={orientation}
        />
      )
    });

    const containerStyle = CommonConfigHelper.buildStyleByIndexPath(controllerPath, config, selectedState, orientation, runtime);

    return (
      <ContainerRenderer
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        title={CommonConfigHelper.buildTitle(config)}
        style={containerStyle}
        className="overflow-hidden"
        onScroll={event => event.stopPropagation()}
        config={config}
        path={controllerPath}
        runtime={runtime}
      >
        {delegates}
      </ContainerRenderer>
    )

  }

}

CbaContainer.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addSelectGroupControllerConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(true))
  ).isRequired,
  orientation: PropTypes.string.isRequired,
}

ContainerRenderer.propTypes = {
  onClick: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onScroll: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addSelectGroupControllerConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(true))
  ).isRequired,
  path: PropTypes.string.isRequired,
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
}
