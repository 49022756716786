import ValueFormatter from "./ValueFormatter";
import EvaluatorHelper from "./EvaluatorHelper";

// renderer is doing also scaling - strange that a renderer is doing this.
// it should just format it for displaying -> move scaling to evaluator (than doNotRemoveDecimalPoint can be removed)
export default class Renderer {

  static msg = {
    errorMessage: "Err",
    nroot: "nroot"
  };

  constructor(displayWidth, withThousandSeparator, format) {
    this.bigger = EvaluatorHelper.isBigger(displayWidth);
    this.withThousandSeparator = withThousandSeparator;
    this.format = format;
  }

  render = (value, { doNotRemoveDecimalPoint } = {}) => {
    // the case when user types . and the value is 2. but it is needed to display 2.
    const hasComma = doNotRemoveDecimalPoint && value.match(/\./);
    const valAbs = Math.abs(+value);
    const formatter = new ValueFormatter(this.withThousandSeparator, this.format);

    if (value.match(/NaN|Inf|Error/)) {
      return Renderer.msg.errorMessage;
    } else {
      if (valAbs >= 1e+16) {
        value = (+value).toExponential(13).toString();
      }
      if (!this.bigger && valAbs !== 0) {
        value = (+value).toPrecision(9);
      }
      let tmp = (value ? value.toString() : '').split('.');
      // there is a decimal point
      if (tmp[1]) {
        tmp[2] = tmp[1].split('e');
        // there is a 'e' notation
        if (tmp[2][1]) {
          const [, , tmp2] = tmp;
          const [tmp20] = tmp2;
          tmp[1] = tmp20;
        }
        // calculate the decimal part
        let precision;
        if (this.bigger) {
          precision = 16;
        } else if (tmp[2][1]) {
          precision = 7;
        } else {
          precision = 9;
        }
        tmp[1] = ((+(`1.${tmp[1]}`)).toPrecision(precision)).toString();
        if (tmp[1] >= 2) {
          tmp[0] = (+tmp[0] + 1).toString();
        }
        tmp[1] = tmp[1].substr(2).replace(/0+$/, '');
      }
      tmp = tmp[0] + ((tmp[1] || hasComma) ? `.${tmp[1]}` : '')
        // ??? -> .replace('.undefined', '')
        // .replace(inp ? '' : /\.$/, '') 
        + (tmp[2] && tmp[2][1] ? `e${tmp[2][1]}` : '');
      tmp = formatter.formatValue(tmp);
      return tmp;
    }
  }

}
