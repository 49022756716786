import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import RenderingHelper from './RenderingHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';

export default class CbaRectangle extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  buildLineDiv(style, config) {
    return (
      <div
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        title={CommonConfigHelper.buildTitle(config)}
        style={style}
        className="overflow-hidden"
      />
    )
  }

  render() {
    const { config, runtime, path: controllerPath, orientation } = this.props;

    const pathState = ComponentStateHelper.getState(this);
    const selectedState = StateAttributeAccess.extractSelected(pathState);

    const style = CommonConfigHelper.buildStyleByIndexPath(controllerPath, config, selectedState, orientation, runtime);

    const { lineWidth, pageEditId } = config;

    const containerStyle = Object.assign({}, style, {
      backgroundColor: "rgba(255, 255, 255, 0)",
      cursor: undefined
    })

    const lineWidthString = `${lineWidth}px`;

    const baseLineStyle = {
      position: "absolute",
      backgroundColor: style.backgroundColor,
      width: lineWidthString,
      height: lineWidthString,
      cursor: style.cursor
    }

    const northStyle = Object.assign({}, baseLineStyle, {
      top: 0,
      left: 0,
      width: "100%"
    });

    const eastStyle = Object.assign({}, baseLineStyle, {
      top: 0,
      right: 0,
      height: "100%"
    });

    const southStyle = Object.assign({}, baseLineStyle, {
      bottom: 0,
      left: 0,
      width: "100%"
    });

    const westStyle = Object.assign({}, baseLineStyle, {
      top: 0,
      left: 0,
      height: "100%"
    });

    return (
      <div style={containerStyle} data-cba-id={pageEditId}>
        {this.buildLineDiv(northStyle, config)}
        {this.buildLineDiv(southStyle, config)}
        {this.buildLineDiv(eastStyle, config)}
        {this.buildLineDiv(westStyle, config)}
      </div>
    )
  }

}


CbaRectangle.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        lineWidth: PropTypes.number.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired,
}
