/**
 * Helper methods to work with index paths.
 * 
 * See PathTranslationHelper for translation between user defined ID path and index path.
 */
export default class IndexPathHelper {

  /**
   * Build an index path root, i.e. test and item and task.
   */
  static buildPathRoot(testName, itemName, taskName) {
    return `/test=${testName}/item=${itemName}/task=${taskName}`;
  }

  /**
   * Build a page segment from a bare page name, i.e. without any navigation into a children list.
   * 
   * Use the appendIndexToPageSegment method to add navigations into children lists.
   */
  static buildPageSegment(pageName) {
    return `/page=${pageName}`;
  }


  /**
   * Get the page name from the given page segment.
   */
  static getPageNameFromPageSegment(pageSegment) {
    if (!pageSegment.startsWith("/page=")) {
      console.warn(`Cannot get page name from invalid page segment: ${pageSegment}`);
      return undefined;
    }
    const withNameInFront = pageSegment.substring(6);
    const endIndex = withNameInFront.indexOf('/');
    return endIndex === -1 ? withNameInFront : withNameInFront.substring(0, endIndex);
  }

  /**
   * Get the page name from the given path.
   * @param path - path of the component
   */
  static getPageNameFromPath(path) {
    const segmentIndex = path.indexOf("/page=");
    const hasValidPageSegment = segmentIndex !== -1;
    if (!hasValidPageSegment) {
      console.warn(`Cannot get page segment from invalid path: ${path}`);
      return undefined;
    }

    return IndexPathHelper.getPageNameFromPageSegment(path.slice(segmentIndex));
  }

  /**
   * Append the top level page segment to a path root.
   *
   * 
   * @param {*} pathRoot The path root (i.e. test/item/task) to be extended. 
   * @param {*} pageAreaType The type of page area (main, dialog or modal) to attach the page to.
   * @param {*} pageAreaName The name of the page area (standard or Xpage for main type, an arbitrary name for dialog/modal types) to attach the page to.
   * @param {*} pageSegment The page segment to attach to the page area.
   */
  static appendPageSegmentToPathRoot(pathRoot, pageAreaType, pageAreaName, pageSegment) {
    return `${pathRoot}/pageAreaType=${pageAreaType}/pageAreaName=${pageAreaName}${pageSegment}`;
  }

  /**
   * Append a string of page segments to the given path.
   * 
   * @param {*} path The path to be extended.
   * @param {*} pageSegments The string containing one or more page segments to be appended.
   */
  static appendPageSegmentsToPath(path, pageSegments) {
    return path + pageSegments;
  }

  /**
   * Drop the last page segment from a path.
   * 
   * The method returns 'undefined' if the path does not contain any page segment.
   */
  static dropPageSegmentFromPath(path) {
    const lastPageSlashIndex = path.lastIndexOf('/page=');
    return lastPageSlashIndex === -1 ? undefined : path.substring(0, lastPageSlashIndex);
  }

  /**
   * Drop all page segments from a path.
   * 
   * The method returns the path if it does not contain any page segment.
   */
  static dropAllPageSegments(path) {
    const firstPageSlashIndex = path.indexOf('/page=');
    return firstPageSlashIndex === -1 ? path : path.substring(0, firstPageSlashIndex);
  }


  /**
   * Get the last page segment from a path.
   * 
   * The method returns undefined if the path does not contain any page segment.
   */
  static getLastPageSegmentFromPath(path) {
    const lastPageSlashIndex = path.lastIndexOf('/page=');
    return lastPageSlashIndex === -1 ? undefined : path.substring(lastPageSlashIndex);
  }

  /**
   * Get an array of all page segments in a path.
   * 
   * The top level page segment is the first element in the array. 
   */
  static getPageSegmentArray(path) {
    const result = [];
    let remainingPath = path;
    let pageSegment = IndexPathHelper.getLastPageSegmentFromPath(path);
    while (pageSegment !== undefined) {
      result.splice(0, 0, pageSegment);
      remainingPath = IndexPathHelper.dropPageSegmentFromPath(remainingPath);
      pageSegment = IndexPathHelper.getLastPageSegmentFromPath(remainingPath);
    }
    return result;
  }

  /**
   * Get a reversed array of all page segments in a path.
   * 
   * The top level page segment is the last element in the array. 
   */
  static getReversedPageSegmentArray(path) {
    const result = [];
    let remainingPath = path;
    let pageSegment = IndexPathHelper.getLastPageSegmentFromPath(path);
    while (pageSegment !== undefined) {
      result.push(pageSegment);
      remainingPath = IndexPathHelper.dropPageSegmentFromPath(remainingPath);
      pageSegment = IndexPathHelper.getLastPageSegmentFromPath(remainingPath);
    }
    return result;
  }

  /**
   * Append the index of a child in a children array to a page segment. 
   */
  static appendIndexToPageSegment(pageSegment, index) {
    return `${pageSegment}/index=${index}`;
  }

  /**
   * Drop the last index of a child from a page segment. 
   */
  static dropIndexFromPageSegment(pageSegment) {
    const lastSlashIndex = pageSegment.lastIndexOf('/index=');
    return lastSlashIndex === -1 ? undefined : pageSegment.substring(0, lastSlashIndex);
  }

  /**
   * Get the index of the last child from a page segment. 
   * 
   * The method returns the bare index as a number. 
   */
  static getLastIndexFromPageSegment(pageSegment) {
    const lastSlashIndex = pageSegment.lastIndexOf('/index=');
    return lastSlashIndex === -1 ? undefined : pageSegment.substring(lastSlashIndex + 7);
  }


  /**
   * Trim the leading test specification from a path.
   * 
   * The method returns undefined if the path does not contain an item specification.
   * Otherwise it returns a path fragment starting with the item specification.
   */
  static trimTestFromPath(path) {
    const slashIndex = path.indexOf('/item=');
    return slashIndex === -1 ? undefined : path.substring(slashIndex);
  }

  /**
   * Trim the path root (i.e. the test/item/task) and page area specification (i.e. pageAreaType/pageAreaName) from a path.
   * 
   * The method returns undefined if the path does not contain a page segment.
   * Otherwise it returns the page segments string from the path.
   */
  static trimRootAndPageAreaFromPath(path) {
    const slashIndex = path.indexOf('/page=');
    return slashIndex === -1 ? undefined : path.substring(slashIndex);
  }

  /**
   * Extract the path root (i.e. the test/item/task) from a path (without page type specification).
   * 
   * The method returns the full path if the path does not contain a pageType specification.
   * Otherwise it returns the path root up to (but not including) the pageType specification.
   */
  static getRootFromPath(path) {
    const endIndex = path.indexOf('/pageAreaType=');
    return endIndex === -1 ? path : path.substring(0, endIndex);
  }

  /**
   * Extract the tree path root (i.e. the test/item/task) from a path (without page type specification).
   * 
   * The method returns the path of the tree node given
   * Otherwise it returns null
   */
  static ExtractTreeChildPathFromTreePath(treePath, childPath) {
    const endIndex = childPath.indexOf(treePath);
    return endIndex === -1 ? null : childPath.substr(treePath.length);
  }

  /**
   * Extract the page area type from a path.
   * 
   * The method returns undefined if the path does not contain a page area type specification.
   */
  static getPageAreaTypeFromPath(path) {
    const typeKeyIndex = path.indexOf('/pageAreaType=');
    const endIndex = path.indexOf('/pageAreaName=');
    return (typeKeyIndex === -1 || endIndex === -1) ? undefined : path.substring(typeKeyIndex + 14, endIndex);
  }

  /**
   * Extract the page area name from a path.
   * 
   * The method returns undefined if the path does not contain a page area name specification.
   */
  static getPageAreaNameFromPath(path) {
    const typeKeyIndex = path.indexOf('/pageAreaName=');
    if (typeKeyIndex === -1) return undefined;

    const endIndex = path.indexOf('/page=');
    if (endIndex === -1) return path.substring(typeKeyIndex + 14);

    return path.substring(typeKeyIndex + 14, endIndex);
  }


  /**
   * Find the index path of the CbaPageArea that is the first CbaPageArea ancestor of the given component.
   * 
   * @param {String} path The index path of the child component of the CbaPageArea.
   */
  static findPageAreaAncestorPath(path) {
    const trimmedPath = IndexPathHelper.dropPageSegmentFromPath(path);
    if (IndexPathHelper.getLastPageSegmentFromPath(trimmedPath) === undefined) {
      return undefined;
    }
    return trimmedPath;
  }

  /**
   * Extract the page path from a component path.
   * @param {String} path The index path of the child component of the page.
   */
  static getPagePath(path) {
    const rootPath = IndexPathHelper.dropAllPageSegments(path);
    const pageName = IndexPathHelper.getPageNameFromPath(path);
    return IndexPathHelper.appendPageSegmentsToPath(rootPath, IndexPathHelper.buildPageSegment(pageName));
  }

  /**
   * Replaces al special characters from an index path so it can be used as a valid css id or selector
   * 
   * @param {String} path The index path
   */

  static getValidCssIdFromPath(path) {
    return path.replace(/[&/\\#, +()$~%.'":*?<>{}=]/g, '');
  }


}
