import TreeUtils from "./TreeUtils";
import TreeTraceHelper from "./TreeTraceHelper";

export default class TreeContextMenuHelper {

  static openContextMenu(event, rowData, columnKey) {
    event.persist();
    const { runtime } = rowData;
    const entries = TreeContextMenuHelper.buildContextMenuEntries(rowData, columnKey);

    if (entries.length) {
      runtime.contextMenu.openMenuItemTree({
        event,
        entries
      })
    }
  }

  static buildContextMenuEntries = (rowData, columnKey) => {
    const { nodeType, nodeTypes } = rowData;
    const nodeTypeInstance = TreeUtils.getNodeType(nodeTypes, nodeType);
    const { contextMenuCommands } = nodeTypeInstance;

    return contextMenuCommands.map((entry) => {
      let command = Object.assign({}, entry);
      switch (entry.type) {
        case "action": command = TreeContextMenuHelper.buildContextMenuActionEntry(command, rowData, columnKey); break;
        case "submenu": command = Object.assign(command, {
          submenu: command.submenu.map(e => TreeContextMenuHelper.buildContextMenuActionEntry(e, rowData, columnKey)),
          disabled: !command.submenu.length
        }); break;
        default: console.error("Invalid context menu config in tree", rowData);
      }

      return command;
    });
  }

  static buildContextMenuActionEntry(entry, rowData, columnKey) {
    const actionEntry = Object.assign({}, entry);
    const { action } = entry;

    switch (action) {
      case "CUT":
        actionEntry.action = () => TreeContextMenuHelper.buildContextMenuAction(action, rowData, TreeUtils.doTreeCut);
        actionEntry.disabled = false;
        break;
      case "COPY":
        actionEntry.action = () => TreeContextMenuHelper.buildContextMenuAction(action, rowData, TreeUtils.doTreeCopy);
        actionEntry.disabled = false;
        break;
      case "PASTE":
        actionEntry.action = () => TreeContextMenuHelper.buildContextMenuAction(action, rowData, TreeUtils.doTreePaste);
        actionEntry.disabled = TreeContextMenuHelper.handlePasteDisabled(rowData);
        break;
      case "DELETE":
        actionEntry.action = () => TreeContextMenuHelper.buildContextMenuAction(action, rowData, TreeUtils.doTreeDelete);
        actionEntry.disabled = false;
        break;
      case "RENAME": // rename operation needs specific data for tracing, thus we'll trace inside the rename handler
        actionEntry.action = () => TreeUtils.startRenameOperation(rowData, columnKey);
        actionEntry.disabled = false;
        break;
      case "NEW":
        actionEntry.action = () => TreeContextMenuHelper.buildContextMenuAction(action, rowData, TreeUtils.doTreeNew, actionEntry.label);
        actionEntry.disabled = false;
        break;
      default: console.error("Invalid context menu config in tree", rowData);
    }

    return actionEntry;
  }

  static buildContextMenuAction = (actionType, rowData, actionHandler, label) => {
    const { path: nodePath, treePath, runtime, isTreeView, treeViewPath } = rowData;

    TreeTraceHelper.traceNodeAction(actionType.toLowerCase(), treePath, rowData, runtime);
    actionHandler(treePath, nodePath, runtime, label, isTreeView, treeViewPath);
  }

  static handlePasteDisabled = (rowData) => {
    const { treePath, runtime, path: nodePath, nodeType: nodeTypeName } = rowData;

    const { treeState, nodeTypes } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const { copiedNode, currentlyCutNodePath } = treeState;
    const nodeType = TreeUtils.getNodeType(nodeTypes, nodeTypeName);

    // mark as disabled if there is no node to paste
    if (!treeState.copiedNode) {
      return true;
    }

    // mark as disabled if the copied node does not have the accepted node type
    if (!nodeType.childTypes.includes(copiedNode.nodeType)) {
      return true;
    }

    // mark as disabled if cut node is a parent of the node that opened context menu
    if (copiedNode && currentlyCutNodePath) {
      let cutNodes = []
      if (copiedNode.nodes.length) {
        cutNodes = TreeUtils.flattenNodes(copiedNode.nodes);
      }
      cutNodes.push(copiedNode);

      if (cutNodes.find(n => n.path === nodePath)) {
        return true;
      }
    }

    // mark enabled if it gets here
    return false
  }

}
