import StateManagerHelper from './StateManagerHelper';

export default class IncidentsAccumulator {

  constructor() {
    this.taskValuesList = {};
    this.currentTaskPath = undefined;
    this.startTimeOfCurrentTask = undefined;
    this.startOfCurrentPause = undefined;
    this.timeSpentPausedInCurrentTask = undefined;
    this.firstReactionSeen = undefined;
    this.lastContinuingInteractionKey = undefined;
  }

  // update values ------------------------------------------------------------------

  enterTask = (taskPath, atTime) => {
    this.leaveTask(atTime);

    this.currentTaskPath = taskPath;
    this.startTimeOfCurrentTask = atTime;
    this.startTimeOfCurrentPause = undefined;
    this.timeSpentPausedInCurrentTask = 0;
    this.firstReactionSeen = false;
    this.lastContinuingInteractionKey = undefined;
    const values = this.getOrInitializeValuesForTask(taskPath);
    values.nbUserInteractions = 0;
    values.firstReactionTime = undefined;
    values.taskExecutionTime = 0;
  }

  /**
   * Count a single user interaction. 
   * 
   * If a continuingInteractionKey is given 
   * the method will ignore all but the first call of a consecutive sequence of calls 
   * that use the same continuingInteractionKey.  
   * 
   * @param {*} atTime The timestamp of the interaction, used for first reaction timing.
   * @param {{type: String, value: String}} continuingInteractionKey The key used to identify a user interaction that might trigger 
   *  several consecutive calls to this method but should be counted once only. 
   */
  userInteraction = (atTime, continuingInteractionKey) => {
    if (this.lastContinuingInteractionKey
      && continuingInteractionKey
      && this.lastContinuingInteractionKey.type === continuingInteractionKey.type
      && this.lastContinuingInteractionKey.value === continuingInteractionKey.value) {
      return;
    }
    this.lastContinuingInteractionKey = continuingInteractionKey;
    if (this.currentTaskPath !== undefined) {
      const values = this.getOrInitializeValuesForTask(this.currentTaskPath);
      values.nbUserInteractions += 1;
      values.nbUserInteractionsTotal += 1;

      if (!this.firstReactionSeen) {
        values.firstReactionTime = IncidentsAccumulator.getNonPausedTimeSpentInCurrentTask(atTime, this.startTimeOfCurrentTask, this.timeSpentPausedInCurrentTask, this.startTimeOfCurrentPause);
        values.firstReactionTimeTotal += values.firstReactionTime;
        this.firstReactionSeen = true;
      }
    }
  }

  leaveTask = (atTime) => {
    if (this.currentTaskPath !== undefined) {
      const values = this.getOrInitializeValuesForTask(this.currentTaskPath);
      values.taskExecutionTime = IncidentsAccumulator.getNonPausedTimeSpentInCurrentTask(atTime, this.startTimeOfCurrentTask, this.timeSpentPausedInCurrentTask, this.startTimeOfCurrentPause);
      values.taskExecutionTimeTotal += values.taskExecutionTime;
    }
    this.currentTaskPath = undefined;
    this.startTimeOfCurrentTask = undefined;
    this.startTimeOfCurrentPause = undefined;
    this.timeSpentPausedInCurrentTask = undefined;
    this.firstReactionSeen = undefined;
    this.lastContinuingInteractionKey = undefined;
  }

  pauseTask = (atTime) => {
    if (this.startTimeOfCurrentPause === undefined) {
      this.startTimeOfCurrentPause = atTime;
    }
  }

  resumeTask = (atTime) => {
    if (this.startTimeOfCurrentPause !== undefined) {
      // tolerate resume calls without any active task:
      if (this.timeSpentPausedInCurrentTask !== undefined) {
        this.timeSpentPausedInCurrentTask += atTime - this.startTimeOfCurrentPause;
      }
      this.startTimeOfCurrentPause = undefined;
    }
  }

  static getTimeSpentInPausedState(atTime, timeSpentInEarlierPauses, startTimeOfCurrentPause) {
    return timeSpentInEarlierPauses + (startTimeOfCurrentPause === undefined ? 0 : atTime - startTimeOfCurrentPause);
  }

  static getNonPausedTimeSpentInCurrentTask(atTime, startTimeOfCurrentTask, timeSpentPausedInCurrentTask, startTimeOfCurrentPause) {
    return atTime - startTimeOfCurrentTask - IncidentsAccumulator.getTimeSpentInPausedState(atTime, timeSpentPausedInCurrentTask, startTimeOfCurrentPause);
  }

  // get values --------------------------------------------------------------------
  nbUserInteractions = taskPath => this.getValuesForTask(taskPath).nbUserInteractions

  nbUserInteractionsTotal = taskPath => this.getValuesForTask(taskPath).nbUserInteractionsTotal

  firstReactionTime = taskPath => this.getValuesForTask(taskPath).firstReactionTime

  firstReactionTimeTotal = taskPath => this.getValuesForTask(taskPath).firstReactionTimeTotal

  taskExecutionTime = (taskPath, atTime) => {
    if (taskPath === this.currentTaskPath) {
      return IncidentsAccumulator.getNonPausedTimeSpentInCurrentTask(atTime, this.startTimeOfCurrentTask, this.timeSpentPausedInCurrentTask, this.startTimeOfCurrentPause);
    } else {
      return this.getValuesForTask(taskPath).taskExecutionTime;
    }
  }

  taskExecutionTimeTotal = taskPath => this.getValuesForTask(taskPath).taskExecutionTimeTotal

  /**
   * Get a structure containing all values for the given task (for use in trace log e.g.).
   */
  getAllValuesForTask = (taskPath, atTime) => {
    // The taskExecutionTime in the values array is not updated until we leave a task.
    // -> Get the the current value for our result:
    const result = StateManagerHelper.deepCopy(this.getValuesForTask(taskPath));
    result.taskExecutionTime = this.taskExecutionTime(taskPath, atTime);
    return result;
  }

  /**
   * Get the historical state for all existing tasks.
   * 
   * Use the result of this method as parameter to preloadTasksState to preload another instance to our current state. 
   */
  getAllTasksState = () => StateManagerHelper.deepCopy(this.taskValuesList);

  /**
   * Drop history for all tasks.
   */
  clearTasksState = () => { this.taskValuesList = {}; };

  /**
   * Preload the historical tasks state returned by a call to getAllTasksState.
   */
  preloadTasksState = (allTasksState) => {
    this.taskValuesList = StateManagerHelper.deepCopy(allTasksState);
  }


  // private stuff --------------------------------------------------------------------
  getValuesForTask = (taskPath) => {
    if (this.taskValuesList[taskPath] === undefined) {
      return IncidentsAccumulator.buildInitialValues();
    }
    return this.taskValuesList[taskPath];
  }

  getOrInitializeValuesForTask = (taskPath) => {
    if (this.taskValuesList[taskPath] === undefined) {
      this.taskValuesList[taskPath] = IncidentsAccumulator.buildInitialValues();
    }
    return this.taskValuesList[taskPath];
  }

  static buildInitialValues() {
    return {
      nbUserInteractions: 0,
      nbUserInteractionsTotal: 0,
      firstReactionTime: undefined,
      firstReactionTimeTotal: 0,
      taskExecutionTime: 0,
      taskExecutionTimeTotal: 0
    }
  }

}
