import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-rangeslider'

// To include the default styles
// import 'react-rangeslider/lib/index.css'
import './slider.css'
import '../CbaRichTextField/css/repeatable.css';
import RenderingHelper from '../RenderingHelper';
import PropTypesHelper from '../PropTypesHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import CommonActionsHelper from '../CommonActionsHelper';

class CbaScaleValueInput extends Component {

  constructor(props) {
    super(props)
    this.scaleValueInput = React.createRef();
  }

  componentDidMount() {
    const { path, runtime } = this.props;
    RenderingHelper.onMount(this);
    this.subscription = runtime.eventEmitter.addListener(`${path}-incrementScale`, this.onIncrementScale.bind(this));

    if (this.scaleValueInput.current) {
      // set tabindex on 3rd party component slider.
      // later edit, added the -1 to disable completly. This blocks tabbing across elements on the page if left to default
      this.scaleValueInput.current.querySelector(".rangeslider__handle").tabIndex = -1 // should be config.tabIndex but does not work due to 3rd part implementation
    }

  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
    this.subscription.remove();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler = (event) => {
    const pathState = ComponentStateHelper.getState(this);
    if (!pathState.disabled) {
      CommonActionsHelper.doStandardOnClick(event, undefined, this);
    } else {
      CommonActionsHelper.stopEventPropagation(event);
    }
  }

  onChangeHandler = (value) => {
    const { config, runtime } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    value = this.validateValue(value);
    if (pathState.disabled || Number.isNaN(value)) {
      // we should not update the statemachine variable with NaN value 
      return;
    }

    const { taskManager, variableManager } = runtime;
    const currentTaskId = taskManager.getCurrentStatePathRoot();

    const variableName = config.text.dynamic.variable;

    variableManager.setVariable(currentTaskId, variableName, value, "integer", runtime);
    RenderingHelper.triggerRendering(this);
  }

  onKeyDownHandler = (e) => {
    const { config, runtime } = this.props;
    const { pageIncrement, minValue, maxValue } = config;

    const variableName = config.text.dynamic.variable;
    const { taskManager, variableManager } = runtime;
    const currentTaskId = taskManager.getCurrentStatePathRoot();

    const value = variableManager.getVariable(currentTaskId, variableName, false);

    if (e.keyCode === 33) {
      let newValue = value + pageIncrement;
      newValue = newValue > maxValue ? maxValue : newValue;
      this.onChangeHandler(newValue);
      e.preventDefault();
    }
    if (e.keyCode === 34) {
      let newValue = value - pageIncrement;
      newValue = newValue < minValue ? minValue : newValue;
      this.onChangeHandler(newValue);
      e.preventDefault();
    }
  }

  onIncrementScale = (incrementValue) => {
    const { config, runtime } = this.props;
    const { minValue, maxValue } = config;

    const variableName = config.text.dynamic.variable;
    const { taskManager, variableManager } = runtime;
    const currentTaskId = taskManager.getCurrentStatePathRoot();

    const value = variableManager.getVariable(currentTaskId, variableName, false);

    const newValue = value + incrementValue;
    let incrementedValue = newValue > maxValue ? maxValue : newValue;
    incrementedValue = incrementedValue < minValue ? minValue : incrementedValue;
    this.onChangeHandler(incrementedValue);
  }

  validateValue = (value, minValue, maxValue) => {
    let numberValue = parseInt(value, 10);
    numberValue = numberValue < minValue ? minValue : numberValue;
    numberValue = numberValue > maxValue ? maxValue : numberValue;
    return numberValue;
  }

  render() {
    const { config, path, runtime, orientation } = this.props;
    const { minValue, maxValue, stepIncrement, thumbSize, isHorizontal } = config;
    const pathState = ComponentStateHelper.getState(this);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);
    let value = CommonConfigHelper.buildDisplayText(config, pathState, runtime);
    value = this.validateValue(value, minValue, maxValue);
    const horizontal = `rangeslider-horizontal sliderHandleWidth${thumbSize}`;
    const vertical = `rangeslider-vertical sliderHandleHeight${thumbSize}`;
    const orientationClass = isHorizontal ? horizontal : vertical;

    return (
      <div
        style={style}
        data-cba-id={config.pageEditId}
        title={pathState.disabled ? undefined : CommonConfigHelper.buildTitle(config)}
        onKeyDown={this.onKeyDownHandler}
        onClickCapture={this.onClickHandler}
        ref={this.scaleValueInput}
      >
        <Slider
          className={`${orientationClass} ${pathState.disabled ? "disabled" : ""}`}
          min={minValue}
          max={maxValue}
          tooltip={false}
          step={stepIncrement}
          value={value}
          orientation={isHorizontal ? "horizontal" : "vertical"}
          onChange={this.onChangeHandler}
        />
      </div>
    );
  }

}

CbaScaleValueInput.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape().isRequired,
  orientation: PropTypes.string.isRequired,
};

export default CbaScaleValueInput;
