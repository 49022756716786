import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './TopButtonToolbar.css';

export default class TopButtonToolbar extends Component {

  constructor(props) {
    super(props);
    this.currentSlide = 0;
  }

  static getButtonPadding() {
    return 5;
  }

  static getCarouselMarginBottom() {
    return 5;
  }

  /*
  * external function that can be used to compute carousel current height
  */
  static getCarouselHeight = (buttonHeight) => {
    const padding = TopButtonToolbar.getButtonPadding() * 2;
    const marginBottom = TopButtonToolbar.getCarouselMarginBottom();

    return buttonHeight + padding + marginBottom;
  }

  /*
  * function used to build array of slides that server the carousel
  */
  buildSlides = buttonArray => (
    buttonArray.map((item, index) => (
      <Slide key={item.key} index={index}>
        <span className="button-container">
          {item}
        </span>
      </Slide>
    ))
  )


  /*
  * build the config needed for the carousel
  */
  buildCarouselConfig = (buttonHeight, widthRatio) => {
    const { itemWidth } = this.props;

    const navButtonsWidth = 25;
    const buttonPadding = TopButtonToolbar.getButtonPadding();
    const buttonWidth = buttonHeight * widthRatio;

    const carouselWidth = itemWidth - navButtonsWidth * 2 - buttonPadding * 2;
    const carouselHeight = buttonHeight + buttonPadding * 2;

    const slideWidth = buttonWidth + buttonPadding * 2;
    const slideHeight = carouselHeight;

    const leftArrow = String.fromCharCode("0x2039");
    const rightArrow = String.fromCharCode("0x203A");

    const carouselWrapStyle = {
      width: itemWidth,
      marginBottom: TopButtonToolbar.getCarouselMarginBottom()
    }

    const carouselStyle = {
      width: carouselWidth,
      height: carouselHeight,
      margin: "0 auto",
    }

    const buttonStyle = {
      height: carouselHeight
    }

    return {
      button: {
        height: buttonHeight,
        width: buttonWidth,
        padding: buttonPadding
      },

      slides: {
        width: slideWidth,
        height: slideHeight,
        visibleSlides: Math.floor(carouselWidth / slideWidth),
      },

      carouselWidth, // padding for next and prev
      carouselHeight, // button paddings

      arrows: {
        left: leftArrow,
        right: rightArrow
      },

      styles: {
        carouselStyle,
        buttonStyle,
        carouselWrapStyle,
      }
    }
  }

  /*
  * computes the offset needed for the carousel to set the active button in the middle
  */
  computeCurrentSlideCenter = (visibleSlides, buttons) => {
    let offset = 0
    // only valid for more buttons than slides.
    if (visibleSlides >= buttons.length) return offset;

    const middlePoint = Math.ceil(visibleSlides / 2);
    let activeSlide;
    buttons.forEach((button, index) => {
      if (button.props.className === "active") {
        activeSlide = index + 1;
      }
    });

    offset = activeSlide - middlePoint;

    offset = offset < 0 ? 0 : offset;
    offset = offset > buttons.length - visibleSlides ? buttons.length - visibleSlides : offset;

    return offset;

  }

  computeCurrentSlideNotCenter = () => (
    this.carousel ? this.carousel.carouselStore.getStoreState().currentSlide : 0
  )

  buildCurrentSlide = (carouselConfig, isCenter) => {
    const { buttons } = this.props;
    return (isCenter
      ? this.computeCurrentSlideCenter(carouselConfig.slides.visibleSlides, buttons)
      : this.computeCurrentSlideNotCenter()
    );
  }

  render() {
    const { buttonHeight, buttonRatio, activateCenterToolbarOnChange, className, buttons } = this.props;
    const carouselConfig = this.buildCarouselConfig(buttonHeight, buttonRatio);
    this.currentSlide = this.buildCurrentSlide(carouselConfig, activateCenterToolbarOnChange);

    return (
      <div className={`carousel-wrap ${className}`} style={carouselConfig.styles.carouselWrapStyle}>
        <CarouselProvider
          ref={(c) => { this.carousel = c; }}
          style={carouselConfig.styles.carouselStyle}
          className={className}
          naturalSlideWidth={carouselConfig.slides.width}
          naturalSlideHeight={carouselConfig.slides.height}
          totalSlides={buttons.length}
          visibleSlides={carouselConfig.slides.visibleSlides}
          currentSlide={this.currentSlide}
        >
          <Slider style={carouselConfig.styles.carouselStyle}>
            {this.buildSlides(buttons)}
          </Slider>

          <ButtonBack className="nav-buttons back" style={carouselConfig.styles.buttonStyle}>{carouselConfig.arrows.left}</ButtonBack>
          <ButtonNext className="nav-buttons next" style={carouselConfig.styles.buttonStyle}>{carouselConfig.arrows.right}</ButtonNext>
        </CarouselProvider>
      </div>
    );
  }

}

TopButtonToolbar.propTypes = {
  className: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonHeight: PropTypes.number.isRequired,
  buttonRatio: PropTypes.number,
  activateCenterToolbarOnChange: PropTypes.bool,
  itemWidth: PropTypes.number
}

TopButtonToolbar.defaultProps = {
  itemWidth: 800,
  buttonRatio: 3,
  activateCenterToolbarOnChange: false
}
