import React from 'react';
import PropTypes from 'prop-types';
import './CbaList.css'


function ListItem(props) {
  const { config, selectedIds, index, itemHeight, onClickHandler, parentTabIndex } = props;
  const selectedBackgroundColor = "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(88, 130, 181)), to(rgb(65, 102, 147))), rgb(0, 88, 159)";

  // accesibility event for tab navigation
  function onKeyDown(event) {
    if (event.keyCode === 32) {
      // space key
      onClickHandler(event, index);
    }
  }

  return (
    <div
      className={selectedIds.includes(index) ? "listItemNoHover" : "listItem"}
      style={{
        height: itemHeight,
        background: selectedIds.includes(index) ? selectedBackgroundColor : config.background
      }}
      onClick={event => onClickHandler(event, index)}
      onKeyDown={event => onKeyDown(event)}
      tabIndex={parentTabIndex}
    >
      <div className="listItemChild">
        {config.text}
      </div>
    </div>
  );
}

ListItem.propTypes = {
  config: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  itemHeight: PropTypes.number.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  parentTabIndex: PropTypes.number.isRequired
};

export default ListItem;
