import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import SelectGroupHelper from './SelectGroupHelper';
import RenderingHelper from './RenderingHelper';

const DEFAULT_LABEL_POSITION = 10;

/**
 * A display component that displays a labeled check box.
 */
export default class CbaCheckbox extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.updateStateAndTriggerRendering = this.updateStateAndTriggerRendering.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState),
      event, runtime);

    // Flip state: 'true' <-> 'false'
    this.updateStateAndTriggerRendering(pathState);

    SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);

    // send statemachine event depending on old selected state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  updateStateAndTriggerRendering(pathState) {
    const { runtime, path } = this.props;
    const oldSelected = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    StateAttributeAccess.setVisited(pathState, true);
    SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);

  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, path, runtime);
    const text = RenderingHelper.extractMultilineText(CommonConfigHelper.buildDisplayText(config, pathState, runtime));
    const { label, textWrap } = config;
    const { position, distance } = label;

    const wrapperStyle = CommonConfigHelper.buildStyleByIndexPath(path, config, selectedState, orientation, runtime);

    const isPositionLeft = position === "left";

    const textWrapStyle = {
      whiteSpace: textWrap ? "initial" : "nowrap",
      marginLeft: isPositionLeft ? 0 : 20,
      marginRight: isPositionLeft ? 20 : 0,
      justifyContent: isPositionLeft ? "flex-end" : "flex-start"
    }

    const inputStyle = {
      left: isPositionLeft ? "auto" : 2.5,
      right: isPositionLeft ? 2.5 : "auto",
      cursor: "inherit"
    }

    const labelPositionDelta = distance - DEFAULT_LABEL_POSITION;
    const textStyle = {
      marginLeft: isPositionLeft ? 0 : labelPositionDelta,
      marginRight: isPositionLeft ? labelPositionDelta : 0,
      display: text ? "inline" : "none"
    }

    const labelStyle = {
      cursor: "inherit",
    }

    const forKey = shortid.generate();

    // TODO: This class resembles CbaRadioButton pretty much 
    //       -> Merge into one class once we know that we keep implementation of both as <input> plus <div>.
    return (
      <div
        style={wrapperStyle}
        data-cba-id={config.pageEditId}
        title={CommonConfigHelper.buildTitle(config)}
        className="inputWrapper overflow-hidden"
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
      >
        <div className="checkBoxWrap">
          <label
            style={labelStyle}
            htmlFor={forKey}
            className="label"
            onClick={(event) => { event.stopPropagation(); }}
          >
            <span style={textWrapStyle}>
              <span style={textStyle} className="inputTextWrap">{text}</span>
            </span>
          </label>
          <input
            style={inputStyle}
            id={forKey}
            type="checkbox"
            checked={selectedState}
            disabled={StateAttributeAccess.extractDisabled(pathState)}
            // using a blank function to remove the warning src: https://stackoverflow.com/questions/36715901/reactjs-error-warning
            onChange={() => { }}
            tabIndex={config.tabIndex}
          />
        </div>
      </div>
    );
  }

}


CbaCheckbox.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        textWrap: PropTypes.bool.isRequired,
        label: PropTypesHelper.getInputLabelConfig()
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
