import CommonActionsHelper from "./CommonActionsHelper";
import SpecialClickActionsHelper from "./SpecialClickActionsHelper";
import PathTranslationHelper from '../state/PathTranslationHelper';
import CbaPageArea from "./CbaPageArea";
import IndexPathHelper from '../state/IndexPathHelper';
import UserDefPathHelper from "../state/UserDefPathHelper";


/**
 * Helper methods to transform context menu configuration structures.
 */
export default class MenuBuildHelper {

  // ----- public API ----------------------------------------------------------------------

  /**
   * Transform the contextMenu configuration object from a display component configuration 
   * to a context menu configuration suitable for the MenuItemTree.
   * 
   * @param {*} displayComponentContextMenuConfig The contextMenu configuration object of a display component.
   * @param {*} event The click event that triggered the context menu.
   * @param {String} indexPath The index path of the calling component.
   * @param {String} defaultLinkReceiver The index path of the default link receiver (see CommaonActionsHelper.doPageSwitch()).
   * @param {*} runtime The common runtime context structure.
   */
  static buildMenuTreeItemConfiguration(displayComponentContextMenuConfig, event, indexPath, defaultLinkReceiver, runtime) {
    return {
      entries: MenuBuildHelper.transformEntries(displayComponentContextMenuConfig.entries, indexPath, defaultLinkReceiver, runtime),
      event
    }
  }


  // ------ private stuff ------------------------------------------------------------------

  static transformEntries(displayComponentEntries, indexPath, defaultLinkReceiver, runtime) {
    const result = [];
    displayComponentEntries.forEach((entry, index) => {
      result.push(MenuBuildHelper.transformEntry(entry, indexPath, defaultLinkReceiver, runtime));
    });
    return result;
  }

  static transformEntry(displayComponentEntry, indexPath, defaultLinkReceiver, runtime) {
    switch (displayComponentEntry.type) {
      case 'separator':
        return MenuBuildHelper.transformSeparatorEntry(displayComponentEntry);
      case 'submenu':
        return MenuBuildHelper.transformSubmenuEntry(displayComponentEntry, indexPath, defaultLinkReceiver, runtime);
      case 'action':
        return MenuBuildHelper.transformActionEntry(displayComponentEntry, indexPath, runtime);
      case 'link':
        return MenuBuildHelper.transformLinkEntry(displayComponentEntry, indexPath, defaultLinkReceiver, runtime);
      case 'provided':
        return MenuBuildHelper.transformProvidedEntry(displayComponentEntry, indexPath, runtime);
      default:
        console.error(`Invalid context menu configuration entry type: ${displayComponentEntry.type}`, displayComponentEntry);
        return {
          type: 'separator'
        };
    }
  }

  static transformSeparatorEntry(orignal) {
    return {
      type: 'separator'
    };
  }

  static transformSubmenuEntry(original, indexPath, defaultLinkReceiver, runtime) {
    return {
      type: 'submenu',
      label: original.label,
      icon: original.icon,
      disabled: false,
      submenu: MenuBuildHelper.transformEntries(original.submenu, indexPath, defaultLinkReceiver, runtime)
    }
  }

  static transformActionEntry(originalEntry, indexPath, runtime) {
    return {
      type: 'action',
      label: originalEntry.label,
      mouseOver: originalEntry.mouseOver,
      icon: originalEntry.icon,
      event: originalEntry.event,
      action: MenuBuildHelper.buildActionMethod(originalEntry.action, originalEntry.actionParam, indexPath, runtime),
      disabled: MenuBuildHelper.isActionDisabled(originalEntry.action, originalEntry.actionParam, indexPath, runtime, originalEntry.event)
    }
  }

  static buildActionMethod(actionType, actionParam, indexPath, runtime) {
    switch (actionType) {
      case 'cut':
        return () => { SpecialClickActionsHelper.cut('contextMenu', indexPath, runtime); };
      case 'copy':
        return () => { SpecialClickActionsHelper.copy('contextMenu', indexPath, runtime); };
      case 'paste':
        return () => { SpecialClickActionsHelper.paste('contextMenu', indexPath, runtime); };
      case 'next_task':
        return () => { SpecialClickActionsHelper.nextTask(runtime); };
      case 'back_task':
        return () => { SpecialClickActionsHelper.backTask(runtime); };
      case 'cancel_task':
        return () => { SpecialClickActionsHelper.cancelTask(runtime); };
      case 'startFullscreen':
        return () => { SpecialClickActionsHelper.startFullscreen(runtime); };
      case 'stopFullscreen':
        return () => { SpecialClickActionsHelper.stopFullscreen(runtime); };
      case 'close':
        return () => { SpecialClickActionsHelper.close(indexPath, runtime); };
      case 'back':
        return () => { SpecialClickActionsHelper.back(indexPath, actionParam, runtime); };
      case 'forward':
        return () => { SpecialClickActionsHelper.forward(indexPath, actionParam, runtime); };
      case 'home':
        return () => { SpecialClickActionsHelper.home(indexPath, actionParam, runtime); };
      case 'addBookmark':
        return () => { SpecialClickActionsHelper.addBookmark('contextMenu', indexPath, actionParam, runtime); };
      case 'manageBookmark':
        return () => { SpecialClickActionsHelper.manageBookmark('contextMenu', indexPath, actionParam, runtime); };
      case 'none':
        return () => { console.log(`Menu button without action activated: ${indexPath}`) };
      default:
        console.error(`Unknown action type: ${actionType}`);
        return () => { console.error(`Cannot process unknown action type: ${actionType}`) };
    }
  }

  static isActionDisabled(actionType, actionParam, indexPath, runtime, event) {
    switch (actionType) {
      case 'cut':
        return !SpecialClickActionsHelper.cutEnabled(runtime);
      case 'copy':
        return !SpecialClickActionsHelper.copyEnabled(runtime);
      case 'paste':
        return !SpecialClickActionsHelper.pasteEnabled(runtime);
      case 'next_task':
        return !SpecialClickActionsHelper.nextTaskEnabled(runtime);
      case 'back_task':
        return !SpecialClickActionsHelper.backTaskEnabled(runtime);
      case 'back':
        return !SpecialClickActionsHelper.backEnabled(indexPath, actionParam, runtime);
      case 'forward':
        return !SpecialClickActionsHelper.forwardEnabled(indexPath, actionParam, runtime);
      case 'none':
        return event === undefined;
      default:
        return false;
    }
  }

  static transformLinkEntry(originalEntry, indexPath, defaultLinkReceiver, runtime) {
    return {
      type: 'action',
      label: originalEntry.label,
      mouseOver: originalEntry.mouseOver,
      icon: originalEntry.icon,
      event: originalEntry.event,
      action: () => { CommonActionsHelper.doPageSwitch(originalEntry.link, runtime, defaultLinkReceiver, indexPath); },
      disabled: false
    }
  }

  static transformProvidedEntry(originalEntry, indexPath, runtime) {
    const pageAreaPath = originalEntry.providerParam === undefined
      ? IndexPathHelper.findPageAreaAncestorPath(indexPath)
      : IndexPathHelper.appendPageSegmentsToPath(IndexPathHelper.dropPageSegmentFromPath(indexPath), originalEntry.providerParam);
    const bookmarks = CbaPageArea.getBookmarks(pageAreaPath, runtime);
    const menuEntries = bookmarks.map((pageEntry) => {
      const pageLabel = pageEntry.pageUrl ? pageEntry.pageUrl : "";
      const pageAreaTab = pageEntry.pageTab === undefined ? undefined : {
        name: pageEntry.pageTab,
        image: pageEntry.pageImage
      }

      return {
        type: 'action',
        label: pageLabel,
        mouseOver: `Switch browser to ${pageLabel}`,
        icon: pageEntry.pageImage,
        disabled: false,
        action: () => {
          // Note: Delegating the trace operation makes sure that the trace timestamp is taken when executing the menu action: 
          MenuBuildHelper.trace(indexPath, pageAreaPath, pageEntry.pageName, pageEntry.pageUrl, pageEntry.pageTab, runtime);
          runtime.taskManager.switchEmbeddedPage(pageAreaPath, pageEntry.pageName, pageEntry.pageUrl, pageAreaTab);
        }
      }
    });

    return {
      type: 'dynamicItems',
      provider: () => menuEntries,
      providerParam: undefined
    }
  }

  /**
 * Create a trace log entry for a bookmark select action
 * 
 * @param {String} triggeringIndexPath The index path of the triggering display component instance.
 * @param {String} ownerIndexPath The index path of the display component owining the bookmarks.
 * @param {String} pageName The name pf the page of the bookmark.
 * @param {String} pageUrl The URL pf the page of the bookmark.
 * @param {String} tab The browser tab the bookmark operates on.
 * @param {*} runtime The common runtime context structure.
 */
  static trace(triggeringIndexPath, ownerIndexPath, pageName, pageUrl, tab, runtime) {
    const triggeringUserDefPath = PathTranslationHelper.getUserDefPathForIndexPath(triggeringIndexPath, runtime);
    const ownerUserDefPath = PathTranslationHelper.getUserDefPathForIndexPath(ownerIndexPath, runtime);

    runtime.traceLogBuffer.reportEvent(
      'Bookmark',
      new Date(),
      {
        triggerType: 'contextMenu',
        triggerIndexPath: triggeringIndexPath,
        triggerUserDefIdPath: triggeringUserDefPath,
        triggerUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(triggeringUserDefPath),
        operation: 'select',
        ownerIndexPath,
        ownerUserDefIdPath: ownerUserDefPath,
        ownerUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(ownerUserDefPath),
        pageName,
        pageUrl,
        tab
      }
    );
  }

}
