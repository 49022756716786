import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CbaPageArea from '../components/CbaPageArea';


export default class ManageBookmarksData extends Component {

  constructor(props) {
    super(props);
    this.toBeDeleted = [];
  }

  okButtonClicked = () => {
    const { content } = this.props;
    const { pageAreaIndexPath, requestingComponentPath, testPresenter, runtime } = content;
    console.info(`delete called for bookmarks ${this.toBeDeleted.join()}`)
    this.toBeDeleted.forEach((value) => { CbaPageArea.dropBookmark(pageAreaIndexPath, value, 'contextMenu', requestingComponentPath, runtime); });
    testPresenter.handleClose();
  }

  cancelButtonClicked = () => {
    const { content } = this.props;
    const { testPresenter } = content;
    testPresenter.handleClose();
  }

  setBookmarkAsToBeDeleted = (bookmark) => {
    this.toBeDeleted.push(bookmark);
  }

  render() {
    const { content } = this.props;
    const { pageAreaIndexPath, labelConfiguration, runtime } = content;
    const { deleteColumnHeader, bookmarkColumnHeader, okButton, cancelButton } = labelConfiguration;

    const bookmarks = CbaPageArea.getBookmarks(pageAreaIndexPath, runtime);
    const toBeDeletedAccess = this.toBeDeleted;

    const fontStyle = {
      fontSize: '12px'
    }

    return (
      <div className="cba-modal-content" style={fontStyle}>
        <div className="detailsWrapper">
          <table className="details">
            <thead>
              <tr>
                <th className="cbaColWidth15">{deleteColumnHeader}</th>
                <th className="cbaColWidth85">{bookmarkColumnHeader}</th>
              </tr>
            </thead>
            <tbody>
              {bookmarks.map(bookmark => (
                <tr key={bookmark.pageName}>
                  <td className="cbaColWidth15">
                    <input
                      id={bookmark.pageName}
                      type="checkbox"
                      onChange={() => toBeDeletedAccess.push(bookmark.pageName)}
                    />
                  </td>
                  <td className="cbaColWidth85">
                    {bookmark.pageUrl ? bookmark.pageUrl : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="cba-bookmarks-modal-buttons">
          <button type="button" onClick={this.okButtonClicked}>{okButton}</button>
          <button type="button" onClick={this.cancelButtonClicked}>{cancelButton}</button>
        </div>
      </div>
    );
  }

}

ManageBookmarksData.propTypes = {
  content: PropTypes.shape(
    {
      pageAreaIndexPath: PropTypes.string.isRequired,
      requestingComponentPath: PropTypes.string.isRequired,
      runtime: PropTypes.object.isRequired,
      labelConfiguration: PropTypes.shape(
        {
          windowTitle: PropTypes.string.isRequired,
          deleteColumnHeader: PropTypes.string.isRequired,
          bookmarkColumnHeader: PropTypes.string.isRequired,
          okButton: PropTypes.string.isRequired,
          cancelButton: PropTypes.string.isRequired,
        }
      ).isRequired,
      testPresenter: PropTypes.object.isRequired,
    }
  ).isRequired
}
