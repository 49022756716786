import StateManagerHelper from "./StateManagerHelper";

/**
 * Keep the state of all tasks. 
 * 
 * The task state contains:
 * - The names of the currently loaded pages in the standard and xPage areas.
 * - The list of dialog windows and the name of the page loaded in each.
 * - The size (width and height) of the item defining the task.
 * - The layout configuration (space used for xPage area, size and color of divider etc.) of the task.
 * - The highlight color of the item defining the task.
 */
export default class PresenterStateManager {

  constructor() {
    this.taskEntriesList = {};
  }


  /**
   * Save the given task state for the task identified by the task path.
   */
  saveTaskState = (taskPath, state) => {
    this.taskEntriesList[taskPath] = StateManagerHelper.deepCopy(state);
  }

  /**
   * Get the task state for the task identified by the task path.
   */
  getTaskState = taskPath => StateManagerHelper.deepCopy(this.taskEntriesList[taskPath]);

  /**
  * Remove a dialog or a modal dialog from task state.
  *
  * The method does nothing if no dialog with the given name and type exists.
  * 
  * @param {string} pageAreaName The name of the dialog to be removed
  * @param {string} pageAreaType The type of dialog being removed
  * @param {string} taskPath The task from which is being removed
  * 
  */
  removeDialog = (pageAreaName, pageAreaType, taskPath) => {
    const taskState = this.taskEntriesList[taskPath];

    taskState.dialogs = taskState.dialogs.filter(dialog => dialog.pageAreaName !== pageAreaName || dialog.type !== pageAreaType);

    this.saveTaskState(taskPath, taskState);
  }

  /**
  * Mark a dialog or a modal dialog as not visible from task state.
  *
  * The method does nothing if no dialog with the given name and type exists.
  * 
  * @param {string} pageAreaName The name of the dialog to be removed
  * @param {string} pageAreaType The type of dialog being removed
  * @param {string} taskPath The task from which is being removed
  * 
  */
  hideDialog = (pageAreaName, pageAreaType, taskPath) => {
    const taskState = this.taskEntriesList[taskPath];

    const removableDialog = taskState.dialogs.find(dialog => dialog.pageAreaName === pageAreaName && dialog.type === pageAreaType);
    if (removableDialog) {
      removableDialog.visible = false;
    } else {
      console.warn("Dialog not found - ", pageAreaType, pageAreaName);
    }

    this.saveTaskState(taskPath, taskState);
  }

  /**
  * Update the position of a dialog or a modal dialog in task state.
  * 
  * The method implicitly sets the focus flag to true for the given dialog 
  * and false for all other dialogs.
  *
  * The method does not complain if no dialog with the given name and type exists.
  * 
  * @param {string} pageAreaName The name of the dialog to be modified
  * @param {string} pageAreaType The type of the dialog to be modified
  * @param {string} taskPath The task from which is being removed
  * @param {x:int, y:int} newPosition The new position to set for the dialog
  * 
  */
  updatePositionAndFocusDialog = (pageAreaName, pageAreaType, taskPath, newPosition) => {
    const currentTaskState = this.taskEntriesList[taskPath];

    currentTaskState.dialogs.forEach((dialog, index) => {
      if (dialog.pageAreaName === pageAreaName && dialog.type === pageAreaType) {
        dialog.position = newPosition;
        dialog.focused = true;
      } else {
        dialog.focused = false;
      }
    });

    this.saveTaskState(taskPath, currentTaskState);
  }

  /**
   * Build an initial task state object. 
   * 
   * @param {string} standardPage The name of the page loaded in the standard area.
   * @param {string} xPage The name of the page loaded in the XPage area.
   * @param {number} itemWidth The width of the item defining the task.
   * @param {number} itemHeight The height of the item defining the task.
   * @param {boolean} withEditContextMenu Should we include the edit context menu (and the keyboard shortcuts for cut/copy/paste)?
   * @param {*} itemLayout The layout of the task (i.e. standard vs. xPage division).
   * @param {string} itemHighlightColor The highlight color of the item defining the task.
   */
  static buildInitialTaskStateObject(standardPage, xPage, itemWidth, itemHeight, itemLayout, withEditContextMenu, itemHighlightColor, highlightColors) {
    return {
      standardPage,
      xPage,
      itemWidth,
      itemHeight,
      itemLayout,
      withEditContextMenu,
      dialogs: [],
      itemHighlightColor,
      highlightColors
    }
  }


  /**
   * Modify the page name and position for the given page area in the given task state.
   * 
   * @param {string} pageName The new page name to set.
   * @param {{ x: integer, y: integer}} position The new position of the page area to set. Not used for main page areas.
   * @param {string} pageAreaType The type of the page area to modify.
   * @param {string} pageAreaName The name of the page area to modify.
   * @param {*} taskState The task state object containing the page area configurations to be changed.
   */
  static setPageForPageAreaInTaskState(pageName, position, pageAreaType, pageAreaName, taskState) {
    switch (pageAreaType) {
      case 'main':
        PresenterStateManager.setMainAreaPage(pageName, pageAreaName, taskState);
        break;
      case 'dialog':
        PresenterStateManager.changeOrCreatePageArea(pageName, position, pageAreaName, "dialog", taskState.dialogs);
        break;
      case 'modal':
        PresenterStateManager.changeOrCreatePageArea(pageName, position, pageAreaName, "modal", taskState.dialogs);
        break;
      default:
        console.error(`Unknown page area type: ${pageAreaType}`);
    }
  }

  /**
   * Set the page name for the standard or xPage area.
   * 
   * @param {string} pageName The new page name to set.
   * @param {string} pageAreaName The name of the page area: 'standard' or 'xPage'.
   * @param {string} taskState 
   * @param {*} taskState The task state object containing the page area configurations to be changed.
   */
  static setMainAreaPage(pageName, pageAreaName, taskState) {
    switch (pageAreaName) {
      case 'standard':
        taskState.standardPage = pageName;
        break;
      case 'xPage':
        taskState.xPage = pageName;
        break;
      default:
        console.error(`Unknown page area name for 'main' page area type: ${pageAreaName}`);
    }
  }


  /**
   * Set the page name and position for a page area identified by the given area name and area type residing in the given page area list.
   * 
   * The method implicitly creates an entry for the page area if there is no such entry yet.
   * 
   * @param {string} pageName The new page name to set.
   * @param {{ x: integer, y: integer}} position The new position of the page area to set.
   * @param {string} pageAreaName The name of the page area.
   * @param {string} pageAreaType The type of the page area.
   * @param {[]} pageAreaList The list of page areas to modify.
   */
  static changeOrCreatePageArea(pageName, position, pageAreaName, pageAreaType, pageAreaList) {
    const pageAreaEntry = pageAreaList.find(value => value.pageAreaName === pageAreaName && value.type === pageAreaType);
    if (pageAreaEntry === undefined) {
      pageAreaList.push({
        pageAreaName,
        type: pageAreaType,
        pageName,
        position,
        visible: true
      });
    } else {
      pageAreaEntry.pageName = pageName;
      pageAreaEntry.position = position;
      pageAreaEntry.visible = true;
    }
  }

  /**
   * Get the full state for all existing tasks.
   * 
   * Use the result of this method as parameter to preloadTasksState to preload another instance to our current state. 
   */
  getAllTasksState = () => StateManagerHelper.deepCopy(this.taskEntriesList);

  /**
   * Drop all tasks.
   */
  clearTasksState = () => { this.taskEntriesList = {}; };

  /**
   * Preload the tasks state returned by a call to getAllTasksState.
   */
  preloadTasksState = (allTasksState) => {
    this.taskEntriesList = StateManagerHelper.deepCopy(allTasksState);
  }


}
