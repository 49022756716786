import StateManagerHelper from "./StateManagerHelper";

export default class TraceLogBuffer {

  constructor() {
    this.logEntriesList = [];
    this.entryCounter = 0;
    this.newEntryListener = undefined;
  }

  // public API for components ------------------------------------------------------------------

  /**
   * Report an event that is relevant for the trace log.
   * 
   * The method expects these parameters:
   * - entryType: A String representing the type of the event.
   * - timestamp: A Date representing the timestamp to log for the event.
   * - entryContent: All further attributes of the event to be logged.
   * 
   * The method will implicitly assigna unique log entry counter to the logged event.
   * The method will create a deep copy of the given entryContent to decouple the log
   * from further modifications on the entryContent.
   * 
   */
  reportEvent = (entryType, timestamp, entryContent) => {
    const newEntry = {
      entryId: this.getNextCount().toString(),
      timestamp: TraceLogBuffer.buildTimeString(timestamp),
      type: entryType,
      details: StateManagerHelper.deepCopy(entryContent)
    }
    this.logEntriesList.push(newEntry);
    if (this.newEntryListener !== undefined) {
      this.newEntryListener();
    }
  }


  // public API for log consumers --------------------------------------------------------------------

  /**
   * Get the next bunch of log entries for delivery to the server and drop them from our queue.
   * 
   * Note: This method must be called from the GUI thread to avoid multithreading issues!
   * 
   * The method returns a list of log entry objects. I returns an empty list of no log entries are pending.
   */
  popEntries = () => {
    const deliveredEntries = this.logEntriesList;
    this.logEntriesList = [];
    return deliveredEntries;
  }


  /**
   * Get the list of current log entries (without dropping them).
   * 
   * The method returns a list of log entry objects. I returns an empty list of no log entries are pending.
   */
  peekEntries = () => StateManagerHelper.deepCopy(this.logEntriesList);

  /**
   * Set a function to call each time we receive a new entry.
   * 
   * We support a single entry listener only. The entry listener is called after the new log entry is put into our buffer.
   * 
   * @param callback The function to call each time a new entry is put into our buffer.
   */
  setNewEntryListener = (callback) => {
    this.newEntryListener = callback;
  }

  // private stuff --------------------------------------------------------------------


  /**
   * Get a string representation of the given time which is helpful in the trace log:
   */
  static buildTimeString(date) {
    // Hint: We could use the momentjs library here:
    // DNI: example: return moment(date).format("YYYY-MM-DD THH:mm:ss.SSS Z");
    return `${
      TraceLogBuffer.padLeadingZeroes(date.getFullYear(), 4)}-${
      TraceLogBuffer.padLeadingZeroes((date.getMonth() + 1), 2)}-${
      TraceLogBuffer.padLeadingZeroes(date.getDate(), 2)}T${
      TraceLogBuffer.padLeadingZeroes(date.getHours(), 2)}:${
      TraceLogBuffer.padLeadingZeroes(date.getMinutes(), 2)}:${
      TraceLogBuffer.padLeadingZeroes(date.getSeconds(), 2)}.${
      TraceLogBuffer.padLeadingZeroes(date.getMilliseconds(), 3)}${
      TraceLogBuffer.buildTimeZoneOffsetString(date.getTimezoneOffset())}`;
  }

  static buildTimeZoneOffsetString(offsetInMinutes) {
    const absoluteOffsetInMinutes = Math.abs(offsetInMinutes);
    const minutesOffset = absoluteOffsetInMinutes % 60;
    const hoursOffset = (absoluteOffsetInMinutes - minutesOffset) / 60;
    return (offsetInMinutes > 0 ? '-' : '+') + TraceLogBuffer.padLeadingZeroes(hoursOffset.toString(), 2) + TraceLogBuffer.padLeadingZeroes(minutesOffset.toString(), 2);
  }

  static padLeadingZeroes(number, size) {
    let result = `${number}`;
    while (result.length < size) {
      result = `0${result}`;
    }
    return result;
  }

  /**
   * Get the next entry count value and increase the counter.
   */
  getNextCount = () => {
    this.entryCounter +=1;
    return this.entryCounter;
  }

}
