import Utils from '../utils/Utils';
import UserDataUploader from './UserDataUploader';

/**
 * Manage the transfer of the trace log to the server. 
 * 
 * We support transmission of the trace log entries 
 *  - via HTTP PUT calls or
 *  - window.postMessage events sent to the given target domain origin or
 *  - writing the trace logs to the console.
 */
export default class TraceLogUploader extends UserDataUploader {

  constructor(traceLogBuffer) {
    super(traceLogBuffer, TraceLogUploader.buildTracePostContent, "traceLog")
    this.transmitInterval = 20000;
  }

  // ------- public API -----------------------------------------------------------------------------

  // See the base class UserDataUploader for our public API.

  // ------- private stuff --------------------------------------------------------------------------

  /**
   * Build the content of a trace transmission to the server. 
   * 
   * This consists of a meta data structure and the list of log entries to transmit.
   * We take the list of log entries from our internal log entries list but
   * we do not drop the entries from this list yet.
   * 
   * @param {String} sendTimestamp The time of send operation.
   * @param {*} runtime 
   */
  static buildTracePostContent = (sendTimestamp, sessionContext, logEntriesList) => {
    const { sessionId, username, loginTimestamp } = sessionContext;
    if (sessionId === undefined) {
      console.warn("Sending trace log with dummy session id!");
    }
    const metaData = {
      userId: username,
      sessionId: sessionId === undefined ? 'dummySessionId' : sessionId,
      loginTimestamp,
      sendTimestamp,
      cbaVers: Utils.getCbaVersion()
    };

    const tracePostContent = {
      metaData,
      logEntriesList
    };

    return tracePostContent;
  }

}
