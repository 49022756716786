import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import DragAndDropHelper, { DndItemTypes } from '../config/DragAndDropHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import RenderingHelper from './RenderingHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import IndexPathHelper from '../state/IndexPathHelper';
import Utils from '../utils/Utils';

/**
 * A 'bare function component' used to manage the drag&drop functionality for the CbaSimpleTextField component.
 */
function SimpleTextFieldRenderer({ onClick, onContextMenu, onSelect, title, style, value, className, config, path, runtime }) {

  const ref = useRef(null);
  const [dragCollectedProps, drag, preview] = DragAndDropHelper.addDrag(
    config, path, runtime, DndItemTypes.VALUE_DISPLAY, {
      title,
      style,
      value
    }
  );

  preview(getEmptyImage(), {
    captureDraggingState: true
  });

  const [dropCollectedProps, drop] = DragAndDropHelper.addDrop(config, path, runtime, DndItemTypes.VALUE_DISPLAY, false);
  drag(drop(ref));

  const extendedStyle = Utils.safeDeepCopy(style);
  DragAndDropHelper.addIsDraggingStyleAttributes(extendedStyle, dragCollectedProps);
  DragAndDropHelper.addCanDropStyleAttributes(extendedStyle, dropCollectedProps);

  if (config.renderAsDiv) {
    CommonConfigHelper.setStyleAttribute(extendedStyle, "whiteSpace", "pre-wrap");
    CommonConfigHelper.setStyleAttribute(extendedStyle, "overflow", "auto");

    return (
      <div
        ref={ref}
        readOnly
        onClick={onClick}
        onContextMenu={onContextMenu}
        onSelect={onSelect}
        title={title}
        style={extendedStyle}
        data-cba-id={config.pageEditId}
        className={className}
      >
        {value}
      </div>
    )
  }

  // We use a textarea instead of a label since it preserves line breaks etc. 
  return (
    <textarea
      ref={ref}
      readOnly
      onClick={onClick}
      onContextMenu={onContextMenu}
      onSelect={onSelect}
      title={title}
      style={extendedStyle}
      data-cba-id={config.pageEditId}
      value={value}
      className={className}
    />
  )
}

export default class CbaSimpleTextField extends Component {

  componentDidMount() {
    const { config, runtime } = this.props;
    const { textInputReferencePath } = config;
    if (textInputReferencePath !== undefined) {
      runtime.eventEmitter.addListener(`inputFieldUpdated-${textInputReferencePath}`, this.onInputFieldUpdate.bind(this));
    }
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onInputFieldUpdate = (newTextValue) => {
    const pathState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setTextValue(pathState, newTextValue);
    ComponentStateHelper.registerState(this, pathState);
    RenderingHelper.triggerRendering(this);
  }

  onClickHandler = (event) => {
    const { runtime, path, config } = this.props;
    if (!config.renderAsDiv) {
      CommonActionsHelper.processSelectedTextForCutAndPaste(path, event, true, undefined, undefined, runtime);
    }
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  onSelectionHandler = (event) => {
    const { runtime, path, config } = this.props;
    const { suppressSelections } = config;

    if (suppressSelections) {
      event.target.selectionStart = event.target.selectionEnd;
    } else {
      CommonActionsHelper.processSelectedTextForCutAndPaste(path, event, true, undefined, undefined, runtime);
    }

  }

  static addAttributesToInitialState(result, config, runtime) {
    const { textInputReferencePath } = config;
    if (textInputReferencePath !== undefined) {
      const filteredPaths = runtime.componentStateManager.filterExistingPathIds(candidate => IndexPathHelper.getLastPageSegmentFromPath(candidate) === textInputReferencePath);
      if (filteredPaths.length > 0) {
        result.textValue = ComponentStateHelper.getStateAttributeByPathId(StateAttributeAccess.extractTextValue, filteredPaths[0], runtime);
      } else {
        const providerComponent = runtime.pageConfigurationsManager.findConfigurationForPageSegment(textInputReferencePath);
        const { text } = providerComponent.config;
        result.textValue = text.label;
      }
    }
  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const { suppressSelections } = config;
    const pathState = ComponentStateHelper.getState(this);

    const text = CommonConfigHelper.buildDisplayText(config, pathState, runtime);

    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);
    CommonConfigHelper.setStyleAttribute(style, "resize", "none");

    // override browser default padding value for textarea: 
    if (config.position.padding === undefined) {
      CommonConfigHelper.setStyleAttribute(style, "padding", "0px");
    }

    return (
      <SimpleTextFieldRenderer
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        onSelect={this.onSelectionHandler}
        title={CommonConfigHelper.buildTitle(config)}
        style={style}
        value={text}
        className={suppressSelections ? "hide-selection" : ""}
        config={config}
        path={path}
        runtime={runtime}
      />
    )
  }

}


CbaSimpleTextField.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        textInputReferencePath: PropTypes.string,
        renderAsDiv: PropTypes.bool
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}

SimpleTextFieldRenderer.propTypes = {
  onClick: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    {
      dragAndDrop: PropTypes.object,
      pageEditId: PropTypes.string,
      renderAsDiv: PropTypes.bool
    }
  ).isRequired,
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
}
