import StateManagerHelper from './StateManagerHelper';
import PathTranslationHelper from './PathTranslationHelper';
import UserDefPathHelper from './UserDefPathHelper';

/**
 * Manage a global clipboard. 
 */
export default class ClipboardManager {

  constructor(traceLogBuffer) {
    this.traceLog = traceLogBuffer;
    this.clipboardContent = undefined;
    this.currentSelectionHolder = undefined;
    this.currentInsertPosition = undefined;
  }

  // public API ------------------------------------------------------------------------------------

  /**
   * Do the cut operation: Grab the current selection from the current selection holder and put
   * it into our clipboard buffer. Tell the current selection holder to drop the selected text.
   * 
   * If no selection is registered currently or the selection is read-only 
   * the method will write just a trace log and skip the operation.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} indexPath The index path of the calling display component instance.
   * @param {*} runtime The common runtime context structure.
   */
  cut = (triggeringType, indexPath, runtime) => {
    // keep a reference to the selection holder: the getSelectionCallback might deregister the selection holder!
    const selectionHolderEntry = this.currentSelectionHolder;
    if (selectionHolderEntry !== undefined && selectionHolderEntry.isReadOnlyCallback() === false) {
      this.clipboardContent = selectionHolderEntry.getSelectionCallback(true);
      this.traceCutCopy(
        triggeringType,
        'cut',
        indexPath,
        selectionHolderEntry,
        true,
        runtime
      );
      this.currentSelectionHolder = undefined;
      this.currentInsertPosition = undefined;
    } else {
      this.traceCutCopy(
        triggeringType,
        'cut',
        indexPath,
        selectionHolderEntry,
        false,
        runtime
      );
    }
  }

  /**
   * Could we perform a cut operation currently? 
   */
  cutEnabled = () => this.currentSelectionHolder !== undefined && this.currentSelectionHolder.isReadOnlyCallback() === false

  /**
   * Do the copy operation: Grab the current selection from the current selection holder and put
   * it into our clipboard buffer.
   * 
   * If no selection is registered currently the method will write just a trace log and skip the operation.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} indexPath The index path of the calling display component instance.
   * @param {*} runtime The common runtime context structure.
   */
  copy = (triggeringType, indexPath, runtime) => {
    // keep a reference to the selection holder: the getSelectionCallback might deregister the selection holder!
    const selectionHolderEntry = this.currentSelectionHolder;
    if (selectionHolderEntry !== undefined) {
      this.clipboardContent = selectionHolderEntry.getSelectionCallback(false);
      this.traceCutCopy(
        triggeringType,
        'copy',
        indexPath,
        selectionHolderEntry,
        true,
        runtime
      );
      this.currentSelectionHolder = undefined;
      this.currentInsertPosition = undefined;
    } else {
      this.traceCutCopy(
        triggeringType,
        'copy',
        indexPath,
        selectionHolderEntry,
        false,
        runtime
      );
    }
  }

  /**
   * Could we perform a copy operation currently? 
   */
  copyEnabled = () => this.currentSelectionHolder !== undefined


  /**
   * Do the paste operation: Tell the current insert position holder to put in the text in our clipboard buffer.
   * 
   * If no insert position is registered currently or we do not have any content in the clipboard buffer yet
   * the method will just write a trace log entry and skip the operation.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} indexPath The index path of the calling display component instance.
   * @param {*} runtime The common runtime context structure.
   */
  paste = (triggeringType, indexPath, runtime) => {
    // keep a reference to the input position: the insertCallback might deregister the input position!
    const insertPositionEntry = this.currentInsertPosition;
    if (this.currentInsertPosition !== undefined && this.clipboardContent !== undefined) {
      this.currentInsertPosition.insertCallback(this.clipboardContent);
      this.tracePaste(
        triggeringType,
        indexPath,
        insertPositionEntry,
        true,
        runtime
      );
      this.currentSelectionHolder = undefined;
      this.currentInsertPosition = undefined;
    } else {
      this.tracePaste(
        triggeringType,
        indexPath,
        insertPositionEntry,
        false,
        runtime
      );
    }
  }

  /**
   * Could we perform a paste operation currently? 
   */
  pasteEnabled = () => this.currentInsertPosition !== undefined && this.clipboardContent !== undefined

  /**
   * Register the currently active selection.
   * 
   * @param {String} indexPath The index path of the display component instance carrying the currently active selection.
   * @param {get(dropContent)} getSelectionCallback A method to obtain the currently selected text. 
   *    The method supports a boolean flag to drop the selected content in the source component. 
   * @param {isReadOnly()} isReadOnlyCallback A method to obtain the current read only status of the component instance. 
   */
  registerSelection = (indexPath, getSelectionCallback, isReadOnlyCallback) => {
    this.currentSelectionHolder = {
      indexPath,
      getSelectionCallback,
      isReadOnlyCallback
    };
  }

  /**
   * Deregister a previously registered selection.
   * 
   * @param indexPath The index path of the display component instance deregistering the selection.
   */
  deregisterSelection = (indexPath) => {
    if (this.currentSelectionHolder !== undefined && this.currentSelectionHolder.indexPath === indexPath) {
      this.currentSelectionHolder = undefined;
    }
  }

  /**
   * Register the current paste insert position.
   * 
   * The registering component should not be read-only, i.e. it should be possible to really insert content
   * at the insert position.
   * 
   * @param indexPath The index path of the display component instance carrying the current insert position.
   * @param insertCallback A method to insert text at the insert position.
   */
  registerInsertPosition = (indexPath, insertCallback) => {
    this.currentInsertPosition = {
      indexPath,
      insertCallback
    };
  }

  /**
   * Deregister a previously registered insert position.
   * 
   * @param indexPath The index path of the display component instance deregistering the insert position.
   */
  deregisterInsertPosition = (indexPath) => {
    if (this.currentInsertPosition !== undefined && this.currentInsertPosition.indexPath === indexPath) {
      this.currentInsertPosition = undefined;
    }
  }

  /**
   * Register that a component recieved the input focus. 
   * 
   * We will deregister any other component as selection or insert position provider.
   */
  registerFocus = (indexPath) => {
    if (this.currentInsertPosition !== undefined && this.currentInsertPosition.indexPath !== indexPath) {
      this.currentInsertPosition = undefined;
    }
    if (this.currentSelectionHolder !== undefined && this.currentSelectionHolder.indexPath !== indexPath) {
      this.currentSelectionHolder = undefined;
    }
  }

  /**
   * Get a representation of our internal state that can be written to the trace log.
   */
  getStateForTracing = () => (
    StateManagerHelper.deepCopy({
      clipboardContent: this.clipboardContent,
      selectionHolder: this.currentSelectionHolder === undefined ? undefined : {
        indexPath: this.currentSelectionHolder.indexPath
      },
      insertPosition: this.currentInsertPosition === undefined ? undefined : {
        indexPath: this.currentInsertPosition.indexPath
      }
    })
  )


  // private stuff ------------------------------------------------------------------------------------

  /**
   * Create a trace log entry for a cut or copy operation
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} operationType The operation type to trace: CUT, COPY
   * @param {String} triggeringIndexPath The index path of the triggering display component instance.
   * @param {*} selectionHolderEntry The selection holder entry that was used to perform the operation. 
   * @param {boolean} wasPerformed A flag indicating whether the requested action was performed or denied.
   * @param {*} runtime The common runtime context structure.
   */
  traceCutCopy = (triggeringType, operation, indexPath, selectionHolderEntry, wasPerformed, runtime) => {
    this.trace(
      triggeringType,
      operation,
      indexPath,
      selectionHolderEntry === undefined ? undefined : selectionHolderEntry.indexPath,
      wasPerformed ? this.clipboardContent : undefined,
      wasPerformed,
      runtime
    );
  }

  /**
   * Create a trace log entry for a cut or copy operation
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} triggeringIndexPath The index path of the triggering display component instance.
   * @param {*} insertPositionEntry The insert position entry that was used to perform the operation. 
   * @param {boolean} wasPerformed A flag indicating whether the requested action was performed or denied.
   * @param {*} runtime The common runtime context structure.
   */
  tracePaste = (triggeringType, indexPath, insertPositionEntry, wasPerformed, runtime) => {
    this.trace(
      triggeringType,
      'paste',
      indexPath,
      insertPositionEntry === undefined ? undefined : insertPositionEntry.indexPath,
      wasPerformed ? this.clipboardContent : undefined,
      wasPerformed,
      runtime
    );
  }


  /**
   * Create a trace log entry.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} operationType The operation type to trace: 'cut', 'copy', 'paste'
   * @param {String} triggeringIndexPath The index path of the triggering display component instance. Might be undefined (keyboard triggers).
   * @param {String} contentDealerIndexPath The index path of the display component instance providing resp. accepting content.
   * @param {String} content The content that was transferred
   * @param {boolean} wasPerformed A flag indicating whether the requested action was performed or denied.
   * @param {*} runtime The common runtime context structure.
   */
  trace = (triggeringType, operationType, triggeringIndexPath, contentDealerIndexPath, content, wasPerformed, runtime) => {
    const triggerUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(triggeringIndexPath, runtime);
    const contentUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(contentDealerIndexPath, runtime);
    this.traceLog.reportEvent(
      'CutCopyPaste',
      new Date(),
      {
        triggerType: triggeringType,
        triggerIndexPath: triggeringIndexPath,
        triggerUserDefIdPath,
        triggerUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(triggerUserDefIdPath),
        operation: operationType,
        contentIndexPath: contentDealerIndexPath,
        contentUserDefIdPath,
        contentUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(contentUserDefIdPath),
        content,
        isPerformed: wasPerformed
      }
    );
  }


}
