import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import RenderingHelper from '../RenderingHelper';

import './CbaTimer.css';
import StateAttributeAccess from '../../state/StateAttributeAccess';

const ROW = "row";
const COLUMN_REVERSE = "column-reverse";

const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame
  || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

export default class CbaTimer extends Component {

  state = {
    value: '0%'
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
    this.setTimerType();
    this.timer = requestAnimationFrame(this.timerAnimation);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.timer);
    this.timer = null;
    RenderingHelper.onUnmount(this);
  }

  timerAnimation = () => {
    const { runtime, config } = this.props;
    const { timerEvent, runForward } = config;
    const timerData = runtime.statemachinesManager.getTimerData(timerEvent);

    if (this.timer) {
      const percentage = this.computeTimerPercentage(timerData, runForward);

      this.setState({
        value: `${percentage}%`
      });

      requestAnimationFrame(this.timerAnimation);
    }
  }

  setTimerType = () => {
    const { config } = this.props;
    const { verticalOrientation, runForward } = config;
    this.timerType = this.computeTimerType(verticalOrientation, runForward);
  }

  computeTimerType = (verticalOrientation) => {
    if (verticalOrientation) return COLUMN_REVERSE;
    if (!verticalOrientation) return ROW;

    console.error("Timer Flags not ok", verticalOrientation);
    return null;
  }

  computeTimerValue = time => ({
    seconds: time ? Math.ceil(time / 1000) : 0,
    miliseconds: time ? (time % 1000) : null
  });

  computeTimerPercentage = (timerData, runForward) => {
    const { totalTime, remainingTime, isRunning } = timerData;
    let percentage;

    if (!isRunning && !remainingTime) {
      percentage = 0;
    } else {
      percentage = ((remainingTime * 100) / totalTime);
    }

    if (runForward) {
      percentage = 100 - percentage;
    }

    return percentage.toFixed(2);
  }

  render() {
    const { runtime, config, path, orientation } = this.props;
    const { value } = this.state;
    const pathState = ComponentStateHelper.getState(this);
    const pressedState = StateAttributeAccess.extractSelected(pathState);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, pressedState, orientation, runtime);
    const { timerEvent, showSecondsLabel } = config;

    const timerStyleDirection = (this.timerType === ROW) ? "width" : "height";
    const animationStyle = {
      [timerStyleDirection]: value
    }

    const timerData = runtime.statemachinesManager.getTimerData(timerEvent);
    const timerParsedValue = this.computeTimerValue(timerData.remainingTime);

    return (
      <div
        className={`cba-timer-wrap ${this.timerType}`}
        style={style}
        data-cba-id={config.pageEditId}
        title={CommonConfigHelper.buildTitle(config)}
      >
        <div className="cba-timer-animation" style={animationStyle} />
        {showSecondsLabel ? <span className="cba-timer-secondsLabel">{timerParsedValue.seconds}</span> : null}
      </div>
    )
  }

}

CbaTimer.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(PropTypesHelper.getStandardConfigPropTypes(false)).isRequired,
  orientation: PropTypes.string.isRequired
}
