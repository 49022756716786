import axios from 'axios';
import Utils from '../utils/Utils';

/**
 * Interaction with the server providing configuration data and resources.
 * 
 * We support two running modes:
 *  - The standard mode expecting a REST API on the server to obtain configuration data.
 *  - A simplified mode expecting a plain static content server. This mode is meant to 
 *  support item authoring tools that do not embed a dynamic content server. 
 * 
 *   Action              | Preview mode                                      | Rest-API mode
 *   --------------------|---------------------------------------------------|---------------------------------------------------------
 *   Config file         | <WindowURLWithPath>/courses/previewcourse.json    | <WindowURLNoPath>/ee4cba-api/courses?userId=<username>&<URLParams>
 *   retrieval           | <WindowURLWithPath>/tests/<testname>.json         | <WindowURLNoPath>/ee4cba-api/tests/<testname>/?<URLParams>
 *                       | <WindowURLWithPath>/items/<itemname>.json         | <WindowURLNoPath>/ee4cba-api/items/<itemname>/?<URLParams>
 *   --------------------|---------------------------------------------------|---------------------------------------------------------
 *   Resource            | ./resources                                       | <WindowURLNoPath>/ee4cba_assets/<itemname>/resources
 *   retrieval           | ./external-resources                              | <WindowURLNoPath>/ee4cba_assets/<itemname>/external-resources
 *                       |                                                   |
 */

function safeGet(value, def) {
  return (!!value || value === "") ? value : def;
}
export default class ServerCalls {

  // --------- public API --------------------------------------------------------------------------------

  /**
   * The URL path components used to access the server's API.
   */
  static restApiTargets = {
    CONFIGURATION_API: safeGet(window.cba_runtime_config?.configuration_api, 'ibsd/'),
    ASSETS_API: safeGet(window.cba_runtime_config?.assets_api, 'assets'),
    TRACE_ENDPOINT: safeGet(window.cba_runtime_config?.trace_endpoint, 'trace'),
    STATE_ENDPOINT: safeGet(window.cba_runtime_config?.state_endpoint, 'state'),
    RECORDING_ENDPOINT: safeGet(window.cba_runtime_config?.recording_endpoint, 'recording')
  }

  /**
   * Do we run with a simplified server interactions protocol
   * specifically designed to support a preview in an item authoring tool?
   * 
   * See class comment for more details.
   */
  static runWithSimplifiedPreviewServer() {
    const controllerMode = Utils.getControllerMode();
    switch (controllerMode) {
      case 'preview':
        return true;
      case 'rest-api':
        return false;
      default:
        return false;
    }
  }


  /**
   * Get the complete configuration (i.e. test course, tests and items) from the server.
   */
  static getJsonsData(username) {
    console.log(`Getting configuration data in ${ServerCalls.runWithSimplifiedPreviewServer() ? "preview" : "rest-api"} mode.`);
    return (ServerCalls.doGetCourseConfigurationStep(ServerCalls.getFirstCourse(), username)
      .then(courseStepResult => ServerCalls.doGetTestConfigurationsStep(courseStepResult))
      .then(testsStepResult => ServerCalls.doGetItemConfigurationsStep(testsStepResult))
      .then(itemsStepResult => ServerCalls.doBuildConfigurationStep(itemsStepResult))
    );
  }

  /**
   * Build a promise that puts the given assets to the CBA cache.
   * 
   * This is used by the ServiceWorker to cache assets.
   * 
   * @param {*} assets 
   */
  static cacheAssets(assets = []) {
    console.log(assets);
  }

  static preloadResources(resources, type) {
    const resourcePromises = resources.map(resource => new Promise((resolve, reject) => {
      const linkTag = document.createElement('link');
      linkTag.as = type;
      linkTag.href = resource.path;
      linkTag.rel = "preload";


      if (type === "video" || type === "audio") {
        linkTag.type = "media";
        linkTag.rel = "prefetch";
      }


      if (resource.size > 5 * (10 ** 6) && (type === "video" || type === "audio")) {
        linkTag.as = "fetch";
      }

      linkTag.onload = () => {
        resource.hadErrors = false;
        resolve(resource);
      }

      linkTag.onerror = () => {
        console.error("Error while loading resource: ", resource);
        resource.hadErrors = true;
        resolve(resource);
      }
      document.head.append(linkTag);
    }));


    return Promise.all(resourcePromises);

  }

  // --------- private stuff ----------------------------------------------------------------------------

  /**
   * Create the axios instance that we will use to fetch data from the server.
   */
  static instance = axios.create({
    baseURL: ServerCalls.getServerPath(),
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json'
    },
  });

  /**
   * Build the base of the URLs that we use to access the server to obtain data.
   * 
   * We use the origin of our window as a starting point and modify the URL as follows:
   * If we do not run in a simplifying preview context we return our origin URL but replace the path 
   * (and all parameters) with the plain REST API endpoint. 
   *   Example: For the origin URL http://127.0.0.1/app?param=value we return http://127.0.0.1/ee4cba-api
   * If we run in a simplifying preview context we return the origin URL but drop all parameters. 
   *   Example: For the origin URL http://127.0.0.1/app?param=value we return http://127.0.0.1/app
   */
  static getServerPath() {
    return ServerCalls.runWithSimplifiedPreviewServer()
      ? ServerCalls.getCallingUrlWithoutParams()
      : `${Utils.getCallingUrlWithoutPath()}/${ServerCalls.restApiTargets.CONFIGURATION_API}`;
  }

  /**
   * Get the URL that called our code and strip all params.
   * 
   * The method retains the path part of the URL.
   */
  static getCallingUrlWithoutParams() {
    const href = window.location.href.split('?')[0];
    return `${href.substring(0, href.lastIndexOf('/'))}/`;
  }

  /**
   * Get the path of the initial course configuration.
   * 
   *  In a simplifying preview mode we return 'previewcourses' as file name. (Another method 
   *  will append the .json extension later.)
   *  In full REST API mode we don't specify a specific file name: A username parameter
   *  will be added in this case by another method.
   */
  static getFirstCourse() {
    let defaultCourse = Utils.getQueryParam("course") ?? '';
    return ServerCalls.runWithSimplifiedPreviewServer() ? 'previewcourses' : defaultCourse;
  }


  static doGetCourseConfigurationStep(courseName, username) {
    return ServerCalls.getCourse(courseName, username);
  }

  static doGetTestConfigurationsStep(courseStepResult) {
    const { settings, headerButtons, sessionId, courses } = courseStepResult;

    // Remove all duplicates from the array of test names: 
    // Don't retrieve the same test configuration more than once.
    const testsToRetrieve = ServerCalls.buildArrayWithoutDuplicates(courses);

    // Get the array of promises that retrieve all tests 
    const promisesForTests = testsToRetrieve.map(testName => ServerCalls.getTest(testName));

    // Return a promise that will execute all test retrieval promises and return the results together with the courses step result
    return ServerCalls.axiosAllWrapper(
      {
        settings,
        headerButtons,
        sessionId,
        courses,
        testsToRetrieve
      },
      promisesForTests
    );
  }

  static isEmptyRetrievedValue(value) {
    return value === undefined || value === null || value.toString() === '';
  }

  static doGetItemConfigurationsStep(testsStepResult) {
    const { settings, headerButtons, sessionId, courses, testsToRetrieve } = testsStepResult.contextData;
    const tests = testsStepResult.response;

    const flaggedErrors = [];
    if (tests.length === 0) {
      flaggedErrors.push('no tests to execute found in course configuration');
    }
    tests.forEach((value, index) => { if (this.isEmptyRetrievedValue(value)) flaggedErrors.push(`could not retrieve test ${testsToRetrieve[index]}`); });

    // get all item names mentioned in the tests
    const collectedItemNames = tests
      .filter(value => !this.isEmptyRetrievedValue(value))
      .reduce((itemNamesArray, test) => itemNamesArray.concat(test.items), []);

    // remove duplicates from item names
    const itemsToRetrieve = ServerCalls.buildArrayWithoutDuplicates(collectedItemNames);

    // get array of item promises
    const promisesForItems = itemsToRetrieve.map(itemName => ServerCalls.getItem(itemName));

    // get all items data
    return ServerCalls.axiosAllWrapper(
      {
        settings,
        headerButtons,
        sessionId,
        courses,
        tests,
        itemsToRetrieve,
        flaggedErrors
      },
      promisesForItems
    );

  }

  static doBuildConfigurationStep(itemsStepResult) {
    const { settings, headerButtons, sessionId, courses, tests, itemsToRetrieve, flaggedErrors } = itemsStepResult.contextData;
    const items = itemsStepResult.response;

    if (items.length === 0) {
      flaggedErrors.push('no required items found in test specifications')
    }
    items.forEach((value, index) => { if (this.isEmptyRetrievedValue(value)) flaggedErrors.push(`could not retrieve item ${itemsToRetrieve[index]}`); });

    return (ServerCalls.validateJsonResults(flaggedErrors, courses, tests, items.filter(value => !this.isEmptyRetrievedValue(value)))
      ? {
        success: true,
        payload: {
          settings,
          headerButtons,
          sessionId,
          courses,
          tests,
          items,
        }
      }
      : {
        success: false
      }
    );
  }

  /**
   * Create a promise that runs an array of promises and returns an array of results.
   * 
   *  @param {*} contextData Some arbitrary context data that we will include in the result.
   *  @param {[*]} promisesArray The promises to run.
   * 
   *  @returns A promise that will return an object with two fields: 
   *    - contextData: the given context data
   *    - response: the array of promise results
   */
  static axiosAllWrapper(contextData, promisesArray) {
    return axios.all(promisesArray)
      .then((response) => {
        const result = {
          contextData,
          response
        };
        return result;
      })
      .catch((error) => {
        console.info(error);
      });
  }

  /**
   * Build an array that contains the entries of the given array
   * but drops all duplicates.
   */
  static buildArrayWithoutDuplicates(array) {
    return [...new Set(array)];
  }

  /**
   * Validate the configuration obtained from the server
   * 
   * @param {*} flaggedErrors An array of errors that were detected during data retrieval.
   * @param {*} course The list of the names of the tests to be executed.
   * @param {*} tests An array of test configurations.
   * @param {*} items An array of item configurations.
   */
  static validateJsonResults(flaggedErrors, course, tests, items) {
    if (flaggedErrors.length !== 0) {
      console.info('Errors during data retrieval:', flaggedErrors);
      console.info('loaded course:', course);
      console.info('loaded tests:', tests);
      console.info('loaded items:', items);
    }
    return flaggedErrors.length === 0
      && (course !== undefined || course.length > 0)
      && (tests !== undefined || tests.length > 0)
      && (items !== undefined || items.length > 0)
  }

  /**
   * Get a test course configuration from the server.
   * 
   * @param {*} courseName 
   */
  static getCourse(courseName, username) {
    return ServerCalls.getDataFromServer('courses', courseName, username);
  }

  /**
   * Get a test configuration from the server.
   * 
   * @param {*} testName
   */
  static getTest(testName) {
    return ServerCalls.getDataFromServer('tests', testName, undefined);
  }


  /**
   * Get an item configuration from the server.
   * 
   * @param itemName 
   */
  static getItem(itemName) {
    return ServerCalls.getDataFromServer('items', itemName, undefined);
  }


  /**
   * Get configuration data from the server.
   * 
   * - If we run in the simplifying preview mode we add a file extension .json at the end of the request URL path. 
   *   This assumes a static content server serving static configuration data files from the file system.
   * - If we run in in full REST-API mode we don't append a file extension since we assume a service API to respond.
   * 
   * The method log a message and returns an empty array if any error occurs.
   * 
   * @param {String} typeOfData The type of configuration data to retrieve: 'courses', 'tests', 'items'.
   * @param {String} nameOfValue The name of the data item to retrieve, e.g. the test name of the test to retrieve or the item name of the item to retrieve.
   * @param {String} username The name of the user for whom we retrieve configuration data. We add this as query parameter to the request URL.
   */
  static getDataFromServer(typeOfData, nameOfValue, username) {
    const urlPathExtension = `/${typeOfData}/${nameOfValue}${ServerCalls.runWithSimplifiedPreviewServer() ? '.json' : ''}`;
    const params = (
      ServerCalls.runWithSimplifiedPreviewServer()
        ? {}
        : {
          params: ServerCalls.addAsParam(Utils.getQueryParams(), username)
        }
    );
    return ServerCalls.instance.get(urlPathExtension, params)
      .then(response => response.data)
      .catch((error) => {
        console.info(error);
        return [];
      });
  }

  /**
   * Add a userId parameter to the query parameter object.
   */
  static addAsParam(params, username) {
    if (username !== undefined) {
      params.userId = username;
    }
    return params;
  }

}
