import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import RenderingHelper from '../RenderingHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';


export default class CbaStopWatch extends Component {

  state = {
    counterValue: moment(new Date(0, 0, 0, 0, 0, 0))
  }

  componentDidMount() {
    RenderingHelper.onMount(this);

    this.reinitialiseState();
    this.timer = this.startTimer();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.setCounterValueToState();
    RenderingHelper.onUnmount(this);
  }

  setCounterValueToState = () => {
    const { counterValue } = this.state;
    const pathState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setCounterValue(pathState, counterValue);
    ComponentStateHelper.registerState(this, pathState);
  }

  reinitialiseState = () => {
    const pathState = ComponentStateHelper.getState(this);
    const rawInitialCounterValue = StateAttributeAccess.extractCounterValue(pathState);
    const initialCounterValue = rawInitialCounterValue ? moment(rawInitialCounterValue) : null;

    if (initialCounterValue) {
      this.setState((prevState) => {
        const { counterValue } = prevState;
        counterValue.add(initialCounterValue.hours(), 'hours');
        counterValue.add(initialCounterValue.minutes(), 'minutes');
        counterValue.add(initialCounterValue.seconds(), 'seconds');

        return {
          counterValue
        }
      });
    }
  }

  startTimer = () => setInterval(() => {
    this.setState((prevState) => {
      const { counterValue } = prevState;

      counterValue.add(1, 'seconds');

      return {
        counterValue
      }
    })
  }, 1000)

  formatTime = (counterValue) => {
    const withHoursFormat = 'HH:mm:ss';
    const withoutHoursFormat = 'mm:ss';

    return !counterValue.hours() ? counterValue.format(withoutHoursFormat) : counterValue.format(withHoursFormat);
  }

  render() {
    const { runtime, config, path, orientation } = this.props;
    const { counterValue } = this.state;
    const pathState = ComponentStateHelper.getState(this);
    const pressedState = StateAttributeAccess.extractSelected(pathState);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, pressedState, orientation, runtime);

    return (
      <div
        className="centerVerticalHorizontal"
        title={CommonConfigHelper.buildTitle(config)}
        style={style}
        data-cba-id={config.pageEditId}
      >
        {this.formatTime(counterValue)}
      </div>
    )
  }

}

CbaStopWatch.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(PropTypesHelper.getStandardConfigPropTypes(false)).isRequired,
  orientation: PropTypes.string.isRequired
}
