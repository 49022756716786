import React from 'react';
import PropTypes from 'prop-types';

export default class DraftToolbarHighlightButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      style: {}
    }
  }

  UNSAFE_componentWillReceiveProps() {
    let offset = 0;

    if (window.getSelection().focusNode) {
      let lastSelectionElement = window.getSelection().focusNode.parentElement;
      let firstSelectionElement = window.getSelection().anchorNode.parentElement;

      if (firstSelectionElement.getBoundingClientRect().top > lastSelectionElement.getBoundingClientRect().top) {
        // swap first and last elements
        [firstSelectionElement, lastSelectionElement] = [lastSelectionElement, firstSelectionElement];
      }


      const lastSelectionElementHeight = lastSelectionElement.getBoundingClientRect().height;
      const buttonHeight = 28;
      const lastSelectionOffset = lastSelectionElement.getBoundingClientRect().top;
      const firstSelectionOffset = firstSelectionElement.getBoundingClientRect().top;

      offset = (lastSelectionOffset - firstSelectionOffset) + lastSelectionElementHeight + buttonHeight;
    }

    this.setState({
      style: {
        transform: `translateY(${offset}px)`
      }
    });
  }

  render() {
    const { doHighlight } = this.props;
    const { style } = this.state;
    return (
      <div style={style} className="toolbarInner" onMouseDown={e => e.preventDefault()}>
        <span onClick={() => doHighlight()}>
          Highlight
        </span>
      </div>
    )
  }

}

DraftToolbarHighlightButton.propTypes = {
  doHighlight: PropTypes.func.isRequired,
}
