/**
 * Helper methods to work with user defined ID paths.
 * 
 * See PathTranslationHelper for translation between user defined ID path and index path.
 */
export default class UserDefPathHelper {

  static forEachUserDefIdInPath(path, processor) {
    if (path === undefined || path.lastIndexOf('/id=') === -1) return;
    UserDefPathHelper.forEachUserDefIdInPath(UserDefPathHelper.dropUserDefIdFromPath(path), processor);
    const lastId = UserDefPathHelper.getLastUserDefIdFromPath(path);
    if (lastId !== undefined) {
      processor(lastId);
    }
  }

  static dropUserDefIdFromPath(path) {
    if (path === undefined) return undefined;

    const lastSlashIndex = path.lastIndexOf('/id=');
    if (lastSlashIndex === -1) {
      return undefined;
    }
    return path.substring(0, lastSlashIndex);
  }

  static getLastUserDefIdFromPath(path) {
    if (path === undefined) return undefined;

    const lastSlashIndex = path.lastIndexOf('/id=');
    if (lastSlashIndex === -1) {
      return undefined;
    }
    return path.substring(lastSlashIndex + 4);
  }

  static getPageAreaTypeFromPath(path) {
    if (path === undefined) return undefined;

    const pageAreaNameSlashIndex = path.indexOf('/pageAreaName=');
    return path.substring(14, pageAreaNameSlashIndex);
  }

  static getPageAreaNameFromPath(path) {
    if (path === undefined) return undefined;

    const pageAreaNameStartIndex = path.indexOf('/pageAreaName=') + 14;
    const firstIdSlashIndex = path.indexOf('/id=');
    if (firstIdSlashIndex === -1) {
      return path.substring(pageAreaNameStartIndex);
    }
    return path.substring(pageAreaNameStartIndex, firstIdSlashIndex);
  }

  static buildUserDefIdPath(pageAreaType, pageAreaName, firstUserDefId) {
    if (pageAreaType === undefined || pageAreaName === undefined || firstUserDefId === undefined) {
      return undefined;
    }
    return `/pageAreaType=${pageAreaType}/pageAreaName=${pageAreaName}/id=${firstUserDefId}`;
  }

  static appendUserDefIdToPath(path, userDefId) {
    if (path === undefined || userDefId === undefined) {
      return undefined;
    }
    return `${path}/id=${userDefId}`;
  }

}
