import React, { useRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { useDrop, useDrag } from 'react-dnd';
import CbaTreeCell from './CbaTreeCell';
import PropTypesHelper from '../../PropTypesHelper';
import DragAndDropHelper from '../../../config/DragAndDropHelper';
import TreeUtils from '../TreeUtils';
import TreeTraceHelper from '../TreeTraceHelper';

const CbaTreeRow = (props) => {
  const { row, columns, idx, height } = props;
  const { nodeTypes, nodeType, treePath, runtime, isReadOnly } = row;

  const { treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);

  const { currentNode, currentlyCutNodePath } = treeState

  const selectedClass = currentNode === row.path ? "selected" : "";
  const cutClass = currentlyCutNodePath === row.path ? "cut" : "";
  const reactGridClass = idx % 2 ? "react-grid-Row--even" : "react-grid-Row--odd";

  const width = columns.map(c => c.width).reduce((accum, reducer) => accum + reducer, 0);

  const rowStyle = {
    height,
    width
  }

  const nodeTypeInstance = TreeUtils.getNodeType(nodeTypes, nodeType);
  const treeAction = TreeUtils.getTreeDragDropAction(treePath, runtime);

  let hoverCount = 0;
  let firstHover;

  const rowRef = useRef(null);
  const [collectedPropsDrop, drop] = useDrop({
    accept: nodeTypes.map(ct => `${treePath}-${ct.name}`),
    hover: () => {
      hoverCount += 1;

      if (hoverCount === 1) {
        firstHover = new Date();
      }

      const deltaTime = new Date() - firstHover;

      if (deltaTime > 1400 && deltaTime < 1600) {
        row.onRowExpandClick(null, row, true);
      }
    },
    drop: (item) => {
      switch (treeAction) {
        case "DROP_COPY": TreeUtils.doTreeCopyAction(treePath, item.path, row.path, runtime); break;
        case "DROP_MOVE": TreeUtils.doTreeMoveAction(treePath, item.path, row.path, runtime); break;
        case "DROP_SWITCH":
        case "DROP_NONE": break;
        default: console.error("Invalid Tree drag&dropMode", treePath);
      }

      TreeTraceHelper.traceNodeAction("drop", treePath, row, runtime, undefined);
    },
    canDrop: item => TreeUtils.canNodeTypeDrop(nodeTypeInstance, treePath, runtime, item.nodeType, isReadOnly),
    collect: monitor => ({
      dragIsOver: monitor.isOver(),
      dragCanDrop: monitor.canDrop(),
    })
  });

  const [, drag] = useDrag({
    item: {
      path: row.path,
      type: `${treePath}-${nodeType}`,
      nodePathId: row.nodePathId,
      nodeType: row.nodeType,
      runtime
    },
    canDrag: () => TreeUtils.canNodeTypeDrag(nodeTypeInstance, treePath, runtime, isReadOnly),
    begin: () => {
      TreeTraceHelper.traceNodeAction("drag", treePath, row, runtime, undefined);
    }
  });

  drag(drop(rowRef));

  DragAndDropHelper.addCanDropStyleAttributes(rowStyle, collectedPropsDrop);

  return (
    <div
      ref={rowRef}
      style={rowStyle}
      className={` react-grid-Row ${selectedClass} ${cutClass} ${reactGridClass}`}
    >
      {columns.map((column, index) => (
        <CbaTreeCell key={column.key} column={column} rowData={row} idx={index} />
      ))}
    </div>
  )
}

CbaTreeRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(PropTypesHelper.getCbaTreeColumnConfig())).isRequired,
  row: PropTypes.shape(PropTypesHelper.getCbaTreeRowConfig()).isRequired,
  idx: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
}

/**
 * Passthrough component required because CbaTreeRow is a functional component and the library react-data-grid uses ref's on it
 * Refs are only supported on Components
 */
class CbaTreeRowWrapper extends PureComponent {

  render() {
    return <CbaTreeRow {...this.props} />
  }

}

export default CbaTreeRowWrapper;
