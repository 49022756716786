export default class EvaluatorHelper {

  static ANGLE_DEGREE = "Degree";

  static ANGLE_RADIAN = "Radian";

  static fak = (n) => {
    // console.log(n, isInt(n));
    if (n < 0 || n > 170) {
      return 1;
    } else if (!EvaluatorHelper.isInt(n)) {
      return 0;
    } else if (n <= 1) {
      return 1;
    } else {
      return n * EvaluatorHelper.fak(n - 1);
    }
  }

  static nthroot = (x, n) => {
    const negate = n % 2 === 1 && x < 0;
    if (negate) x = -x;
    const possible = x ** (1 / n);
    n = possible ** n;
    if (Math.abs(x - n) < 1 && ((x > 0) === (n > 0))) {
      return negate ? -possible : possible;
    }
    return undefined;
  }

  static isInt = n => n % 1 === 0;

  static isBigger = displayWidth => displayWidth > 10;

}
