import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import SelectGroupHelper from './SelectGroupHelper';
import RenderingHelper from './RenderingHelper';

export default class CbaPolygon extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.updateStateAndTriggerRendering = this.updateStateAndTriggerRendering.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState),
      event, runtime);

    const oldSelected = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    // Flip the selected state: 'yes' <-> 'no'
    this.updateStateAndTriggerRendering(pathState, oldSelected);

    SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);

    // send statemachine event depending on state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);

  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  updateStateAndTriggerRendering(pathState, oldSelected) {
    const { runtime, path } = this.props;
    StateAttributeAccess.setVisited(pathState, true);

    SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);
  }

  // accesibility event for tab navigation
  onKeyDown(event) {
    if (event.keyCode === 32) {
      // space key
      this.onClickHandler(event);
    }
  }


  render() {
    const { config, path, runtime, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);

    const renderingSelectedState = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    const polygonStyle = CommonConfigHelper.buildStyleSvg(pathState, config, renderingSelectedState, orientation, runtime);
    const pointsString = CommonConfigHelper.buildPointsString(config.bendPoints);
    const title = CommonConfigHelper.buildTitle(config);

    return (
      <polygon
        points={pointsString}
        style={polygonStyle}
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        tabIndex={config.tabIndex}
        onKeyDown={e => this.onKeyDown(e)}
      >
        {title !== "" && <title>{title}</title>}
      </polygon>
    );

  }


}


CbaPolygon.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        bendPoints: PropTypes.arrayOf(PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        }))
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
