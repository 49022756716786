/**
 * Provide the 'standard' sequence for a given list of tests.
 */
export default class StandardTaskSequencer {

  constructor(course, tests) {
    this.course = course;
    this.tests = tests;
    this.taskList = StandardTaskSequencer.buildTaskList(course, tests);

    const initialTask = this.taskList[0];
    if (initialTask === undefined) {
      console.error("No task found in standard task sequencer.");
      this.activeTestName = undefined;
      this.activeItemName = undefined;
      this.activeTaskName = undefined;
    } else {
      const { testName, itemName, taskName } = initialTask;
      this.activeTestName = testName;
      this.activeItemName = itemName;
      this.activeTaskName = taskName;
    }
  }

  // ---------- public API -----------------------------------------------------------------


  /**
   * Find the first task with the given name inside a specific test (i.e. we ignore the source item).
   * 
   * We return undefined if no matching task is available. 
   * We log the reason for not finding a task as error if the error log flag is set.
   */
  findFirstMatchingTaskInTest = (newTestName, newTaskName, withErrorLog) => {
    const newTest = StandardTaskSequencer.getTestForName(this.tests, newTestName);
    if (newTest === undefined) {
      if (withErrorLog) {
        console.error(`Test ${newTestName} is not defined in the test course`);
      }
      return undefined;
    }

    const newTaskEntry = newTest.taskCourse.find((value, index, theArray) => value.task === newTaskName);

    if (newTaskEntry === undefined) {
      if (withErrorLog) {
        console.error(`Task ${newTaskName} is not defined in test ${newTestName}`);
      }
      return undefined;
    }
    return ({
      testName: newTestName,
      itemName: newTaskEntry.item,
      taskName: newTaskEntry.task
    });
  }

  /**
   * Get the task info for the next task in our task sequence.
   * 
   * The method silently returns undefined if not next taskk is available.
   */
  getNextTaskInfo = () => (this.nextTaskAvailable() ? this.taskList[this.getCurrentTaskIndex() + 1] : undefined);

  /**
   * Get the task info for the previous task in our task sequence.
   * 
   * The method silently returns undefined if not previous taskk is available.
   */
  getPreviousTaskInfo = () => (this.previousTaskAvailable() ? this.taskList[this.getCurrentTaskIndex() - 1] : undefined);

  /**
   * Could we currently perform a switch to the next task?
   */
  nextTaskAvailable = () => this.taskList.length > this.getCurrentTaskIndex() + 1;

  /**
   * Could we currently perform a switch to the previous task?
   */
  previousTaskAvailable = () => this.getCurrentTaskIndex() > 0;

  /**
   * Switch the current task in our internal structures.
   */
  switchCurrentTask = (test, item, task) => {
    this.activeTestName = test;
    this.activeItemName = item;
    this.activeTaskName = task;
  }

  /**
   * Get info about the currently active task. 
   */
  getCurrentTaskInfo = () => ({
    testName: this.activeTestName,
    itemName: this.activeItemName,
    taskName: this.activeTaskName
  });

  /**
   * Get the course/tests configuration.
   */
  getConfigurationInfo = () => ({
    course: this.course,
    tests: this.tests
  });

  // -------------- private stuff ----------------------------------------------------------------------------------------

  /**
   * Get the index of the currently active task in our task list.
   */
  getCurrentTaskIndex = () => {
    const result = StandardTaskSequencer.getIndexForTask(this.taskList, this.activeTestName, this.activeItemName, this.activeTaskName);
    if (result < 0) {
      console.error("Cannot find task in task list");
      return undefined;
    }
    return result;
  }

  /**
   * Get the index of the specified task in the given task list.
   */
  static getIndexForTask(taskList, testName, itemName, taskName) {
    return taskList.findIndex((entry, index, all) => entry.testName === testName && entry.itemName === itemName && entry.taskName === taskName);
  }

  /**
   * Get the test configuration object for the specified test.
   * 
   * The method returns the test configuration object, i.e. an object with attributes 
   *  - name
   *  - items
   *  - taskCourse
   */
  static getTestForName(tests, testName) {
    const result = tests.find((value, index, theArray) => value.name === testName);
    if (result === undefined) {
      console.error(`Could not find test ${testName}`);
    }
    return result;
  }

  /**
   * Build a list of all tasks in the given tests course.
   * 
   * The method returns an array of objects where each object describes one task:
   * - testName
   * - itemName
   * - taskName
   */
  static buildTaskList(testsCourse, tests) {
    const result = [];
    testsCourse.forEach((testName) => {
      const test = StandardTaskSequencer.getTestForName(tests, testName);
      test.taskCourse.forEach((taskEntry) => {
        result.push({
          testName,
          itemName: taskEntry.item,
          taskName: taskEntry.task
        })
      })
    });
    return result;
  }


}
