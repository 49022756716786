import IndexPathHelper from "../../state/IndexPathHelper";
import ComponentStateHelper from "../../state/ComponentStateHelper";
import StateAttributeAccess from "../../state/StateAttributeAccess";

/**
 * Helper methods for table specific actions.
 */
export default class TableHelper {

  /**
   * This method will be used to handle table navigation via arrows. 
   * More specific will trigger table cell auto focus events.
   * 
   * @param {*} e The keyboard event received.
   * @param {*} runtime The common runtime context structure.
   * @param {Object} config The common config structure.
   * @param {boolean} isInEditMode Flag to know that we are in a table cell.
   */
  static handleKeyDown(e, runtime, config, tablePath, isInEditMode) {
    e = e || window.event;
    const keyCode = e.keyCode || e.which;
    const arrow = {
      left: 37,
      up: 38,
      right: 39,
      down: 40
    };

    if (isInEditMode) {
      const { row, column } = config;
      if (e.ctrlKey) {
        switch (keyCode) {
          case arrow.left:
            // TODO: extract this in a method to avoid redundance 
            TableHelper.emitAutoFocusAndPreventDefault(runtime, tablePath, row, column - 1, e);
            break;
          case arrow.right:
            TableHelper.emitAutoFocusAndPreventDefault(runtime, tablePath, row, column + 1, e);
            break;
          default:
            break;
        }
      }

      switch (keyCode) {
        case arrow.up:
          TableHelper.emitAutoFocusAndPreventDefault(runtime, tablePath, row - 1, column, e);
          break;
        case arrow.down:
          TableHelper.emitAutoFocusAndPreventDefault(runtime, tablePath, row + 1, column, e);
          break;
        default:
          break;
      }
    }
  }

  /**
   * This method will emit an autoFocus event and stop propagation.
   * 
   * @param {*} runtime The common runtime context structure.
   * @param {*} parentTableUserDefId The user defined id of the table.
   * @param {*} row Row to focus
   * @param {*} column Columns to focus
   * @param {*} e 
   */
  static emitAutoFocusAndPreventDefault(runtime, tablePath, row, column, e) {
    runtime.eventEmitter.emit(`${tablePath}-autoFocus`, row, column);
    e.preventDefault();
  }

  /**
   * This method will be used to cleanup the last selection when a new cell get focus.
   * 
   * @param {*} runtime The common runtime context structure.
   * @param {*} config The common config structure.
   * @param {*} isInEditMode Flag to know that we are in a table cell.
   */
  static handleCellFocus(runtime, tablePath, isInEditMode) {
    if (isInEditMode) {
      runtime.eventEmitter.emit(`${tablePath}-removeOldSelection`);
    }
  }

  static isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  static isFloat(n) {
    n = Number.parseFloat(n);
    if (n === undefined || Number.isNaN(n)) {
      return false;
    }
    return Number(n) === n && n % 1 !== 0;
  }

  /**
   * Returns the table index path.
   * @param {*} componentPath The path for edit mode component of a spreadsheet table cell 
   * or the path of a RichTextField child for standard table cell.
   *      
   */
  static buildTablePath(componentPath) {
    const tableCellPath = IndexPathHelper.dropIndexFromPageSegment(componentPath);
    return IndexPathHelper.dropIndexFromPageSegment(tableCellPath);
  }

  static isFormula(value) {
    return value !== undefined && value.slice(0, 1) === '=';
  }

  /**
   * Returns true if the current selected cell has same row/column as the old selected one.
   * @param {*} tableUserDefIdPath The user defined id path of the table.
   * @param {*} runtime The common runtime context structure.
   */
  static isOldSelected(tableUserDefIdPath, runtime) {
    const currentSelected = ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractSelectedCell, tableUserDefIdPath, runtime);
    const oldSelected = ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractOldSelectedCell, tableUserDefIdPath, runtime);
    return (currentSelected.row === oldSelected.row && currentSelected.column === oldSelected.column);
  }

}
