import { useDrag, useDrop } from 'react-dnd';
import CommonConfigHelper from './CommonConfigHelper';
import PathTranslationHelper from '../state/PathTranslationHelper';
import TermEvaluator from '../eval/TermEvaluator';
import StateAttributeAccess from '../state/StateAttributeAccess';
import RenderingHelper from '../components/RenderingHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import UserDefPathHelper from '../state/UserDefPathHelper';


/**
 * All available drag&drop-content 'items' that we can move around via drag&drop.
 */
export const DndItemTypes = {
  VALUE_DISPLAY: 'valueDisplay',
}

/**
 * Helper methods used for drag and drop event handling.
 */
export default class DragAndDropHelper {

  // -------- public API ----------------------------------------------------------------


  /**
   * Add the 'drag' part of a drag&drop functionality to a component renderer.
   * 
   * @param {*} config The config structure of the component from the page configuration description.
   * @param {String} path The index path of the component's state in the ComponentStateManager.
   * @param {*} runtime The common runtime context structure.
   * @param {String} itemType The type of the dragged item structure.
   * @param {*} previewData The data that should be available for the preview in the drag layer.
   * @returns The array returned by the useDrag call.
   */
  static addDrag(config, path, runtime, itemType, previewData) {
    return useDrag({
      item: {
        type: itemType
      },
      begin: (monitor) => {
        const dragAndDropConfig = CommonConfigHelper.getDragAndDrop(config);
        const { sender: senderConfig } = dragAndDropConfig;
        const { data, traceType, event: statemachineEvent } = senderConfig;
        const dragAndDropData = {
          type: itemType,
          previewData,
          indexPath: path,
          senderData: data,
          traceType,
          startPosition: monitor.getInitialClientOffset()
        };
        DragAndDropHelper.triggerStatemachineEvent(statemachineEvent, runtime);
        return dragAndDropData;
      },
      canDrag: monitor => DragAndDropHelper.isDragAndDropSender({
        config, runtime, path
      }),
      collect: monitor => ({
        idDragging: monitor.isDragging()
      })
    })
  }

  /**
   * Add the 'drop' part of a drag&drop functionality to a component renderer.
   * 
   * @param {*} config The config structure of the component from the page configuration description.
   * @param {String} path The index path of the component's state in the ComponentStateManager.
   * @param {*} runtime The common runtime context structure.
   * @param {String} itemType The item type that we should accept as drop content.
   * @param {bool} dropsOfMyChildrenOnly Should we restrict drops to children of the drop target component?
   * @returns The array returned by the useDrop call.
   */
  static addDrop(config, path, runtime, itemType, dropsOfMyChildrenOnly) {
    return useDrop({
      accept: itemType,
      drop: (item, monitor) => {
        const transferredData = item;
        const dragAndDropConfig = CommonConfigHelper.getDragAndDrop(config);
        const { receiver: receiverConfig } = dragAndDropConfig;
        const { actionTerms, traceType, event: statemachineEvent } = receiverConfig;
        const endPosition = monitor.getClientOffset();
        const lastEvaluationResult = DragAndDropHelper.evaluateActionTerms(actionTerms, transferredData, runtime);
        DragAndDropHelper.relocateIfNecessary(lastEvaluationResult, endPosition, transferredData, runtime);
        DragAndDropHelper.triggerStatemachineEvent(statemachineEvent, runtime);
        DragAndDropHelper.logAction(transferredData, path, endPosition, traceType, lastEvaluationResult, runtime);
      },
      canDrop: (item, monitor) => {
        if (dropsOfMyChildrenOnly) {
          const transferredData = item;
          const senderIndexPath = transferredData.indexPath;
          if (path !== IndexPathHelper.dropIndexFromPageSegment(senderIndexPath)) {
            return false;
          }
        }
        return DragAndDropHelper.isDragAndDropReceiver({
          config, runtime, path
        });
      },
      collect: monitor => (
        {
          dragIsOver: monitor.isOver({
            shallow: true
          }),
          dragCanDrop: monitor.canDrop()
        }
      )
    });
  }

  /**
   * Add attributes to the given style configuration that give feedback
   * highlighting a component instance as eligible drop target.
   * 
   * @param {*} style The style attributes list to be modified.
   * @param {*} props The props of the component instance.
   */
  static addCanDropStyleAttributes(style, props) {
    const { dragIsOver, dragCanDrop } = props;
    if (dragIsOver && dragCanDrop) {
      CommonConfigHelper.setStyleAttribute(style, "boxShadow", "0 0 8px 0 rgba(0, 0, 0, 0.5) inset");
    }
  }

  /**
   * Add attributes to the given style configuration that give feedback
   * highlighting a component instance as current drag source.
   * 
   * @param {*} style The style attributes list to be modified.
   * @param {*} props The props of the component instance.
   */
  static addIsDraggingStyleAttributes(style, props) {
    const { isDragging } = props;
    if (isDragging) {
      CommonConfigHelper.setStyleAttribute(style, "boxShadow", "0 0 8px 0 rgba(0, 0, 0, 0.5) inset");
    }
  }


  // -------- private stuff ----------------------------------------------------------------

  /**
   * Can the component instance start a drag currently?
   * 
   * @param {*} props The full props structure of the component instance.
   */
  static isDragAndDropSender(props) {
    const { config, runtime, path } = props;

    // The TermEvaluator operator might declare a component to be a sender 
    // even though it has no proper drag&drop configuration.
    // -> We check the necessary configuration in any case:
    const dragAndDropInConfig = CommonConfigHelper.getDragAndDrop(config);
    if (dragAndDropInConfig === undefined) {
      return false;
    }

    const { sender: senderInConfig } = dragAndDropInConfig;
    if (senderInConfig === undefined) {
      return false;
    }

    // Drag and drop participation can be changed by the TermEvaluator operators.
    const pathState = runtime.componentStateManager.findOrBuildStateForPathId(path, runtime);
    const dragAndDropInState = StateAttributeAccess.extractDragAndDrop(pathState);
    return dragAndDropInState.isSender;

  }

  /**
   * Can the component instance receive a drop currently?
   * 
   * @param {*} props The full props structure of the component instance.
   */
  static isDragAndDropReceiver(props) {
    const { config, runtime, path } = props;

    // The TermEvaluator operator might declare a component to be a receiver 
    // even though it has no proper drag&drop configuration.
    // -> We check the necessary configuration in any case:
    const dragAndDropInConfig = CommonConfigHelper.getDragAndDrop(config);
    if (dragAndDropInConfig === undefined) {
      return false;
    }

    const { receiver: receiverInConfig } = dragAndDropInConfig;
    if (receiverInConfig === undefined) {
      return false;
    }

    // Drag and drop participation can be changed by the TermEvaluator operators.
    const pathState = runtime.componentStateManager.findOrBuildStateForPathId(path, runtime);
    const dragAndDropInState = StateAttributeAccess.extractDragAndDrop(pathState);
    return dragAndDropInState.isReceiver;

  }


  static evaluateActionTerms(actionTerms, transferredData, runtime) {
    let lastEvaluationResult;
    if (actionTerms !== undefined) {
      actionTerms.forEach((term, index) => {
        lastEvaluationResult = TermEvaluator.evaluateTerm(term, runtime, [transferredData], `drag&drop[${index}]`);
      });
    }
    return lastEvaluationResult;
  }

  static relocateIfNecessary(lastEvaluationResult, endPosition, transferredData, runtime) {
    if (lastEvaluationResult !== 'relocate') {
      return;
    }
    const { startPosition } = transferredData;
    if (endPosition === undefined || endPosition === null || startPosition === undefined || startPosition === null) {
      console.warn(`Drag and drop without proper coordinates! -> We do not move the component instance.`);
      return;
    }
    const translationX = endPosition.x - startPosition.x;
    const translationY = endPosition.y - startPosition.y;
    const { componentStateManager } = runtime;
    const senderIndexPath = transferredData.indexPath;
    const pathState = componentStateManager.findOrBuildStateForPathId(senderIndexPath, runtime);
    const position = StateAttributeAccess.extractPosition(pathState);
    position.x += translationX;
    position.y += translationY;
    StateAttributeAccess.setPosition(pathState, position);
    componentStateManager.registerStateByPathId(senderIndexPath, pathState);
    RenderingHelper.triggerRenderingViaPath(senderIndexPath, runtime);
  }

  static triggerStatemachineEvent(statemachineEvent, runtime) {
    if (statemachineEvent !== undefined) {
      runtime.statemachinesManager.triggerEvent(statemachineEvent);
    }
  }

  static logAction(transferredData, indexPath, endPosition, traceType, operation, runtime) {
    const timestamp = new Date();
    const receiverUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(indexPath, runtime);
    const senderUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(transferredData.indexPath, runtime);

    runtime.incidentsAccumulator.userInteraction(timestamp.getTime(), undefined);
    runtime.traceLogBuffer.reportEvent('DragAndDropReceive', timestamp, {
      senderIndexPath: transferredData.indexPath,
      senderUserDefIdPath,
      senderUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(senderUserDefIdPath),
      receiverIndexPath: indexPath,
      receiverUserDefIdPath,
      receiverUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(receiverUserDefIdPath),
      startPosition: transferredData.startPosition,
      endPosition,
      sendingType: transferredData.traceType,
      receivingType: traceType,
      operation
    });

  }

}
