import React from 'react';
import PropTypes from 'prop-types';

import './WebTab.css';

const WebTab = (props) => {
  const { tabName, closeTab, isActive, onClick, maxWidth, icon } = props;

  const webTabClass = `webTab ${isActive ? "active" : ""}`;
  const webTabStyle = {
    maxWidth
  };

  return (
    <div title={tabName} className={webTabClass} onClick={onClick} style={webTabStyle}>
      {icon === undefined ? undefined : <img src={icon} alt="" height="100%" width="auto" />}
      <span>{tabName}</span>
      <i onClick={closeTab} />
    </div>
  )
}

WebTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  closeTab: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
  icon: PropTypes.string
}

WebTab.defaultProps = {
  maxWidth: 200,
  icon: undefined
};

export default WebTab;
