import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import PropTypesHelper from './PropTypesHelper';
import RenderingHelper from './RenderingHelper';
import InputComponent from './InputComponent';

export default class CbaInputField extends Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  focus = () => {
    this.inputRef.current.focus();
  }

  render() {
    const { config, path, runtime, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selected = StateAttributeAccess.extractSelected(pathState);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, selected, orientation, runtime);

    // override browser default padding value for textarea: 
    if (config.position.padding === undefined) {
      CommonConfigHelper.setStyleAttribute(style, "padding", "0px");
    }

    CommonConfigHelper.setStyleAttribute(style, "resize", "none");

    return (
      <textarea
        style={style}
        data-cba-id={config.pageEditId}
        ref={this.inputRef}
        title={CommonConfigHelper.buildTitle(config)}
        disabled={StateAttributeAccess.extractDisabled(pathState)}
        value={StateAttributeAccess.extractTextValue(pathState)}
        onClick={event => InputComponent.onClickHandler(this, event)}
        onContextMenu={event => InputComponent.onContextMenuHandler(this, event)}
        onChange={event => InputComponent.onChangeHandler(this, event, 'InputFieldModified', 'InputField_onChange')}
        onSelect={event => InputComponent.onSelectionHandler(this, event)}
        onFocus={event => InputComponent.onFocusHandler(this, event)}
        onBlur={event => InputComponent.onBlurHandler(this, event)}
        onCut={event => InputComponent.onCutCopyPaste(this, event)}
        onCopy={event => InputComponent.onCutCopyPaste(this, event)}
        onPaste={event => InputComponent.onCutCopyPaste(this, event)}
        readOnly={config.readOnly}
        tabIndex={config.tabIndex}
        className="CbaInputField"
      />
    );
  }

}


CbaInputField.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        validationPattern: PropTypes.string,
        sourceInputPath: PropTypes.string
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired,
}
