import moment from 'moment';
import ifvisible from 'ifvisible';

const TYPES = {
  pageBlur: 'PAGE_HIDDEN',
  pageFocus: 'PAGE_SHOWN',
  enterFullscreen: 'ENTER_FULLSCREEN',
  exitFullscreen: 'EXIT_FULLSCREEN'
};

/**
 * Observe events related to item visibility.
 * 
 * We tried to detect full screen switches via the resize events initially 
 * and might follow this path again in the future. Therefore this class also handles 
 * creation of full screen switch trace log entries. 
 */
export default class PageEventsObserver {

  /**
   * constructor initialises the lastVisibleTime and lastFullScreenTime variables to the current time.
   * 
   * @param {*} runtime Runtime needed to extract the reportEvent Function from the traceLogBuffer
   */
  constructor(runtime) {
    this.lastVisibilityTime = moment();
    this.lastFullScreenTime = moment();
    this.reportEvent = runtime.traceLogBuffer.reportEvent;
  }

  // private
  lastVisibilityTime = null;

  lastFullScreenTime = null;

  isFullScreen = null;

  handleVisibility = type => () => {
    const newVisibilitytime = this.getMoment();
    const timeSpent = this.computeTimeDifference(newVisibilitytime, this.lastVisibilityTime);

    this.traceAction(type, timeSpent);
    this.lastVisibilityTime = newVisibilitytime;
  }


  traceAction = (type, duration) => {
    switch (type) {
      case TYPES.pageBlur: this.reportEvent("ApplicationVisibility", new Date(), this.buildTraceDetails('pageHidden', duration)); break;
      case TYPES.pageFocus: this.reportEvent("ApplicationVisibility", new Date(), this.buildTraceDetails('pageShown', duration)); break;
      case TYPES.enterFullscreen: this.reportEvent("ApplicationFullScreen", new Date(), this.buildTraceDetails('enterFullscreen', duration)); break;
      case TYPES.exitFullscreen: this.reportEvent("ApplicationFullScreen", new Date(), this.buildTraceDetails('exitFullscreen', duration)); break;
      default: console.warn("Wrong type provided in PageEventsObserverssss");
    }
  }

  computeTimeDifference = (time1, time2) => {
    const duration = moment.duration(time1.diff(time2));
    return duration.asSeconds();
  }

  buildTraceDetails = (type, duration) => ({
    type,
    alternateStateDuration: duration
  })

  // public API

  /**
   * Function initialises the visibility observers
   */
  initObserver() {
    ifvisible.on('blur', this.handleVisibility(TYPES.pageBlur));
    ifvisible.on('focus', this.handleVisibility(TYPES.pageFocus));
  }

  /**
   * Create a full screen switch trace log entry if necessary.
   * 
   * The method manages the 'duration' attribute in the trace log entry.
   */
  handleFullScreen = (isFullscreenNow) => {
    const newFullScreenTime = this.getMoment();
    const timeSpent = this.computeTimeDifference(newFullScreenTime, this.lastFullScreenTime);

    if (this.isFullScreen !== isFullscreenNow) {
      const type = isFullscreenNow ? TYPES.enterFullscreen : TYPES.exitFullscreen;

      this.traceAction(type, timeSpent)

      this.isFullScreen = isFullscreenNow;
      this.lastFullScreenTime = newFullScreenTime;
    }
  }


  /**
   * hook in method for testing purposes
   */
  getMoment = () => moment();

}
