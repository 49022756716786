import React from "react";
import PropTypes from 'prop-types';
import Dialog from "react-dialog";
import ModalManager from "./ModalManager";

const ModalDialog = (props) => {
  const width = 600;
  const height = 500;
  const centerPosition = {
    x: window.innerWidth / 2 - width/2,
    y: window.innerHeight / 2 - height/2
  }
  const { isOpen, currentModal, currentModalTitle, closeDialog } = props;
  return (
    <div className="cba-modal-container cba-modal cba-system-modal">
      {isOpen && (
        <Dialog
          title={currentModalTitle}
          modal
          isResizable
          isDraggable
          hasCloseIcon
          closeOnEscape
          width={width}
          height={height}
          position={centerPosition}
          onClose={closeDialog}
        >
          <ModalManager currentModal={currentModal} {...props} />
        </Dialog>
      )}
    </div>
  );
};

export default ModalDialog;

ModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentModal: PropTypes.string.isRequired,
  currentModalTitle: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired
}
