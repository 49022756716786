import axios from 'axios';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import StringUtils from '../utils/StringUtils';

/**
 * Manage the transfer of user session related result data (trace log, media recordings) to the server. 
 * 
 * We support transmission of user result data entries 
 *  - via HTTP PUT calls or
 *  - window.postMessage events sent to the given target domain origin or
 *  - writing the user result data to the console.
 */
export default class UserDataUploader {

  constructor(userDataBuffer, buildUserDataPostContentCallback, dataType) {
    this.dataType = dataType;
    this.userDataBuffer = userDataBuffer;
    this.buildUserDataPostContentCallback = buildUserDataPostContentCallback;

    this.userDataEntriesList = [];
    this.lastSentFailCount = 0;
    this.isMaxFailCountError = false;
    this.notificationToaster = notify;

    this.transmitterPromise = undefined;
    this.transmitterPromiseIsResolved = false;

    this.sessionContext = {};

    this.transmissionChannel = 'console';
    this.targetWindowType = undefined;
    this.domainUri = undefined;
    this.axiosInstance = undefined;
    this.transmitCallback = undefined;

    this.transmitInterval = undefined;
    this.currentTimer = undefined;

  }

  // ------- public API -----------------------------------------------------------------------------

  /**
   * Set the session context that we will use in the meta data of our transmissions to the server.
   * 
   * The session context consists of 
   * - the session id
   * - the user name
   * - the timestamp of the user login
   */
  setSessionContext = (sessionId, username, loginTimestamp) => {
    this.sessionContext = {
      sessionId,
      username,
      loginTimestamp
    }
  }

  /**
   * Get the session context that we use in the meta data of our transmissions to the server.
   */
  getSessionContext = () => this.sessionContext;

  /**
   * Start the scheduled automatic transmissions. 
   */
  startAutomaticTransmissions = () => {
    if (this.transmitInterval > 0) {
      this.setNewTimer();
    } else {
      this.setImmediateCallback();
    }
  }

  /**
   * Stop the scheduled automatic transmissions.
   * 
   * We will run a final transmission after cancelling the scheduled one.
   */
  stopAutomaticTransmissions = () => {
    this.stopOldTimer();
    this.dropImmediateCallback();
  }

  /**
   * Grab all userData entries from user data buffer and put them onto our internal list of entries to transmit.
   * Start transmission of all entries on our internal list. 
   * 
   * The method will not start another transmission attempt if the maximum failures threshold is reached. 
   * The method triggers an asynchronous transmission: It returns immediately after initiating the transmission
   * and will not wait for the transmission to be completed.
   * 
   */
  collectEntriesAndTriggerTransmission = () => {
    if (this.transmitterPromise === undefined || this.transmitterPromiseIsResolved === true) {
      this.transmitterPromiseIsResolved = false;
      this.transmitterPromise = this.collectEntriesAndBuildTransmitPromise().then(() => { this.transmitterPromiseIsResolved = true; });
    } else {
      this.transmitterPromise = this.transmitterPromise.then(() => {
        this.transmitterPromiseIsResolved = false;
        return this.collectEntriesAndBuildTransmitPromise().then(() => { this.transmitterPromiseIsResolved = true; });
      });
    }
  }

  /**
   * Grab all user data entries from user data buffer and put them onto our internal list of entries to transmit.
   * Return a promise that transmits all entries on our internal list and clips the internal list accordingly. 
   * 
   * The method will return a resolved promise if the maximum failures threshold is reached or no user data entries are to be transmitted. 
   * 
   */
  collectEntriesAndBuildTransmitPromise = () => {
    console.log(`Sending ${this.dataType} entries via ${this.transmissionChannel} channel...`);

    const { notificationToaster } = this;

    // Grab all user data entries from user data buffer and add them to our internal list of user data entries to be sent to the server.
    this.addUserDataEntries(this.userDataBuffer.popEntries());

    if (this.getUserDataEntriesList().length === 0) {
      // No user data entries pending -> do nothing this time.
      return Promise.resolve();
    }

    if (!this.shouldTryAgainToSend()) {
      // Maximum number of failed transmission attempts reached -> Display error message if we did not do so already.

      if (!this.isMaxFailCountErrorDisplayed()) {
        const notifyErrorMsg = `Max tries of error send occurred on the ${this.getFailCount()}th time and will not try again.`;
        console.log('postTrace: ', notifyErrorMsg);
        this.setMaxFailCountErrorDisplayed();

        // to not be overridden with earlier toasts, just delay for 3 seconds the output of the toast
        setTimeout(() => notificationToaster.show(notifyErrorMsg, 'error'), 3000);
      }
      return Promise.resolve();
    }

    // Build message to transmit.
    const userDataRequestBody = this.buildUserDataPostContentCallback(moment().format(), this.getSessionContext(), this.getUserDataEntriesList());
    // Return a promise that transmits the messages and clips our pending entries list:
    return this.getTransmittingPromise(userDataRequestBody).then((response) => {
      this.updateUserDataEntriesListAfterSuccessfulSend(response.noOfEntriesTransmitted);
    }, (error) => {
      console.log(error)
      this.increaseNextSentFailCount();
      notificationToaster.show(`Error sending ${this.dataType}s! Fail count:${this.getFailCount()}`, 'warning');
    })
  }

  /**
   * Set our transmission channel to 'postMessage'. 
   * 
   * @param {String} targetWindowType The type of reference to the window to post messages to: 'parent' (for IFRAME parent), 'opener' (for the window that spawned our window), 'self' (our own window)
   * @param {String} domainUri The domain URI we should use as target origin to post messages to.
   */
  setPostMessageTransmissionChannel = (targetWindowType, domainUri, interval) => {
    this.transmissionChannel = 'postMessage';
    this.targetWindowType = targetWindowType;
    this.domainUri = domainUri;
    this.axiosInstance = undefined;
    this.transmitCallback = undefined;
    this.setTransmitInterval(interval);
  }

  /**
   * Set our transmission channel to 'callback'.
   */
  setCallbackTransmissionChannel = (transmitCallback, interval) => {
    this.transmissionChannel = 'callback';
    this.targetWindowType = undefined;
    this.domainUri = undefined;
    this.axiosInstance = undefined;
    this.transmitCallback = transmitCallback;
    this.setTransmitInterval(interval);
  }


  /**
   * Set our transmission channel to 'console'.
   */
  setConsoleTransmissionChannel = (interval) => {
    this.transmissionChannel = 'console';
    this.targetWindowType = undefined;
    this.domainUri = undefined;
    this.axiosInstance = undefined;
    this.transmitCallback = undefined;
    this.setTransmitInterval(interval);
  }

  /**
   * Set our transmission channel to 'http'. 
   * 
   * @param {*} transmitUrl The target URL to put user data entries to.
   */
  setHttpTransmissionChannel = (transmitUrl, interval, httpTimeout) => {
    this.transmissionChannel = 'http';
    this.targetWindowType = undefined;
    this.domainUri = undefined;

    this.axiosInstance = axios.create({
      baseURL: transmitUrl,
      timeout: httpTimeout === undefined ? 30000 : httpTimeout,
      headers: {
        'Content-Type': 'application/json'
      },
    });

    this.transmitCallback = undefined;
    this.setTransmitInterval(interval);
  }


  /**
   * Set a non-standard notification toaster mechanism. 
   * 
   * This is useful for testing without a GUI where the standard notify toaster is not available. 
   * The given toaster must provide a method show(messageString, levelString)
   * 
   * @param {*} toaster A callback that we use to send notifications to the user. 
   */
  setNotificationToaster = (toaster) => {
    this.notificationToaster = toaster;
  }

  // ------- private stuff --------------------------------------------------------------------------

  /**
   * Set our transmit interval. 
   * 
   * If necessary we stop and restart our automatic transmissions to take a new transmit interval into account.
   */
  setTransmitInterval = (newTransmitInterval) => {
    if (newTransmitInterval !== this.transmitInterval) {
      this.stopOldTimer();
      this.dropImmediateCallback();
      this.transmitInterval = newTransmitInterval;
      if (this.transmitInterval > 0) {
        this.setNewTimer();
      } else {
        this.setImmediateCallback();
      }
    }
  }

  setImmediateCallback = () => {
    this.userDataBuffer.setNewEntryListener(this.collectEntriesAndTriggerTransmission);
  }

  dropImmediateCallback = () => {
    if (this.traceLogBuffer !== undefined) {
      this.traceLogBuffer.setNewEntryListener(undefined);
    }
  }

  stopOldTimer = () => {
    if (this.currentTimer !== undefined) {
      clearInterval(this.currentTimer);
      this.currentTimer = undefined;
      this.collectEntriesAndTriggerTransmission();
    }
  }

  setNewTimer = () => {
    this.stopOldTimer();
    this.currentTimer = setInterval(() => this.collectEntriesAndTriggerTransmission(), this.transmitInterval);
  }

  /**
   *  Increase the transmission failures counter.
   */
  increaseNextSentFailCount = () => {
    this.lastSentFailCount += 1;
  }

  /**
   * Returns the number of failed transmission attempts.
   */
  getFailCount = () => this.lastSentFailCount;


  isMaxFailCountErrorDisplayed = () => this.isMaxFailCountError;


  setMaxFailCountErrorDisplayed = () => {
    this.isMaxFailCountError = true;
  }

  /**
   * Returns true if the number of failed transmission attempts has not yet reached our threshold.
   */
  shouldTryAgainToSend = () => this.getFailCount() < 10;

  /**
   * Retuns our internal list of user data entries to be sent to the server.
   */
  getUserDataEntriesList = () => this.userDataEntriesList;

  /**
   * Add the given user data entries to our internal list of entries to be sent to the server.
   */
  addUserDataEntries = (newUserDataEntries) => {
    if (newUserDataEntries.length > 0) {
      this.userDataEntriesList = this.userDataEntriesList.concat(newUserDataEntries);
    }
  }

  /**
   * Update our internal structures after a successful transmission of data to the server:
   * 
   * Remove the transmitted user data entries from our internal entries list.
   * Reset the last failure count to zero.
   * 
   * @param noOfTransmittedEntries The number of entries that were transmitted by the successful transmission.
   */
  updateUserDataEntriesListAfterSuccessfulSend = (noOfTransmittedEntries) => {
    this.userDataEntriesList = this.userDataEntriesList.filter((data, index) => index > noOfTransmittedEntries);
    this.lastSentFailCount = 0;
  }

  /**
   * Get the appropriate Promise to transmit our user data data.
   */
  getTransmittingPromise = (dataToTransmit) => {
    switch (this.transmissionChannel) {
      case 'postMessage':
        return UserDataUploader.transmitViaPostMessage(dataToTransmit, this.targetWindowType, this.domainUri, this);
      case 'callback':
        return UserDataUploader.transmitViaCallback(dataToTransmit, this.transmitCallback, this);
      case 'console':
        return UserDataUploader.transmitViaConsole(dataToTransmit, this);
      case 'http':
        return UserDataUploader.transmitViaHttp(dataToTransmit, this.axiosInstance, this)
      default:
        return UserDataUploader.transmitViaConsole(dataToTransmit, this);
    }
  }

  /**
   * Initiate transmission of the given user data entries bucket via a postMessage event.
   * 
   * The method returns a Promise that will return a result status object once the transmission is complete or has failed. 
   * If the transmission was successful the result status object contains the number of entries included in the 
   * user data entries bucket that was transferred.
   * 
   * @param {*} dataToTransmit The bucket of user data entries with wrapping meta data ready to be sent to the server.
   * @param {String} targetWindowType The type of reference to the window to post messages to: 'parent' (for IFRAME parent), 'opener' (for the window that spawned our window), 'self' (our own window, useful for testing only)
   * @param {String} targetOriginUri The domain URI we should use as target origin to post messages to.
   * @returns A Promise that will return a result object once the transmission is complete or has failed.
   */
  static transmitViaPostMessage(dataToTransmit, targetWindowType, targetOriginUri, uploaderInstance) {
    return new Promise((resolve, reject) => {
      const messageToSend = {
        eventType: `${uploaderInstance.dataType}Transmission`,
        [`${uploaderInstance.dataType}Data`]: dataToTransmit
      }
      const targetWindow = UserDataUploader.getTargetWindow(targetWindowType)
      if (targetWindow === undefined) {
        console.error(`Cannot find target window for target window type ${targetWindowType}`);
        throw new Error(`Cannot deliver ${uploaderInstance.dataType}s via post message.`);
      }
      try {
        targetWindow.postMessage(JSON.stringify(messageToSend), targetOriginUri);
        resolve({
          noOfEntriesTransmitted: dataToTransmit[`${UserDataUploader.getDataType(uploaderInstance.dataType)}EntriesList`].length
        });
      } catch (e) {
        console.error(`Cannot deliver ${uploaderInstance.dataType}s via postMessage`, e);
        throw new Error(`Cannot deliver ${uploaderInstance.dataType}s via post message.`);
      }
    });
  }

  /**
   * Get the target window specified by the given target window type.
   * 
   * @param {String} targetWindowType The type of reference to the window to post messages to: 'parent' (for IFRAME parent), 'opener' (for the window that spawned our window), 'self' (our own window, useful for testing only)
   */
  static getTargetWindow(targetWindowType) {
    switch (targetWindowType) {
      case 'parent':
        return window.parent;
      case 'opener':
        return window.opener;
      case 'self':
        return window;
      default:
        console.error('Unknown target window type!')
        return undefined;
    }
  }

  /**
   * Initiate transmission of the given user data entries bucket via the externally given transmission callback.
   * 
   * The method returns a Promise that will return a result status object once the transmission is complete or has failed. 
   * If the transmission was successful the result status object contains the number of entries included in the 
   * user data entries bucket that was transferred.
   * 
   * @param {*} dataToTransmit The bucket of user data entries with wrapping meta data ready to be sent to the callback.
   * @returns A Promise that will return a result object once the transmission is complete or has failed.
   */
  static transmitViaCallback(dataToTransmit, transmitCallback, uploaderInstance) {
    return new Promise((resolve, reject) => {
      try {
        transmitCallback(dataToTransmit);
      } catch (e) {
        console.error(`Cannot deliver ${uploaderInstance.dataType}s via callback`, e);
        throw new Error(`Cannot deliver ${uploaderInstance.dataType}s via callback`);
      }
      resolve({
        noOfEntriesTransmitted: dataToTransmit[`${UserDataUploader.getDataType(uploaderInstance.dataType)}EntriesList`].length
      })
    });

  }

  static getDataType = dataType => (dataType === "recording" ? dataType : "log")


  /**
   * Initiate transmission of the given user data entries bucket via a HTTP PUT request.
   * 
   * The method returns a Promise that will return a result status object once the transmission is complete or has failed. 
   * If the transmission was successful the result status object contains the number of entries included in the 
   * user data entries bucket that was transferred.
   * 
   * @param {*} dataToTransmit The bucket of user data entries with wrapping meta data ready to be sent to the server.
   * @param {*} axiosInstance The instance of the axios transmission library to put the user data entries to.
   * @returns A Promise that will return a result object once the transmission is complete or has failed.
   */
  static transmitViaHttp(dataToTransmit, axiosInstance, uploaderInstance) {
    const nbOfEntriesInTransmission = dataToTransmit[`${UserDataUploader.getDataType(uploaderInstance.dataType)}EntriesList`].length;
    return axiosInstance.post('', dataToTransmit)
      .then(response => (
        {
          noOfEntriesTransmitted: nbOfEntriesInTransmission
        }
      ))
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(error.response.status, `Error: Cannot deliver ${uploaderInstance.dataType}s to URL ${error.response.config.url}`);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
        throw new Error(`Cannot deliver ${uploaderInstance.dataType}s to server.`);
      });
  }


  /**
   * Initiate transmission of the given user data entries bucket to the console.
   * 
   * The method returns a Promise that will return a result status object once the transmission is complete or has failed. 
   * If the transmission was successful the result status object contains the number of entries included in the 
   * user data entries bucket that was transferred.
   * 
   * @param {*} dataToLog The bucket of user data entries with wrapping meta data ready to be user dataged to the console.
   * @returns A Promise that will return a result object once the transmission is complete or has failed.
   */
  static transmitViaConsole(dataToLog, uploaderInstance) {
    const entriesList = dataToLog[`${UserDataUploader.getDataType(uploaderInstance.dataType)}EntriesList`]
    return new Promise((resolve, reject) => {
      try {
        const capitalizedDataType = StringUtils.toFirstUpper(uploaderInstance.dataType);
        console.log(`${capitalizedDataType} message sent to console: `, dataToLog);
      } catch (e) {
        console.error(`Cannot deliver ${uploaderInstance.dataType}s to console`, e);
        throw new Error(`Cannot deliver ${uploaderInstance.dataType}s to console`);
      }
      resolve({
        noOfEntriesTransmitted: entriesList.length
      })
    });

  }

}
