import StateManagerHelper from "./StateManagerHelper";

export default class TaskNavigatorStateManager {

  constructor() {
    this.testEntriesList = {};
  }

  saveTestState = (testName, state) => {
    this.testEntriesList[testName] = StateManagerHelper.deepCopy(state);
  }

  getTestState = testName => StateManagerHelper.deepCopy(this.testEntriesList[testName])

}
