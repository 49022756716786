import RenderingHelper from "../components/RenderingHelper";
import Utils from "../utils/Utils";
import StateManagerHelper from "./StateManagerHelper";

export default class VariableManager {

  constructor() {
    this.variableMap = {};
    this.variableObservers = [];
  }

  /**
   * Initialize all variables when starting a task
   * 
   * @param {String} taskPath the task path for which to init variables
   * @param {*} variablesConfig the configuration object
   * @param {*} runtime runtime object
   */
  initializeVariablesForTask(taskPath, variablesConfig, runtime) {
    // do not initialize for existing taskPath or no variable config
    if (variablesConfig && !this.variableMap[taskPath]) {
      variablesConfig.forEach((variableConfig) => {
        const { name, value, type } = variableConfig;
        this.setVariable(taskPath, name, value, type, runtime)
      });
    }
  }

  /**
   * 
   * Get the variable value from a specific task
   * Flag could be provided to get all data (type, name, value)
   * 
   * @param {String} taskPath the task path where the variable is located
   * @param {String} variableName the variable name 
   * @param {Boolean} getFullData flag to specify whether to provide the full object or not
   * @returns {Object | String | Number | Boolean} Variable object or value
   */
  getVariable(taskPath, variableName, getFullData) {

    const taskEntry = this.variableMap[taskPath];
    if (taskEntry === undefined) {
      return undefined;
    }

    const variableEntry = taskEntry[variableName];
    if (variableEntry === undefined) {
      return undefined;
    }

    return getFullData ? variableEntry : variableEntry.value;
  }

  /**
   * Set the variable value from a specific task
   * 
   * - check whether var exists already
   * - if variable exists:
   *   + type of value is compatible with type of variable
   *   + if type is given it must match the existing type
   * - if variable does not exist:
   *   + type must be specified
   *   + type of value is compatible with type of variable
   * 
   * @param {String} taskPath the task path where the variable is located
   * @param {String} name the variable name 
   * @param {*} value value to set
   * @param {String} type variable type 
   * @param {*} runtime runtime object
   */
  setVariable(taskPath, name, value, type, runtime) {
    if (!this.variableMap[taskPath]) {
      this.variableMap[taskPath] = {};
    }

    const oldVariable = this.variableMap[taskPath][name];

    if (oldVariable) {
      if (!Utils.checkType(value, oldVariable.type)) {
        console.error(`Provided variable value is of the wrong type. Expected ${oldVariable.type}`);
        return;
      }

      if (type && type !== oldVariable.type) {
        console.error(`Provided type is wrong. Expected ${oldVariable.type}`);
        return;
      }

      // all checks passed, set variable value
      this.variableMap[taskPath][name].value = value;
    } else {
      if (!type) {
        console.error('Provided variable must have a type');
        return;
      }

      if (!Utils.checkType(value, type)) {
        console.error(`Provided variable is of the wrong type. Expected ${type}`);
        return;
      }

      // all checks passed, set variable value
      this.variableMap[taskPath][name] = {
        name,
        value,
        type
      };
    }

    this.variableObservers
      .filter(entry => entry.variableName === name)
      .forEach((entry) => {
        RenderingHelper.triggerRenderingViaPath(entry.indexPath, runtime);
      })
  }

  /**
   * 
   * @param {*} taskPath the task path where the variables are located
   * @returns {Object} a copy of the variableMap for that task VariableValuesMap
   */
  getVariableValuesMapForTask(taskPath, getFullData) {
    const variableMap = this.variableMap[taskPath];
    const variableValuesMap = {};

    if (variableMap) {
      Object.keys(variableMap).forEach((name) => {
        variableValuesMap[name] = getFullData ? variableMap[name] : variableMap[name].value;
      })
    }

    return Utils.safeDeepCopy(variableValuesMap);
  }

  /**
   * Used for resetting all variables from a task
   * 
   * @param {*} taskPath the task path where the variables are located
   * @param {*} variableMap 
   */
  setVariableValuesMapForTask(taskPath, variableMap) {
    this.variableMap[taskPath] = variableMap;
  }

  /**
  * Build an array of variable/value pairs describing variables for a task
  * @param {String} taskPath the task path where the variable is located
  */
  buildVariablesInfoArrayForTask = (taskPath) => {
    const variableMap = this.variableMap[taskPath];
    const variablesArray = [];

    if (variableMap) {
      Object.keys(variableMap).forEach((variable) => {
        variablesArray.push(variableMap[variable]);
      });
    }

    return variablesArray;
  }

  /**
   * Add a display component instance as observer on variable changes. 
   * 
   * The variable manager will trigger a rerendering on the component 
   * each time the given variable changes its value.
   * 
   * @param {String} indexPath The index path of the observing component.
   * @param {String} variableName The name of the variable to be observed.
   */
  addVariableChangeObserver = (indexPath, variableName) => {
    if (this.variableObservers.find(entry => entry.indexPath === indexPath && entry.variableName === variableName) === undefined) {
      this.variableObservers.push({
        indexPath,
        variableName
      });
    }
  }

  /**
   * Drop a display component instance from the list of observers. 
   * 
   * @param {String} indexPath The index path of the observing component.
   * @param {String} variableName The name of the variable to be observed.
   */
  dropVariableChangeObserver = (indexPath, variableName) => {
    const entryIndex = this.variableObservers.findIndex(entry => entry.indexPath === indexPath && entry.variableName === variableName);
    if (entryIndex !== undefined) {
      this.variableObservers.splice(entryIndex, 1);
    }
  }

  /**
   * Drop all change observers from a path
   * 
   * @param {String} indexPath The index path of the observing component.
   */
  dropChangeObserverForPath = (indexPath) => {
    const entryList = this.variableObservers.filter(entry => entry.indexPath === indexPath);
    if (entryList !== undefined && entryList.length > 0) {
      entryList.forEach((entry) => {
        this.dropVariableChangeObserver(indexPath, entry.variableName);
      });
    }
  }

  /**
   * Triggers rerendering for all change observers
   * 
   * @param {*} runtime our runtime object
   */
  triggerRerenderForAllChangeObservers = (runtime) => {
    this.variableObservers
      .forEach((entry) => {
        RenderingHelper.triggerRenderingViaPath(entry.indexPath, runtime);
      })
  }

  /**
   * Remove all change observers
   */
  clearAllChangeObservers = () => {
    this.variableObservers = [];
  }

  /**
   * Get the full state for all existing tasks.
   * 
   * Use the result of this method as parameter to preloadTasksState to preload another instance to our current state. 
   */
   getAllTasksState = () => StateManagerHelper.deepCopy(this.variableMap);

   /**
    * Drop all tasks.
    */
   clearTasksState = () => {
     this.variableMap = {};
     this.clearAllChangeObservers();
   };

   /**
    * Preload the tasks state returned by a call to getAllTasksState.
    */
   preloadTasksState = (allTasksState) => {
     this.variableMap = StateManagerHelper.deepCopy(allTasksState);
   }


}
