/**
 * Utility class meant to store focus registration per page.
 * We store the focus in a map where the page indexPath is the key 
 * and the value is a boolean.  
 */
export default class ActionRegister {

  constructor() {
    this.focusMap = new Map();
  }

  registerFocus = (indexPath) => {
    this.focusMap.set(indexPath, true);
  }

  hasActionsRegisteredForPath = indexPath => this.focusMap.has(indexPath) && this.focusMap.get(indexPath);

}
