import CommonActionsHelper from "../CommonActionsHelper";
import TreeUtils from "./TreeUtils";

export default class TreeTraceHelper {

  /**
   * Trace tree view sort action 
  */
  static traceSort(path, columnId, sortDirection, runtime) {

    const { treeColumns } = TreeUtils.getTreeDataByPath(path, runtime);

    let columnName;
    let columnIndex;

    treeColumns.forEach((column, index) => {
      if (column.key === columnId) {
        columnName = column.label;
        columnIndex = index;
      }
    });

    const traceDetails = {
      columnName,
      columnIndex,
      sortDirection: TreeTraceHelper.getSortDirectionForTrace(sortDirection)
    }

    CommonActionsHelper.traceUserInteraction("TreeViewSort", path, traceDetails, undefined, undefined, runtime);
  }

  static getSortDirectionForTrace(sortDirection) {
    switch (sortDirection) {
      case 'ASC':
        return 'ascending';
      case 'DESC':
        return 'descending';
      case 'NONE':
        return 'none';
      default:
        return `<unknown: ${sortDirection}>`
    }
  }

  /**
   * Traces any kind of node action 
   */
  static traceNodeAction(actionType, treePath, rowData, runtime, oldValue, newValue, columnName) {
    const { isTreeView, treeViewPath, nodeType, nodePathId, nodeTypes } = rowData;
    const eventType = isTreeView ? "TreeViewNode" : "TreeNode";
    const path = isTreeView ? treeViewPath : treePath;

    let triggeredEvent;
    let nodeName;

    if (!newValue && !oldValue && !columnName) {
      nodeName = TreeUtils.getNodeName(rowData);
    }

    if (nodeType && nodeTypes) {
      const nodeTypeInstance = TreeUtils.getNodeType(nodeTypes, nodeType);
      const { deleteEvent } = nodeTypeInstance;
      triggeredEvent = TreeTraceHelper.isTriggeredEventRequired(deleteEvent, actionType) ? deleteEvent : undefined;
    }

    const traceDetails = {
      operation: actionType,
      nodeName,
      oldValue,
      newValue,
      columnName,
      nodeType,
      nodePathId,
      triggeredEvent
    }

    CommonActionsHelper.traceUserInteraction(eventType, path, traceDetails, undefined, undefined, runtime);
  }

  static isTriggeredEventRequired = (deleteEvent, operation) => deleteEvent !== undefined && deleteEvent !== "" && operation === "delete";

}
