import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ScoringModalData extends Component {

  static sortResults(unsortedList) {
    // sort by name
    return unsortedList.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  static renderDetailsTableBody(resultsList) {
    const tableValues = ScoringModalData.sortResults(resultsList);

    let tableBody = (
      <tr>
        <td className="cbaColWidth05">-</td>
        <td className="cbaColWidth25" />
        <td className="cbaColWidth05" />
        <td className="cbaColWidth30" />
        <td className="cbaColWidth35" />
      </tr>
    );

    if (tableValues !== undefined && tableValues.length > 0 && tableValues[0].name !== undefined) {
      tableBody = tableValues.map((row, i) => (
        <tr key={row.name}>
          <td className="cbaColWidth05">{i + 1}</td>
          <td className="cbaColWidth25">{row.name}</td>
          <td className="cbaColWidth05">{row.weight}</td>
          <td className="cbaColWidth30">{row.class}</td>
          <td className="cbaColWidth35">{row.resultText}</td>
        </tr>
      ));
    }
    return tableBody;
  }

  render() {
    const { currentModalContent: scoringDetails } = this.props;

    return (
      <div className="cba-modal-content">
        <table className="summary">
          <thead>
            <tr>
              <th>Score result:</th>
              <td>{scoringDetails.result ? 'true' : 'false'}</td>
              <th>Execution Time:</th>
              <td>{scoringDetails.execTime}</td>
              <th>Total hits/Total Weight:</th>
              <td>{`${scoringDetails.nbHits}/${scoringDetails.hitWeight}`}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Credit Class:</th>
              <td>{scoringDetails.creditClass}</td>
              <th>Reaction Time:</th>
              <td>{scoringDetails.reactionTime}</td>
              <th>Total misses/Total Weight:</th>
              <td>{`${scoringDetails.nbMisses}/${scoringDetails.missWeight}`}</td>
            </tr>
            <tr>
              <th>Credit Weight:</th>
              <td>{scoringDetails.creditWeight}</td>
              <th>Nb. of Interactions:</th>
              <td>{scoringDetails.nbInteractions}</td>
            </tr>
            <tr>
              <th>Result text:</th>
              <td>{scoringDetails.resultText}</td>
              <th />
              <td />
            </tr>
          </tbody>
        </table>

        <br />
        <div className="detailsWrapper">
          Hits:
          <table className="details">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Weight</th>
                <th>Class</th>
                <th>Result text</th>
              </tr>
            </thead>
            <tbody>
              {ScoringModalData.renderDetailsTableBody(scoringDetails.hitCalculations)}
            </tbody>
          </table>
          <br />
          Misses:
          <table className="details">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Weight</th>
                <th>Class</th>
                <th>Result text</th>
              </tr>
            </thead>
            <tbody>
              {ScoringModalData.renderDetailsTableBody(scoringDetails.missCalculations)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

}

ScoringModalData.propTypes = {
  currentModalContent: PropTypes.object.isRequired
}
