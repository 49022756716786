import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CbaContainer from './CbaContainer';
import CbaRectangle from './CbaRectangle';
import CbaSingleLineInputField from './CbaSingleLineInputField';
import CbaSimpleTextField from './CbaSimpleTextField';
import CbaButton from './CbaButton';
import CbaRadioButton from './CbaRadioButton';
import CbaCheckbox from './CbaCheckbox';
import CbaLink from './CbaLink';
import CbaPageArea from './CbaPageArea';
import CbaRegionMap from './CbaRegionMap';
import CbaInnerHtml from './CbaInnerHtml';
import CbaRichTextField from './CbaRichTextField/CbaRichTextField';
import CbaComboBox from './CbaComboBox';
import CbaMedia from './media/CbaMedia';
import CbaExternalPageFrame from './CbaExternalPageFrame';
import CbaTree from './CbaTree/CbaTree';
import CbaTreeChildArea from './CbaTree/CbaTreeChildArea';
import CbaTreeView from './CbaTree/CbaTreeView';
import CbaTable from './table/CbaTable';
import CbaTableCell from './table/CbaTableCell';
import CbaTableCellEditor from './table/CbaTableCellEditor';
import CbaCalculatorHistory from './calculator/CbaCalculatorHistory';
import CbaCalculatorInput from './calculator/CbaCalculatorInput';
import CbaInputField from './CbaInputField';
import CbaStopWatch from './CbaTimer/CbaStopWatch';
import CbaScaleValueInput from './variable/CbaScaleValueInput';
import CbaSpinnerValueInput from './variable/CbaSpinnerValueInput';
import CbaTimer from './CbaTimer/CbaTimer';
import CbaList from './list/CbaList';

export default class CbaInterpreter extends Component {


  static getComponentClass(componentTypeName) {
    switch (componentTypeName) {
      case 'CbaContainer':
        return CbaContainer;
      case 'CbaRectangle':
        return CbaRectangle;
      case 'CbaSingleLineInputField':
        return CbaSingleLineInputField;
      case 'CbaSimpleTextField':
        return CbaSimpleTextField;
      case 'CbaButton':
        return CbaButton;
      case 'CbaRadioButton':
        return CbaRadioButton;
      case 'CbaCheckbox':
        return CbaCheckbox;
      case 'CbaLink':
        return CbaLink;
      case 'CbaPageArea':
        return CbaPageArea;
      case 'CbaRegionMap':
        return CbaRegionMap;
      case 'CbaInnerHtml':
        return CbaInnerHtml;
      case 'CbaRichTextField':
        return CbaRichTextField;
      case 'CbaComboBox':
        return CbaComboBox;
      case 'CbaExternalPageFrame':
        return CbaExternalPageFrame;
      case 'CbaMedia':
        return CbaMedia;
      case 'CbaTree':
        return CbaTree;
      case 'CbaTreeChildArea':
        return CbaTreeChildArea;
      case 'CbaTreeView':
        return CbaTreeView;
      case 'CbaTable':
        return CbaTable;
      case 'CbaTableCell':
        return CbaTableCell;
      case 'CbaTableCellEditor':
        return CbaTableCellEditor;
      case 'CbaCalculatorInput':
        return CbaCalculatorInput;
      case 'CbaCalculatorHistory':
        return CbaCalculatorHistory;
      case 'CbaInputField':
        return CbaInputField;
      case 'CbaStopWatch':
        return CbaStopWatch;
      case 'CbaTimer':
        return CbaTimer;
      case 'CbaScaleValueInput':
        return CbaScaleValueInput
      case 'CbaSpinnerValueInput':
        return CbaSpinnerValueInput;
      case 'CbaList':
        return CbaList;
      default:
        return undefined;
    }
  }

  render() {
    const { config, path, runtime, orientation } = this.props;
    const { type, content } = config;

    // Take care: This variable has to be capitalized for <Delegate .../> to work!
    const Delegate = CbaInterpreter.getComponentClass(type);

    if (Delegate === undefined) {
      switch (type) {
        case 'PlainText':
          return React.createElement(
            'div',
            null,
            content
          );
        default:
          console.error(`Interpreter does not know component type ${type}`);
          return React.createElement(
            'div',
            null,
            `Interpreter does not know component type: ${type}`
          );
      }
    } else {
      return (
        <Delegate
          config={config.config}
          path={path}
          runtime={runtime}
          orientation={orientation}
        />
      )
    }
  }

}

CbaInterpreter.propTypes = {
  runtime: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape({
    type: PropTypes.string.isRequired,
    config: PropTypes.object,
    content: PropTypes.string
  }).isRequired,
  orientation: PropTypes.string.isRequired,
}
