import SelectionHelper from './SelectionHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';


/**
 * Helper methods to check the current 'highlight' selections
 * in a rich text field. These methods answer questions like:
 * Are there any non-blank characters selected?
 * Is a named 'text block' currently completely or partially selected?
 */
export default class TextBlockSelectHelper {

  /**
   * Is there any non-blank character inside the current selection of the given rich text field?
   * @param {*} richTextPathState The full path state of the rich text field containing the text block.
   * @param {*} richTextConfig The JSON configuration of the rich text field containing the text block.
   */
  static isSelectionContainsNonBlank(richTextPathState, richTextConfig) {
    if (richTextConfig.value === undefined) {
      console.warn("Invalid configuration found in rich text field!");
      return false;
    }

    const draftBlocks = richTextConfig.value.blocks;
    if (draftBlocks === undefined) {
      return false;
    }

    const blockKeyToIndexMap = TextBlockSelectHelper.buildBlockKeyToIndexMap(richTextConfig);
    const currentSelections = TextBlockSelectHelper.getSpansFromSelections(richTextPathState);

    return TextBlockSelectHelper.spanArrayContainsNonBlank(currentSelections, draftBlocks, blockKeyToIndexMap);
  }

  /**
   * Is the text block identified by the given text block name (at least partially) selected by the current selection 
   * in the rich text field?
   * @param {*} blockName The name of the text block to look at.
   * @param {*} richTextPathState The full path state of the rich text field containing the text block.
   * @param {*} richTextConfig The JSON configuration of the rich text field containing the text block.
   * @param {*} partial Is it sufficient to have at least one non-blank character selected of do we need a full selection of all non-blank characters?
   */
  static isTextBlockSelected(blockName, richTextPathState, richTextConfig, partial) {

    if (richTextConfig.value === undefined) {
      console.warn("Invalid configuration found in rich text field!");
      return false;
    }

    const draftBlocks = richTextConfig.value.blocks;
    if (draftBlocks === undefined) {
      return false;
    }

    const allBlocksConfig = richTextConfig.textBlocks;
    if (allBlocksConfig === undefined) {
      console.warn(`Text block with name ${blockName} not found in rich text field ${CommonConfigHelper.getIdentifyingInfoForLogMessage(richTextConfig)} (no text blocks there)`);
      return false;
    }

    const blockConfig = TextBlockSelectHelper.findBlockConfigForBlockName(blockName, richTextConfig.textBlocks);
    if (blockConfig === undefined) {
      console.warn(`Text block with name ${blockName} not found in rich text field ${CommonConfigHelper.getIdentifyingInfoForLogMessage(richTextConfig)} (no such block)`);
      return false;
    }

    const currentSelections = TextBlockSelectHelper.getSpansFromSelections(richTextPathState);

    const blockKeyToIndexMap = TextBlockSelectHelper.buildBlockKeyToIndexMap(richTextConfig);

    const { spans } = blockConfig;

    return (partial
      ? (spans.find(span => TextBlockSelectHelper.selectionsCoverANonBlankInSpan(currentSelections, span, draftBlocks, blockKeyToIndexMap)) !== undefined)
      : spans.every(span => TextBlockSelectHelper.selectionsCoverNonBlanksInSpan(currentSelections, span, draftBlocks, blockKeyToIndexMap)));
  }


  static findBlockConfigForBlockName(blockName, allBlocksConfig) {
    return allBlocksConfig.find(blockConfig => blockConfig.name === blockName)
  }

  static buildBlockKeyToIndexMap(richTextConfig) {
    return SelectionHelper.getBlockKeysToBlockIndexMap(SelectionHelper.getListOfBlockKeysInBlockOrder(richTextConfig.value.blocks));
  }


  /**
   * Return spans representing the current selections in the rich text field.
   * @param {*} richTextPathState 
   */
  static getSpansFromSelections(richTextPathState) {
    return richTextPathState.selections.map(editorSelection => TextBlockSelectHelper.getSpanFromSelection(editorSelection));
  }

  /**
   * Return a span representing the given current selection.
   * 
   * @param {{startKey, startOffset, endKey, endOffset}} currentSelection A selection object representing one area of the current selections.
   * @return {{start: { blockKey, offset}, end: {blockKey, offset}}}
   */
  static getSpanFromSelection(currentSelection) {
    return {
      start: {
        blockKey: currentSelection.startKey,
        offset: currentSelection.startOffset
      },
      end: {
        blockKey: currentSelection.endKey,
        offset: currentSelection.endOffset
      }
    };
  }

  static selectionsCoverANonBlankInSpan(selections, spanToCover, draftBlocks, blockKeyToIndexMap) {
    return selections.find((selection, index, all) => TextBlockSelectHelper.selectionCoversANonBlankInSpan(selection, spanToCover, draftBlocks, blockKeyToIndexMap)) !== undefined;
  }

  static selectionCoversANonBlankInSpan(selection, spanToCover, draftBlocks, blockKeyToIndexMap) {
    const overlap = TextBlockSelectHelper.getOverlap(selection, spanToCover, blockKeyToIndexMap);
    return overlap === undefined ? false : TextBlockSelectHelper.spanContainsNonBlank(overlap, draftBlocks, blockKeyToIndexMap);
  }


  static selectionsCoverNonBlanksInSpan(selections, spanToCover, draftBlocks, blockKeyToIndexMap) {
    //  Calculate areas in the span not covered by the selection. 
    const uncoveredAreas = TextBlockSelectHelper.calculateUncoveredAreas(selections, spanToCover, blockKeyToIndexMap);

    //  Look for non-blanks in the uncovered areas. Result is true if no non-blanks are there, otherwise false.
    return !TextBlockSelectHelper.spanArrayContainsNonBlank(uncoveredAreas, draftBlocks, blockKeyToIndexMap);
  }

  /**
   * Calculate an array of spans not covered by the given selections.
   * 
   * @param {*} selections 
   * @param {*} spanToCover 
   * @param {*} blockKeyToIndexMap 
   */
  static calculateUncoveredAreas(selections, spanToCover, blockKeyToIndexMap) {
    // Start with the complete span to cover as only element in a list of 'uncovered areas'. 
    // Loop over all selections: 
    //   Find the first uncovered area that is hit by the selection, punch out the part covered by the selection.
    //   This may trim the uncovered area or split it in a left and a right part. Replace the original uncovered area with the rests of it 
    //   in the list of uncovered areas (in correct order: left first, right after that).
    //  The loop keeps the list of uncovered areas sorted. Since the selections do not overlap we have to process 
    //  the first afflicted uncovered area only for each selection.
    let uncoveredAreas = [spanToCover];
    selections.forEach((currentSelection, index, all) => {
      uncoveredAreas = TextBlockSelectHelper.calculateUncoveredAreasBySingleSelection(currentSelection, uncoveredAreas, blockKeyToIndexMap);
    });
    return uncoveredAreas;
  }

  /**
   * Calculate an array of spans not covered by the given selection.
   * 
   * @param {*} selection 
   * @param {*} spansToCover 
   * @param {*} blockKeyToIndexMap 
   */
  static calculateUncoveredAreasBySingleSelection(selection, spansToCover, blockKeyToIndexMap) {
    // Start with the complete span to cover as only element in a list of 'uncovered areas'. 
    // Loop over all selections: 
    //   Find the first uncovered area that is hit by the selection, punch out the part covered by the selection.
    //   This may trim the uncovered area or split it in a left and a right part. Replace the original uncovered area with the rests of it 
    //   in the list of uncovered areas (in correct order: left first, right after that).
    //  The loop keeps the list of uncovered areas sorted. Since the selections do not overlap we have to process 
    //  the first afflicted uncovered area only for each selection.
    let uncoveredAreas = spansToCover;
    let hitFound = false;
    let areaIndex;
    for (areaIndex = 0; !hitFound && areaIndex < uncoveredAreas.length; areaIndex+=1) {
      const punchTarget = uncoveredAreas[areaIndex];
      const remainingAreas = TextBlockSelectHelper.punch(selection, punchTarget, blockKeyToIndexMap);
      if (remainingAreas !== undefined) {
        hitFound = true;
        uncoveredAreas = TextBlockSelectHelper.replaceEntryByArray(uncoveredAreas, areaIndex, remainingAreas);
      }
    }
    return uncoveredAreas;
  }

  static dumpObject(object) {
    return JSON.stringify(object);
  }

  static replaceEntryByArray(arrayToModify, indexToReplace, arrayToBeInserted) {
    return arrayToModify.slice(0, indexToReplace).concat(arrayToBeInserted).concat(arrayToModify.slice(indexToReplace + 1));
  }


  /**
   * Does the given span array contain any non-blank character?
   * 
   * @param {[{start: { blockKey, offset}, end: {blockKey, offset}}] } spanArray An array of spans to be checked.
   * @param {[{key, text}]} draftBlocks The text content blocks for the Draft editor.
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   */
  static spanArrayContainsNonBlank(spanArray, draftBlocks, blockKeyToIndexMap) {
    let nonBlankFound = false;
    let spanIndex;
    for (spanIndex = 0; !nonBlankFound && spanIndex < spanArray.length; spanIndex+=1) {
      if (TextBlockSelectHelper.spanContainsNonBlank(spanArray[spanIndex], draftBlocks, blockKeyToIndexMap)) {
        nonBlankFound = true;
      }
    }
    return nonBlankFound;
  }


  /**
   * Does the given span contain any non-blank character?
   * 
   * @param {{start: { blockKey, offset}, end: {blockKey, offset}} } span A span to be checked.
   * @param {[{key, text}]} draftBlocks The text content blocks for the Draft editor.
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   */
  static spanContainsNonBlank(span, draftBlocks, blockKeyToIndexMap) {
    const startKeyIndex = blockKeyToIndexMap[span.start.blockKey];
    const endKeyIndex = blockKeyToIndexMap[span.end.blockKey];

    if (startKeyIndex === endKeyIndex) {
      return TextBlockSelectHelper.stringContainsNonBlank(draftBlocks[startKeyIndex].text.substring(span.start.offset, span.end.offset));
    }

    let nonBlankFound = TextBlockSelectHelper.stringContainsNonBlank(draftBlocks[startKeyIndex].text.substring(span.start.offset));
    let keyIndex;
    for (keyIndex = startKeyIndex + 1; !nonBlankFound && keyIndex < endKeyIndex; keyIndex+=1) {
      nonBlankFound = TextBlockSelectHelper.stringContainsNonBlank(draftBlocks[keyIndex].text);
    }
    if (!nonBlankFound) {
      nonBlankFound = TextBlockSelectHelper.stringContainsNonBlank(draftBlocks[endKeyIndex].text.substring(0, span.end.offset));
    }

    return nonBlankFound;
  }

  static stringContainsNonBlank(string) {
    return /\S/.test(string);
  }

  /**
   * Punch out the given selection from the given target span. 
   * If they don't overlap return undefined. 
   * Otherwise return one or two spans that remain after punching out the given punch span from the given target span. 
   * 
   * @param {{start: { blockKey, offset}, end: {blockKey, offset}}} punchSpan A span to be punched from the target span. 
   * @param {{start: { blockKey, offset}, end: {blockKey, offset}} } targetSpan A span the punch is applied to.
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   * @returns {[{start: { blockKey, offset}, end: {blockKey, offset} }]} The rest of the target span afer punching. 
   */
  static punch(punchSpan, targetSpan, blockKeyToIndexMap) {
    const leftPunchBorder = TextBlockSelectHelper.max(punchSpan.start, targetSpan.start, blockKeyToIndexMap);
    const rightPunchBorder = TextBlockSelectHelper.min(punchSpan.end, targetSpan.end, blockKeyToIndexMap);
    if (TextBlockSelectHelper.isLessOrEqual(leftPunchBorder, rightPunchBorder, blockKeyToIndexMap)) {
      const result = [];
      if (TextBlockSelectHelper.isLess(targetSpan.start, leftPunchBorder, blockKeyToIndexMap)) {
        result.push({
          start: targetSpan.start,
          end: leftPunchBorder
        });
      }
      if (TextBlockSelectHelper.isGreater(targetSpan.end, rightPunchBorder, blockKeyToIndexMap)) {
        result.push({
          start: rightPunchBorder,
          end: targetSpan.end
        });
      }
      return result;
    } else {
      return undefined;
    }
  }

  /**
   * Calculate the overlap of two spans.
   * If they don't overlap return undefined. 
   * 
   * @param {{start: { blockKey, offset}, end: {blockKey, offset}}} oneSpan 
   * @param {{start: { blockKey, offset}, end: {blockKey, offset}} } anotherSpan 
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   * @returns {[{start: { blockKey, offset}, end: {blockKey, offset} }]} The overlap or undefined if the spans do not overlap. 
   */
  static getOverlap(oneSpan, anotherSpan, blockKeyToIndexMap) {
    const leftOverlapBorder = TextBlockSelectHelper.max(oneSpan.start, anotherSpan.start, blockKeyToIndexMap);
    const rightOverlapBorder = TextBlockSelectHelper.min(oneSpan.end, anotherSpan.end, blockKeyToIndexMap);
    return (TextBlockSelectHelper.isLessOrEqual(leftOverlapBorder, rightOverlapBorder, blockKeyToIndexMap)
      ? {
        start: leftOverlapBorder,
        end: rightOverlapBorder
      }
      : undefined);
  }


  /**
   * Return the maximum of the given span borders.
   * 
   * @param {{blockKey, offset}} one
   * @param {{blockKey, offset}} another 
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   */
  static max(one, another, blockKeyToIndexMap) {
    return TextBlockSelectHelper.isLess(one, another, blockKeyToIndexMap) ? another : one;
  }

  /**
   * Return the minimum of the given span borders.
   * 
   * @param {{blockKey, offset}} one
   * @param {{blockKey, offset}} another 
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   */
  static min(one, another, blockKeyToIndexMap) {
    return TextBlockSelectHelper.isLess(one, another, blockKeyToIndexMap) ? one : another;
  }


  /**
   * 
   * 
   * @param {{blockKey, offset}} leftBorder 
   * @param {{blockKey, offset}} rightBorder 
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   */
  static isLessOrEqual(leftBorder, rightBorder, blockKeyToIndexMap) {
    return TextBlockSelectHelper.isEqual(leftBorder, rightBorder) || TextBlockSelectHelper.isLess(leftBorder, rightBorder, blockKeyToIndexMap);
  }

  /**
   * 
   * 
   * @param {{blockKey, offset}} leftBorder 
   * @param {{blockKey, offset}} rightBorder 
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   */
  static isGreater(leftBorder, rightBorder, blockKeyToIndexMap) {
    return !TextBlockSelectHelper.isLessOrEqual(leftBorder, rightBorder, blockKeyToIndexMap);
  }

  /**
   * 
   * 
   * @param {{blockKey, offset}} leftBorder 
   * @param {{blockKey, offset}} rightBorder 
   * @param {*} blockKeyToIndexMap A map from the draft block keys to their index in the draft block array.
   */
  static isLess(leftBorder, rightBorder, blockKeyToIndexMap) {
    return TextBlockSelectHelper.isLessBlockKey(leftBorder.blockKey, rightBorder.blockKey, blockKeyToIndexMap) || (leftBorder.blockKey === rightBorder.blockKey && leftBorder.offset < rightBorder.offset);
  }

  /**
   * 
   * 
   * @param {{blockKey, offset}} leftBorder 
   * @param {{blockKey, offset}} rightBorder 
   */
  static isEqual(leftBorder, rightBorder) {
    return leftBorder.blockKey === rightBorder.blockKey && leftBorder.offset === rightBorder.offset;
  }

  static isLessBlockKey(leftKey, rightKey, blockKeyToIndexMap) {
    return blockKeyToIndexMap[leftKey] < blockKeyToIndexMap[rightKey];
  }

}
