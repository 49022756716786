import React, { Component } from 'react';
import SplitPane from 'react-split-pane';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import IndexPathHelper from '../state/IndexPathHelper';
import CbaInterpreter from './CbaInterpreter';
import { SCORING, TRACING, STATE_MACHINE, ModalDialog } from "../dialog/ModalManager";
import Utils from "../utils/Utils";
import DialogPresenter from "../dialog/DialogPresenter"
import CommonActionsHelper from './CommonActionsHelper';

export default class CbaTestPresenter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      currentModal: '',
      currentModalTitle: '',
      currentModalContent: {},
      isPaused: false
    };

    // TODO: Drop this reference in the runtime once we have merged the static modal dialogs into the DialogPresenter:
    //       The SpecialClickActionsHelper would use the DialogPresenter instead of the CbaTestPresenter then and the DialogPresenter is in the runtime already. 
    props.runtime.testPresenter = this;

  }

  openDialog = (modalType, windowTitle, currentModalContent) => {
    this.setState({
      isDialogOpen: true,
      currentModal: modalType,
      currentModalTitle: windowTitle,
      currentModalContent
    });
  }

  handleClose = () => this.setState({
    isDialogOpen: false
  });

  pause = () => this.setState({
    isPaused: true
  })

  resume = () => this.setState({
    isPaused: false
  })

  static findPageForPageName(pageName, runtime) {
    if (pageName === undefined) {
      return undefined;
    }

    const page = runtime.pageConfigurationsManager.findPage(pageName);
    if (page === undefined) {
      console.error(`Could not find page with name ${pageName}`);
    }
    return page;
  }

  static buildPathForPage(pageName, pageAreaName, runtime) {
    if (pageName === undefined) {
      return undefined;
    }

    return IndexPathHelper.appendPageSegmentToPathRoot(
      runtime.taskManager.getCurrentStatePathRoot(),
      'main',
      pageAreaName,
      IndexPathHelper.buildPageSegment(pageName)
    );
  }

  static isRightToLeftPage(page) {
    if (page === undefined) return undefined;
    return page.isRightToLeft;

  }

  static getPagePosition(page) {
    if (page === undefined) return undefined;
    if (page.content === undefined) return undefined;
    if (page.content.config === undefined) return undefined;
    return page.content.config.position;

  }

  static getPageWidth(page, defaultWidth) {
    const position = CbaTestPresenter.getPagePosition(page);
    return (position === undefined) ? defaultWidth : (position.width + position.x);
  }

  static getPageHeight(page, defaultHeight) {
    const position = CbaTestPresenter.getPagePosition(page);
    return (position === undefined) ? defaultHeight : (position.height + position.y);
  }

  static getDividerConfig(itemLayout) {
    if (itemLayout === undefined) return undefined;
    return itemLayout.divider;
  }

  static getDividerWidth(itemLayout) {
    const divider = CbaTestPresenter.getDividerConfig(itemLayout);
    if (divider === undefined) return 0;
    return divider.width === undefined ? 0 : divider.width;
  }

  static getDividerColor(itemLayout) {
    const divider = CbaTestPresenter.getDividerConfig(itemLayout);
    if (divider === undefined) return 'rgb(0,0,0,1)';
    return divider.color === undefined ? 'rgb(0,0,0,1)' : divider.color;
  }

  static getXAreaSize(itemLayout) {
    if (itemLayout === undefined) return 0;
    return itemLayout.xSize === undefined ? 0 : itemLayout.xSize;
  }


  static getXPlacement(itemLayout) {
    if (itemLayout === undefined) return "left";
    return itemLayout.xPlacement === undefined ? "left" : itemLayout.xPlacement;
  }

  static isHorizontalDivider(itemLayout) {
    const xPlacement = CbaTestPresenter.getXPlacement(itemLayout);
    return xPlacement === "top" || xPlacement === "bottom";
  }

  static isDividerMovable(itemLayout) {
    if (itemLayout === undefined) return false;
    return itemLayout.divider.moveable; // TYPO in JSON ?
  }

  static isXPageFirst(itemLayout) {
    const xPlacement = CbaTestPresenter.getXPlacement(itemLayout);
    return xPlacement === "top" || xPlacement === "left";
  }

  static getRtlOrientation(page) {
    return CbaTestPresenter.isRightToLeftPage(page) ? "right" : "left";
  }

  static getRtlDir(page, xPage, isXPageFirst) {
    if (isXPageFirst) {
      return {
        firstAreaDir: CbaTestPresenter.isRightToLeftPage(xPage) ? "rtl" : "ltr",
        secondAreaDir: CbaTestPresenter.isRightToLeftPage(page) ? "rtl" : "ltr",
      }
    } else {
      return {
        firstAreaDir: CbaTestPresenter.isRightToLeftPage(page) ? "rtl" : "ltr",
        secondAreaDir: CbaTestPresenter.isRightToLeftPage(xPage) ? "rtl" : "ltr",
      }
    }
  }

  static calculateDividerOffset(isXPageFirst, isHorizontalDivider, xAreaSize, totalHeight, totalWidth) {
    if (isXPageFirst) return xAreaSize;
    if (isHorizontalDivider) return totalHeight - xAreaSize;
    return totalWidth - xAreaSize;
  }

  render() {
    const { runtime, taskId, settings } = this.props;

    const topLevelConfiguration = runtime.presenterStateManager.getTaskState(taskId);

    const totalWidth = topLevelConfiguration !== undefined && topLevelConfiguration.itemWidth !== undefined ? topLevelConfiguration.itemWidth : 600;
    const totalHeight = topLevelConfiguration !== undefined && topLevelConfiguration.itemHeight !== undefined ? topLevelConfiguration.itemHeight : 600;

    const foundPage = CbaTestPresenter.findPageForPageName(topLevelConfiguration.standardPage, runtime);

    // Declare a dummy page if we cannot find the specified top level page due to a misconfigured item: we cannot make the generator foolproof.
    if (foundPage === undefined) {
      console.error(`Cannot find top level page ${topLevelConfiguration.standardPage} for taskId ${taskId}, showing error page now!`, topLevelConfiguration);
    }
    const standardPage = foundPage === undefined
      ? {
        content: {
          type: 'PlainText',
          content: `Cannot find page ${topLevelConfiguration.standardPage}`
        },
        isRighToLeft: false,
      }
      : foundPage


    const xPage = CbaTestPresenter.findPageForPageName(topLevelConfiguration.xPage, runtime);

    const standardPath = CbaTestPresenter.buildPathForPage(topLevelConfiguration.standardPage, 'standard', runtime);
    const xPath = CbaTestPresenter.buildPathForPage(topLevelConfiguration.xPage, 'xPage', runtime);

    const { withEditContextMenu, itemLayout } = topLevelConfiguration;

    const isHorizontalDivider = CbaTestPresenter.isHorizontalDivider(itemLayout);
    const isXPageFirst = CbaTestPresenter.isXPageFirst(itemLayout);

    const xAreaSize = CbaTestPresenter.getXAreaSize(itemLayout);
    const dividerOffset = CbaTestPresenter.calculateDividerOffset(isXPageFirst, isHorizontalDivider, xAreaSize, totalHeight, totalWidth);
    const dividerThickness = CbaTestPresenter.getDividerWidth(itemLayout);
    const dividerGrabArea = 10;
    const isDividerMovable = CbaTestPresenter.isDividerMovable(itemLayout);
    const isDividerHidden = dividerThickness === 0 && !isDividerMovable;

    const standardPageOrientation = CbaTestPresenter.getRtlOrientation(standardPage);
    const xPageOrientation = CbaTestPresenter.getRtlOrientation(xPage);

    const pagesAreaDir = CbaTestPresenter.getRtlDir(standardPage, xPage, isXPageFirst);
    const standardPageDir = standardPageOrientation === "right" ? "rtl" : "ltr";

    const itemBackgroundColor = 'rgb(192, 192, 192)';

    const noDividerStyle = {
      position: 'absolute',
      [standardPageOrientation]: 0,
      top: 0,
      width: totalWidth,
      height: totalHeight,
      overflow: "auto",
      background: itemBackgroundColor
    }

    const wrapStyle = {
      width: totalWidth,
      height: totalHeight,
      position: "absolute",
      [standardPageOrientation]: 0
    };

    const dividerStyle = {
      width: isHorizontalDivider ? "100%" : dividerThickness + dividerGrabArea,
      height: isHorizontalDivider ? dividerThickness + dividerGrabArea : "100%",
      background: CbaTestPresenter.getDividerColor(topLevelConfiguration.itemLayout),
      display: isDividerHidden ? "none" : "block",
      MozBoxSizing: "border-box",
      WebkitBoxSizing: "border-box",
      boxSizing: "border-box",
      MozBackgroundClip: "padding",
      WebkitBackgroundClip: "padding",
      backgroundClip: "padding-box",
      transition: "none"
    };

    const areaWrapStyle = {
      overflow: 'auto',
      background: itemBackgroundColor
    };

    const areaStyle = {
      position: 'absolute',
      [isXPageFirst ? xPageOrientation : standardPageOrientation]: 0,
      top: 0,
      background: itemBackgroundColor
    };

    // define fixed hot keys as key map and keyHandlers map:
    const keyMap = {
      openConfigParams: ['v+1+2'],
      cut: ['ctrl+x'],
      copy: ['ctrl+c'],
      paste: ['ctrl+v'],
      print: ['ctrl+p', 'ctrl+shift+p']
    };

    const keyHandlers = {
      openConfigParams: (event) => {
        Utils.printCbaVersion();
      },
      cut: (withEditContextMenu
        ? (event) => { runtime.clipboardManager.cut('keyboard', undefined, runtime); }
        : (event) => { }
      ),
      copy: (withEditContextMenu
        ? (event) => { runtime.clipboardManager.copy('keyboard', undefined, runtime); }
        : (event) => { }
      ),
      paste: (withEditContextMenu
        ? (event) => { runtime.clipboardManager.paste('keyboard', undefined, runtime); }
        : (event) => { }
      ),
      print: (event) => {
        event.stopPropagation();
        event.preventDefault();
      }
    };

    // add dynamically configured debugging hot keys:
    const { debugScoreHotKey, debugTraceHotKey, debugStatemachineHotKey } = settings;
    if (debugTraceHotKey !== undefined && debugTraceHotKey.length > 0) {
      keyMap.openTrace = [debugTraceHotKey];
      keyHandlers.openTrace = (event) => {
        event.preventDefault();
        const currentModalContent = runtime.traceLogBuffer;
        this.openDialog(TRACING, 'Tracing Debug Window', currentModalContent);
      }
    }
    if (debugScoreHotKey !== undefined && debugScoreHotKey.length > 0) {
      keyMap.openScoring = [debugScoreHotKey];
      keyHandlers.openScoring = (event) => {
        event.preventDefault();
        const currentModalContent = runtime.taskManager.getScoring();
        this.openDialog(SCORING, 'Scoring Debug Window', currentModalContent);
      }
    }
    if (debugStatemachineHotKey !== undefined && debugStatemachineHotKey.length > 0) {
      keyMap.openStateMachine = [debugStatemachineHotKey];
      keyHandlers.openStateMachine = (event) => {
        event.preventDefault();
        const { statemachinesManager, variableManager } = runtime;
        const currentStatemachineData = statemachinesManager.getCurrentStateMachineLeafStates();
        const currentVariableData = variableManager.buildVariablesInfoArrayForTask(taskId);

        const nullSafeStatemachineData = {
          variables: currentVariableData === undefined ? [] : currentVariableData,
          states: currentStatemachineData === undefined ? [] : currentStatemachineData,
          visitedStates: statemachinesManager.getVisitedStates(),
          raisedEvents: statemachinesManager.getRaisedEvents()
        }
        this.openDialog(STATE_MACHINE, 'State Machine Debug Window', nullSafeStatemachineData);
      }
    }

    const { isDialogOpen, currentModal, currentModalTitle, currentModalContent, isPaused } = this.state;

    return (
      <HotKeys className="appContainer" keyMap={keyMap} handlers={keyHandlers} tabIndex={1}>
        {
          (xPage === undefined
            ? (
              <div style={noDividerStyle} dir={CbaTestPresenter.isRightToLeftPage(standardPage) ? "rtl" : ""} onScroll={CommonActionsHelper.traceUserScroll(standardPath, runtime)}>
                <CbaInterpreter config={standardPage.content} path={standardPath} runtime={runtime} orientation={standardPageOrientation} />
              </div>
            )
            : (
              <div key={IndexPathHelper.getRootFromPath(standardPath)} style={wrapStyle} dir={standardPageDir} onScroll={CommonActionsHelper.traceUserScroll(standardPath, runtime, xPath, isXPageFirst)}>
                <SplitPane
                  split={isHorizontalDivider ? "horizontal" : "vertical"}
                  pane1Style={areaWrapStyle}
                  pane2Style={areaWrapStyle}
                  resizerStyle={dividerStyle}
                  minSize={0}
                  maxSize={dividerThickness === 0 ? -1 : -dividerThickness}
                  defaultSize={dividerOffset}
                  allowResize={isDividerMovable}
                >
                  <div style={areaStyle} dir={pagesAreaDir.firstAreaDir}>
                    <CbaInterpreter config={isXPageFirst ? xPage.content : standardPage.content} path={isXPageFirst ? xPath : standardPath} runtime={runtime} orientation={isXPageFirst ? xPageOrientation : standardPageOrientation} />
                  </div>

                  <div style={areaStyle} dir={pagesAreaDir.secondAreaDir}>
                    <CbaInterpreter config={isXPageFirst ? standardPage.content : xPage.content} path={isXPageFirst ? standardPath : xPath} runtime={runtime} orientation={isXPageFirst ? standardPageOrientation : xPageOrientation} />
                  </div>
                </SplitPane>
              </div>
            )
          )
        }

        <ModalDialog isOpen={isDialogOpen} closeDialog={this.handleClose} currentModal={currentModal} currentModalTitle={currentModalTitle} currentModalContent={currentModalContent} runtime={runtime} />
        <DialogPresenter runtime={runtime} findPageForPageName={CbaTestPresenter.findPageForPageName} />

        <div className={`pause-backdrop ${!isPaused ? 'display-none' : ''}`}>
          <div className="pause-button"> </div>
        </div>
      </HotKeys>
    );
  }

}

CbaTestPresenter.propTypes = {
  runtime: PropTypes.object.isRequired,
  taskId: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired
}
