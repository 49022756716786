import React from 'react';
import PropTypesHelper from '../components/PropTypesHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import RecommendationsManager from '../state/RecommendationsManager';
import Utils from "../utils/Utils";
import AutoLayoutHelper from './AutoLayoutHelper';

export default class CommonConfigHelper {


  // property checking ----------------------------------------------------------------------

  static checkPosition(position) {
    const message = (
      (position.x === undefined ? 'x value missing' : '')
      + (position.y === undefined ? 'y value missing' : '')
      + (position.width === undefined ? 'width value missing' : '')
      + (position.height === undefined ? 'height value missing' : '')
    );
    if (message !== '') {
      PropTypesHelper.raiseError(message);
    }
  }

  static checkFont(font) {
    const message = (
      (font.name === undefined ? 'font name missing' : '')
      + (font.size === undefined ? 'font size missing' : '')
    );
    if (message !== '') {
      PropTypesHelper.raiseError(message);
    }
  }

  static checkState(state) {
    const message = (
      (state.disabled === undefined ? 'disabled status missing' : '')
      + (state.selected === undefined ? 'selected status missing' : '')
      + (state.hidden === undefined ? 'hidden status missing' : '')
    );
    if (message !== '') {
      PropTypesHelper.raiseError(message);
    }
  }


  // methods to access common config values: -----------------------------

  static getDisabled(configProps) {
    return configProps.state.disabled;
  }

  static getSelected(configProps) {
    return configProps.state.selected;
  }

  static getHidden(configProps) {
    return configProps.state.hidden;
  }

  static getPosition(configProps) {
    return configProps.position;
  }

  static getUserDefinedId(configProps) {
    return configProps.userDefinedId;
  }

  static getDragAndDrop(configProps) {
    return configProps.dragAndDrop;
  }

  static getIdentifyingInfoForLogMessage(configProps) {
    const userDefinedId = CommonConfigHelper.getUserDefinedId(configProps);
    const positionString = `at (${configProps.position.x},${configProps.position.y})`;
    return (userDefinedId !== undefined ? `${userDefinedId} ` : "") + positionString;
  }

  static getObservedStatemachineVariableNames(configProps) {
    const result = [];
    if (configProps.text !== undefined
      && configProps.text.dynamic !== undefined
      && !result.includes(configProps.text.dynamic.variable)) {
      result.push(configProps.text.dynamic.variable);
    }
    if (configProps.image !== undefined
      && configProps.image.dynamic !== undefined
      && !result.includes(configProps.image.dynamic.variable)) {
      result.push(configProps.image.dynamic.variable);
    }
    return result;
  }

  // helper methods for rendering step -------------------------------------------------------

  /**
   * Calculate the text to be displayed as main content of a component. 
   * 
   * The method considers (with decreasing priority):
   *  - a 'dynamic' text setting in the configProps that binds the component to a state machine variable
   *  - the textValue attribute in the dynamic state of the component
   *  - the default value text.label given in the configProps as text.label
   *  - the empty string as default value if everything else fails
   * 
   * @param {*} configProps The standard component config properties (i.e. sibling to the type attribute).
   * @param {*} state The state of the component instance in the ComponentStateManager
   * @param {*} runtime The common runtime context structure.
   */
  static buildDisplayText(configProps, state, runtime) {

    if (configProps.text !== undefined && configProps.text.dynamic !== undefined) {
      const textFromVariable = CommonConfigHelper.getDynamicValueFromStatemachineAndMapper(configProps.text.dynamic, runtime);
      if (textFromVariable !== undefined) {
        return textFromVariable;
      }
    }

    const textFromState = StateAttributeAccess.extractTextValue(state);
    if (textFromState !== undefined) {
      return textFromState;
    }

    if (configProps.text !== undefined && configProps.text.label !== undefined) {
      return configProps.text.label;
    }

    return '';
  }

  static buildTitle(props) {
    return props.text && props.text.mouseOver !== undefined ? props.text.mouseOver : '';
  }

  static getImageTag(pathState, configProps, selected, runtime, extraStyle = {}) {
    const { displayMode } = configProps.image;
    if (displayMode !== 'left' && displayMode !== 'right' && displayMode !== 'initial') {
      return undefined;
    }

    const disabled = StateAttributeAccess.extractDisabled(pathState);
    const visited = StateAttributeAccess.extractVisited(pathState);
    const imageResource = CommonConfigHelper.getProperResourcePath(CommonConfigHelper.chooseImageOrColorVariant(configProps.image, disabled, selected, visited), runtime);
    if (imageResource === undefined) {
      return undefined;
    }

    const imageStyle = Object.assign({
      float: displayMode,
      opacity: disabled ? 0.3 : 1
    }, extraStyle);
    return <img src={imageResource} alt="" style={imageStyle} />;
  }

  static buildStyleByIndexPath(indexPath, configProps, selected, orientation, runtime) {
    const pathState = runtime.componentStateManager.findOrBuildStateForPathId(indexPath, runtime);
    const recommended = runtime.recommendationsManager.isRecommended(indexPath);
    return CommonConfigHelper.buildStyleByState(pathState, configProps, selected, orientation, recommended, runtime);
  }

  static buildAbsoluteStyleFromConfig(configProps, orientation, pathState) {
    const { position: positionInConfig } = configProps;
    const positionInState = StateAttributeAccess.extractPosition(pathState);
    const orientationValue = orientation || "left";

    return {
      position: 'absolute',
      [orientationValue]: `${positionInState.x}px`,
      top: `${positionInState.y}px`,
      width: `${positionInConfig.width}px`,
      height: `${positionInConfig.height}px`,
    }
  }

  static buildStyleByState(pathState, configProps, selected, orientation, recommended, runtime) {

    // set strictly required attributes:
    const { position: positionInConfig, font, state, cursor } = configProps;
    const paddingValue = positionInConfig.padding === undefined ? undefined : "padding"

    CommonConfigHelper.checkPosition(positionInConfig);
    CommonConfigHelper.checkFont(font);
    CommonConfigHelper.checkState(state);

    const result = Object.assign(
      AutoLayoutHelper.buildStyleForAutoLayout(configProps, orientation, pathState),
      {
        [paddingValue]: `${positionInConfig.padding}px`,
        outline: 'none',
        visibility: StateAttributeAccess.extractHidden(pathState) ? 'hidden' : 'visible',
        fontFamily: `${font.name}, Geneva, sans-serif`,
        fontSize: font.size,
        fontWeight: font.bold ? 'bold' : 'normal',
        fontStyle: font.italic ? 'italic' : 'normal',
        textDecoration: font.underlined ? 'underline' : 'none',
        textAlign: font.alignmentHorizontal
      }
    );

    // set optional attributes
    const disabled = StateAttributeAccess.extractDisabled(pathState);
    const visited = StateAttributeAccess.extractVisited(pathState);

    CommonConfigHelper.setStyleAttribute(result, "color", CommonConfigHelper.chooseImageOrColorVariant(configProps.color.text, disabled, selected, visited));

    CommonConfigHelper.setStyleAttribute(result, "cursor", CommonConfigHelper.getCursorValue(cursor, runtime));

    CommonConfigHelper.setBorderStyleAttributes(result, configProps.border, disabled, selected, visited);

    CommonConfigHelper.setBackgroundStyleAttributes(result, configProps, disabled, selected, visited, recommended, runtime);

    const rotateDegrees = configProps.transform.rotate;
    const translateX = configProps.transform.translateX !== undefined ? configProps.transform.translateX : 0;
    const translateY = configProps.transform.translateY !== undefined ? configProps.transform.translateY : 0;

    if (rotateDegrees !== undefined) {
      CommonConfigHelper.setStyleAttribute(result, "transform", `translate( ${translateX}px, ${translateY}px) rotate(  ${rotateDegrees}deg)`)
    }

    const { transformOrigin } = configProps.transform;
    if (transformOrigin !== undefined) {
      CommonConfigHelper.setStyleAttribute(result, "transformOrigin", `${transformOrigin.x}% ${transformOrigin.y}%`)
    }

    return result;
  }

  // helper methods for SVG 

  static buildStyleSvg(pathState, configProps, selected) {
    const disabled = StateAttributeAccess.extractDisabled(pathState);
    const visited = StateAttributeAccess.extractVisited(pathState);
    const result = {};
    CommonConfigHelper.setStyleAttribute(result, "fill", CommonConfigHelper.chooseImageOrColorVariant(configProps.color.background, disabled, selected, visited));
    CommonConfigHelper.setStyleAttribute(result, "stroke", CommonConfigHelper.chooseImageOrColorVariant(configProps.border.color, disabled, selected, visited));
    CommonConfigHelper.setStyleAttributeWithUnits(result, "strokeWidth", configProps.border.width, 'px');
    return result;
  }

  static buildPointsString(pointsArray) {
    return pointsArray.reduce((accumulated, child, index) => `${accumulated} ${child.x},${child.y}`, "");
  }


  // internal helper methods ------------------------------------------------------------------------------------------------------

  static getDynamicValueFromStatemachineAndMapper(dynamicConfig, runtime) {
    const { taskManager, variableManager, valueMapper } = runtime;
    const currentTaskId = taskManager.getCurrentStatePathRoot();
    const variableValue = variableManager.getVariable(currentTaskId, dynamicConfig.variable);

    if (variableValue === undefined) {
      return undefined;
    }
    if (dynamicConfig.valueMap === undefined) {
      return `${variableValue}`;
    }
    return valueMapper.getMappedValue(dynamicConfig.valueMap, variableValue);
  }

  static combine(pathState, propsState, defaultValue) {
    if (pathState !== undefined) return pathState;
    if (propsState !== undefined) return propsState;
    return defaultValue;
  }

  static setStyleAttribute(attributes, attributeName, valueOrUndefined) {
    if (valueOrUndefined !== undefined) {
      attributes[attributeName] = valueOrUndefined;
    }
  }

  static setBorderStyleAttributes(attributes, borderProps, disabled, selected, visited) {
    CommonConfigHelper.setStyleAttribute(attributes, "borderStyle", borderProps.style);
    CommonConfigHelper.setStyleAttributeWithUnits(attributes, "borderWidth", borderProps.width, 'px');
    CommonConfigHelper.setStyleAttributeWithUnits(attributes, "borderRadius", borderProps.radius, 'px');
    CommonConfigHelper.setStyleAttribute(attributes, "borderColor", CommonConfigHelper.chooseImageOrColorVariant(borderProps.color, disabled, selected, visited));

    if (borderProps.north !== undefined) {
      CommonConfigHelper.setStyleAttribute(attributes, "borderTopStyle", borderProps.north.style);
      CommonConfigHelper.setStyleAttribute(attributes, "borderTopColor", borderProps.north.color);
      CommonConfigHelper.setStyleAttributeWithUnits(attributes, "borderTopWidth", borderProps.north.width, 'px');
    }
    if (borderProps.south !== undefined) {
      CommonConfigHelper.setStyleAttribute(attributes, "borderBottomStyle", borderProps.south.style);
      CommonConfigHelper.setStyleAttribute(attributes, "borderBottomColor", borderProps.south.color);
      CommonConfigHelper.setStyleAttributeWithUnits(attributes, "borderBottomWidth", borderProps.south.width, 'px');
    }
    if (borderProps.west !== undefined) {
      CommonConfigHelper.setStyleAttribute(attributes, "borderLeftStyle", borderProps.west.style);
      CommonConfigHelper.setStyleAttribute(attributes, "borderLeftColor", borderProps.west.color);
      CommonConfigHelper.setStyleAttributeWithUnits(attributes, "borderLeftWidth", borderProps.west.width, 'px');
    }
    if (borderProps.east !== undefined) {
      CommonConfigHelper.setStyleAttribute(attributes, "borderRightStyle", borderProps.east.style);
      CommonConfigHelper.setStyleAttribute(attributes, "borderRightColor", borderProps.east.color);
      CommonConfigHelper.setStyleAttributeWithUnits(attributes, "borderRightWidth", borderProps.east.width, 'px');
    }
  }

  static setBackgroundStyleAttributes(attributes, configProps, disabled, selected, visited, recommended, runtime) {
    const { displayMode } = configProps.image;

    const imageFromVariable = (configProps.image !== undefined && configProps.image.dynamic !== undefined)
      ? CommonConfigHelper.getDynamicValueFromStatemachineAndMapper(configProps.image.dynamic, runtime)
      : undefined;

    const imageVariant = (imageFromVariable === undefined || imageFromVariable.length === 0)
      ? CommonConfigHelper.chooseImageOrColorVariant(configProps.image, disabled, selected, visited)
      : imageFromVariable;

    let backgroundImageUrl;
    if (displayMode === 'overlay') {
      backgroundImageUrl = (imageVariant === undefined) ? "none" : `url(${CommonConfigHelper.getProperResourcePath(imageVariant, runtime)})`;
    }
    const overlayType = backgroundImageUrl ? configProps.image.overlayType : undefined;

    CommonConfigHelper.setStyleAttribute(attributes, "backgroundColor",
      ((recommended === true) ? RecommendationsManager.getRecommendationColor() : CommonConfigHelper.chooseImageOrColorVariant(configProps.color.background, disabled, selected, visited)));
    CommonConfigHelper.setStyleAttribute(attributes, "backgroundImage", backgroundImageUrl);

    if (!backgroundImageUrl && !overlayType) return;

    switch (overlayType) {
      case "stretch":
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundRepeat", "no-repeat");
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundPosition", "center");
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundSize", "100% 100%");
        break;
      case "center":
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundRepeat", "no-repeat");
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundPosition", "center");
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundSize", "contain");
        break;
      case "left":
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundRepeat", "no-repeat");
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundPosition", "left");
        CommonConfigHelper.setStyleAttribute(attributes, "backgroundSize", "contain");
        break;
      default: break;
    }

  }

  static setStyleAttributeWithUnits(attributes, attributeName, valueOrUndefined, units) {
    if (valueOrUndefined !== undefined) {
      attributes[attributeName] = valueOrUndefined + units;
    }
  }

  static chooseImageOrColorVariant(variantsEntry, disabled, selected, visited) {
    if (variantsEntry === undefined) return undefined;
    const chosenVariant = CommonConfigHelper.pickVariantPropertyFromEntry(variantsEntry, disabled, selected, visited);
    return CommonConfigHelper.isBlockDefaultValue(chosenVariant) ? undefined : Utils.withDefault(chosenVariant, variantsEntry.default);
  }

  static pickVariantPropertyFromEntry(variantsEntry, disabled, selected, visited) {
    if (disabled) return variantsEntry.disabled;
    if (selected) return variantsEntry.selected;
    if (visited) return variantsEntry.visited;
    return variantsEntry.default;
  }

  static isBlockDefaultValue(variant) {
    return variant !== undefined && Utils.isEmptyObject(variant);
  }

  static getCursorValue(cursor, runtime) {
    if (cursor === undefined) return undefined;
    if (cursor.standard !== undefined) return cursor.standard;
    if (cursor.image !== undefined) return `url(${this.getProperResourcePath(cursor.image, runtime)}), auto`;
    return undefined;
  }


  static getProperResourcePath(resourceFromConfig, runtime) {
    return CommonConfigHelper.getProperResourcePathWithFlag(resourceFromConfig, 'resource', runtime);
  }

  static getProperResourcePathWithFlag(resourceFromConfig, flag, runtime) {
    if (resourceFromConfig === undefined || resourceFromConfig.length === 0) {
      return undefined;
    }

    const { taskManager } = runtime;
    let resourceFolder = '';
    switch (flag) {
      case 'resource':
        resourceFolder = taskManager.getResourcePath();
        break;
      case 'external':
        resourceFolder = taskManager.getExternalResourcePath();
        break;
      default:
        console.error(`Unknown resource path flag ${flag}, defaulting to 'resource'`);
        resourceFolder = taskManager.getResourcePath();
        break;
    }

    return `${resourceFolder}/${resourceFromConfig}`;
  }

  /**
   * Add a local path for an external resource so that an iframe can use it as internal or external url.
   * 
   * @param {*} resourceFromConfig 
   */
  static getProperResourcePathExternalResources(resourceFromConfig, runtime) {
    if (resourceFromConfig === undefined) {
      return undefined;
    } else if (resourceFromConfig.toLowerCase().startsWith("http")) {
      return resourceFromConfig;
    } else {
      return CommonConfigHelper.getProperResourcePathWithFlag(resourceFromConfig, 'external', runtime);
    }
  }

}
