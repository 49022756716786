import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrop, useDrag } from 'react-dnd'
import TreeUtils from '../TreeUtils';
import DragAndDropHelper from '../../../config/DragAndDropHelper';
import PropTypesHelper from '../../PropTypesHelper';


export default function CbaTreeHeader(props) {
  const { column } = props;
  const { mouseOver, label, font } = column;
  // const style = TreeUtils.buildTreeCellStyle(column); use this when/if generator generates the full column header style
  const style = {
    textAlign: font ? font.alignmentHorizontal : ""
  }

  const headerRef = useRef(null);

  const [collectedPropsDrop, drop] = useDrop({
    accept: `${column.parentPath}-header`,
    drop: (item) => {
      TreeUtils.handleColumnMove(column, item.id);
    },
    canDrop: item => item.id !== column.key,
    collect: monitor => ({
      dragIsOver: monitor.isOver(),
      dragCanDrop: monitor.canDrop(),
    })
  });

  const [, drag] = useDrag({
    item: {
      id: column.key,
      type: `${column.parentPath}-header`
    },
    canDrag: () => column.moveable
  });

  drag(drop(headerRef));

  DragAndDropHelper.addCanDropStyleAttributes(style, collectedPropsDrop);

  return (
    <div
      title={mouseOver}
      className="CbaTreeHeaderInner"
      style={style}
      ref={headerRef}
    >
      <span>{label}</span>
    </div>
  )
}

CbaTreeHeader.propTypes = {
  column: PropTypes.shape(PropTypesHelper.getCbaTreeColumnConfig())
}

CbaTreeHeader.defaultProps = {
  column: {
    label: "default",
    key: ""
  }
}
