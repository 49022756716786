import StateManagerHelper from "./StateManagerHelper";

/**
 * A history of triggered events for a single state machine.
 */
export default class StatemachineEventHistory {

  constructor(snapshot) {
    // all events that were given to the interpreter.gen() method (in triggerEvent)
    const initialValues = (snapshot !== undefined) ? StateManagerHelper.deepCopy(snapshot) : {
      raisedEvents: [],
      raisedEventsPerState: [],
      totalNbOfRaisedEvents: 0,
      visitedStates: [],
      valuesPerVariable: [],
      valuesPerVariableAfterLastArtificialEvent: []
    }

    this.raisedEvents = initialValues.raisedEvents;

    // all events that were raised in a given state, each entry is: {state, [events]}
    this.raisedEventsPerState = initialValues.raisedEventsPerState;

    // the total number of events raised
    this.totalNbOfRaisedEvents = initialValues.totalNbOfRaisedEvents;

    // all states that were visited
    this.visitedStates = initialValues.visitedStates;

    // all values that were assigned to a given variable, each entry is: {variable, [values]}
    this.valuesPerVariable = initialValues.valuesPerVariable;

    // the values of all variables that were assigned after the last artificial event (i.e. event name starts with dot: '.init' etc.)
    this.valuesPerVariableAfterLastArtificialEvent = initialValues.valuesPerVariableAfterLastArtificialEvent;

  }

  // ------------- public API -------------------------------------------------------------------------

  getHistoryDataForSnapshot = () => (
    StateManagerHelper.deepCopy(
      {
        raisedEvents: this.raisedEvents,
        raisedEventsPerState: this.raisedEventsPerState,
        totalNbOfRaisedEvents: this.totalNbOfRaisedEvents,
        visitedStates: this.visitedStates,
        valuesPerVariable: this.valuesPerVariable,
        valuesPerVariableAfterLastArtificialEvent: this.valuesPerVariableAfterLastArtificialEvent
      }
    )
  )


  /**
   * Report the status before processing an event.
   * 
   * @param {String} eventName The name of the event that was processed.
   * @param {[String]} activeStates The list of states that were active when the event occured.
   * @param {[{variable, value}]} variableValues The list of statemachine variables with their values when the event occured.
   */
  reportBeforeEvent = (eventName, activeStates, variableValues) => {
    this.addVisitedStates(activeStates);

    if (!StatemachineEventHistory.isArtificialEvent(eventName)) {
      this.addRaisedEvent(eventName);
      this.addRaisedEventPerStates(activeStates, eventName);

      // Insert the variable values from the end of the last preceeding event now:
      if (this.valuesPerVariableAfterLastArtificialEvent.length !== 0) {
        this.addVariableValues(this.valuesPerVariableAfterLastArtificialEvent);
        this.valuesPerVariableAfterLastArtificialEvent = [];
      }
      this.addVariableValues(variableValues);
    }
  }

  /**
   * Report the status after processing an event.
   * 
   * @param {String} eventName The name of the event that was processed.
   * @param {[String]} activeStates The list of states that were active when the event processing finished.
   * @param {[{variable, value}]} variableValues The list of statemachine variables with their values when the event processing finished.
   */
  reportAfterEvent = (eventName, activeStates, variableValues) => {
    this.addVisitedStates(activeStates);

    // If that is the last event before the next real event
    // we should put the variable values to the history. 
    // --> Remember values now and put them into history if the next event is not artificial.
    // Copy memorized entries to detach from statemachine's variable table:
    this.valuesPerVariableAfterLastArtificialEvent = variableValues.map(entry => ({
      name: entry.name,
      value: entry.value
    }));
  }


  /**
   * Get an array of all events that were raised. 
   */
  getRaisedEvents = () => this.raisedEvents.slice();

  /**
   * Get an array of all events that were raised while the given state was active.
   */
  getRaisedEventsInState = (state) => {
    const match = this.raisedEventsPerState.find(entry => entry.state === state);
    return match === undefined ? [] : match.events.slice();
  };

  /**
   * Get the total number of events that were raised. 
   */
  getTotalNbOfRaisedEvents = () => this.totalNbOfRaisedEvents;

  /**
   * Get an array of all states that were visited.
   */
  getVisitedStates = () => this.visitedStates.slice();

  /**
   * Get an array of all values that a variable had before or after triggering an event.
   */
  getValuesOfVariable = (variable) => {
    const matchInHistory = this.valuesPerVariable.find(entry => entry.name === variable);
    const matchInPendingMemory = this.valuesPerVariableAfterLastArtificialEvent.find(entry => entry.name === variable);
    if (matchInHistory === undefined && matchInPendingMemory === undefined) {
      return [];
    }
    if (matchInHistory === undefined) {
      return [matchInPendingMemory.value];
    }
    // Copy result to detach from our history:
    const result = matchInHistory.values.slice();
    if (matchInPendingMemory !== undefined && !result.includes(matchInPendingMemory.value)) {
      result.push(matchInPendingMemory.value);
    }
    return result;
  }

  // ------------ private stuff ----------------------------------------------------------------------------
  static isArtificialEvent(eventName) {
    return eventName.startsWith('.');
  }

  static isArtificialVariable(variableName) {
    return variableName.indexOf('.') !== -1;
  }

  addRaisedEvent = (event) => {
    this.totalNbOfRaisedEvents += 1;
    if (!this.raisedEvents.includes(event)) {
      this.raisedEvents.push(event);
    }
  }

  addRaisedEventPerState = (state, event) => {
    const oldEntry = this.raisedEventsPerState.find(entry => entry.state === state);
    if (oldEntry === undefined) {
      this.raisedEventsPerState.push({
        state,
        events: [event]
      })
    } else if (!oldEntry.events.includes(event)) {
      oldEntry.events.push(event);
    }
  }

  addRaisedEventPerStates = (states, event) => {
    states.forEach((state) => {
      this.addRaisedEventPerState(state, event)
    });
  }

  addVisitedState = (state) => {
    if (!this.visitedStates.includes(state)) {
      this.visitedStates.push(state);
    }
  }

  addVisitedStates = (states) => {
    states.forEach((stateToAdd) => {
      this.addVisitedState(stateToAdd)
    });
  }

  addValuePerVariable = (variable, value) => {
    if (!StatemachineEventHistory.isArtificialVariable(variable)) {
      const oldEntry = this.valuesPerVariable.find(entry => entry.name === variable);
      if (oldEntry === undefined) {
        this.valuesPerVariable.push({
          name: variable,
          values: [value]
        })
      } else if (!oldEntry.values.includes(value)) {
        oldEntry.values.push(value);
      }
    }
  }

  addVariableValues = (variableInfos) => {
    variableInfos.forEach((variableInfo) => { this.addValuePerVariable(variableInfo.name, variableInfo.value); });
  }

}
