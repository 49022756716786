import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommonConfigHelper from '../config/CommonConfigHelper';
import CommonActionsHelper from './CommonActionsHelper';
import Utils from '../utils/Utils';

export default class HeaderButtons extends Component {

  constructor(props) {
    super(props);

    // temporary rerender on click
    this.state = {
      // eslint rule malfunction, state used in on click handler
      // eslint-disable-next-line react/no-unused-state
      renderHelper: 0
    }
  }

  handleClick = (event, button, index) => {
    const { runtime } = this.props;

    // trace event in log
    const traceDetails = {
      index
    };
    CommonActionsHelper.addMouseEventDetails(event, traceDetails);
    runtime.traceLogBuffer.reportEvent('HeaderButton', new Date(), traceDetails);

    // send state machine event
    CommonActionsHelper.sendEvent(button.event, runtime);


    // temporary rerender on click
    this.setState(oldState => ({
      renderHelper: oldState.renderHelper + 1
    }
    ));
  }

  getLargestHeightFromButtons = (buttons) => {
    let largestHeight = 0;
    buttons.forEach((button) => {
      largestHeight = largestHeight < button.height ? button.height : largestHeight;
    });

    return largestHeight;
    // or return this buttons.map((button) => button.height).reduce((accumulator, currentValue) => (accumulator < currentValue ? currentValue : accumulator));
  }

  isButtonInvalid = (buttonConfig) => {
    let invalid = false;

    // check width and set default if none
    if (!buttonConfig.width || buttonConfig.width <= 0) {
      buttonConfig.width = 110;
    }
    // check height and set default if none
    if (!buttonConfig.height || buttonConfig.height <= 0) {
      buttonConfig.height = 26;
    }

    // check image and text
    if (!buttonConfig.image && !buttonConfig.text) {
      console.log("Button Config for ", buttonConfig, "  has no image or no text");
      invalid = true;
    }

    // check event
    if (!buttonConfig.event || !(typeof buttonConfig.event === 'string')) {
      console.log("Button Config for ", buttonConfig, " has invalid event");
      invalid = true;
    }

    return invalid;
  }

  buildButtons = (config, barWidth) => {
    const largestHeight = this.getLargestHeightFromButtons(config);
    let buttonTotalWidth = 0;
    const { runtime } = this.props;

    return config.map((button, index) => {
      if (this.isButtonInvalid(button)) return null;

      const buttonPadding = 5;

      const buttonStyle = {
        height: largestHeight || 32,
        width: button.width || "auto",
        overflow: "hidden",
        cursor: "pointer"
      };

      const buttonWrapStyle = {
        padding: buttonPadding,
        display: "inline-block",
        float: "right"
      };

      // handle image case
      if (button.image) {
        buttonStyle.backgroundImage = `url(${CommonConfigHelper.getProperResourcePath(button.image, runtime)})`;
        buttonStyle.backgroundSize = "contain";
        buttonStyle.backgroundPosition = "center";
        buttonStyle.backgroundRepeat = "no-repeat";
      }

      // handle drop buttons case
      buttonTotalWidth += button.width + buttonPadding * 2;
      if (buttonTotalWidth > barWidth) {
        return null;
      }

      return (
        // disabled because we don't have a unique id to use as key and also the array does not change.
        // eslint-disable-next-line react/no-array-index-key
        <span style={buttonWrapStyle} key={index}>
          <button type="button" onClick={(event) => { this.handleClick(event, button, index) }} style={buttonStyle}>{button.image ? false : button.text}</button>
        </span>
      )
    });
  }

  render() {
    const { runtime, config } = this.props;
    if (!config || !Utils.isNonEmptyArray(config)) {
      return null;
    }

    const { taskManager } = runtime;
    const { itemWidth } = taskManager.getTopLevelConfiguration();
    const wrapStyle = {
      width: itemWidth
    };

    const buttons = this.buildButtons(config, itemWidth);


    return <div style={wrapStyle} className="headerButtonsToolbar">{buttons}</div>
  }

}

HeaderButtons.propTypes = {
  runtime: PropTypes.object.isRequired,
  config: PropTypes.array.isRequired
}
