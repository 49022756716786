import CbaCalculatorEngine from '../components/calculator/CbaCalculatorEngine';

/**
 * Manager for all calculators.
 * 
 * We keep one calculator per task. 
 * We need to be informed about the currently running task to switch our 'currently active' 
 * calculator accordingly.
 */
export default class CalculatorsManager {

  constructor() {
    this.calculatorsStateList = {};
    this.currentCalculator = undefined;
  }

  /**
   * Inform the manager about a task switch. 
   * 
   * @param taskPath The path of the new task to switch to.
   * @param runtime The common runtime context structure. 
   */
  setOrInitializeCurrentCalculator = (taskPath, runtime) => {
    this.currentCalculator = new CbaCalculatorEngine(taskPath, runtime);
    this.restoreState(this.calculatorsStateList[taskPath]);
  }

  /** 
  * @returns string to be displayed by calculator history components
  */
  getHistoryDisplayingValue = () => this.safeCall(() => this.currentCalculator.historyDisplayingValue);

  /**
   * @returns string value to be display by the calculator input
   */
  getDisplayingValue = () => this.safeCall(() => this.currentCalculator.displayingValue);

  /**
   * @param memIdx - memory index from where to fetch data
   */
  calcGetMem = memIdx => this.safeCall(() => this.currentCalculator.calcGetMem(memIdx));

  /**
   * Calls an operation on the calculator engine.
   * @param operation - what operation to call (e.g. clear, sin, multiply etc.)
   * @param  baseOrExponentOrMemIdx - some operation need a second parameter (e.g. msave needs a memory index, log needs the base)
   * 
   * @see CBA-TutorialReference.docx for more information.
   */
  calcOp = (operation, baseOrExponentOrMemIdx) => this.safeCall(() => {
    this.currentCalculator.calcOp(operation, baseOrExponentOrMemIdx);
    this.saveState();
  })

  /**
   * Modifies the current operand of the calculator engine.
   * @param operation - what operation to be performed on current operand (e.g. 'add' – append Digits to the current operand)
   * @param  digits - what digits to append (may be optional e.g. invadd operation just reverts the sign)
   * 
   * @see CBA-TutorialReference.docx for more information.
   */
  calcOpnd = (operation, digits) => this.safeCall(() => {
    this.currentCalculator.calcOpnd(operation, digits);
    this.saveState();
  })

  /**
   * Initializes the CalculationEngine and sets parameters.  
   * 
   * @see CBA-TutorialReference.docx for more information.
   */
  calcSettings = calcEngineParams => this.safeCall(() => {
    this.currentCalculator.calcSettings(calcEngineParams);
    this.saveState();
  })

  /** 
   * @param pressedKey - KeyboardEvent.key representation as described here https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
  */
  keypress = pressedKey => this.safeCall(() => {
    this.currentCalculator.keypress(pressedKey);
    this.saveState();
  })

  /**
   * @param text - text representation of clipboard data
   */
  paste = text => this.safeCall(() => {
    this.currentCalculator.paste(text);
    this.saveState();
  });

  getStateForTask = taskPath => this.calculatorsStateList[taskPath]

  // private 

  safeCall = (func) => {
    if (this.currentCalculator !== undefined) {
      const value = func();
      this.saveState();
      return value;
    }
    return undefined;
  }


  saveState = () => {
    // cannot use ComponentStateManager
    if (this.currentCalculator !== undefined) {
      this.calculatorsStateList[this.currentCalculator.taskPath] = this.currentCalculator.getFullState();
    }
  }

  restoreState = (state) => {
    if (this.currentCalculator !== undefined) {
      if (state !== undefined) {
        this.currentCalculator.restoreState(state);
      }
    }
  }

}
