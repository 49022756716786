import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import SelectGroupHelper from './SelectGroupHelper';
import RenderingHelper from './RenderingHelper';

export default class CbaLink extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.updateStateAndTriggerRendering = this.updateStateAndTriggerRendering.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState),
      event, runtime);

    // Update the 'selected' state: 
    this.updateStateAndTriggerRendering(pathState);

    SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);

    // send statemachine event depending on old selected state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);

  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  updateStateAndTriggerRendering(pathState) {
    const { runtime, path } = this.props;
    const oldSelected = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    StateAttributeAccess.setVisited(pathState, true);

    SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);

  }

  // accesibility event for tab navigation
  onKeyDown(event) {
    if (event.keyCode === 32) {
      // space key
      this.onClickHandler(event);
    }
  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);

    const selected = SelectGroupHelper.extractSelectedState(pathState, path, runtime);

    const text = RenderingHelper.extractMultilineText(CommonConfigHelper.buildDisplayText(config, pathState, runtime));
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, selected, orientation, runtime);

    return (
      <div
        style={style}
        data-cba-id={config.pageEditId}
        title={CommonConfigHelper.buildTitle(config)}
        className="CbaLink"
      >
        <span
          onClick={this.onClickHandler}
          onKeyDown={e => this.onKeyDown(e)}
          onContextMenu={this.onContextMenuHandler}
          tabIndex={config.tabIndex}
        >
          {CommonConfigHelper.getImageTag(pathState, config, false, runtime)}
          {text}
        </span>
      </div>
    );
  }

}


CbaLink.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
