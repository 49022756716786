// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
(() => {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false, cancelable: false, detail: undefined
    };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
  return undefined;
})();

/**
 * IE11 doesn't support yet <b>.startsWith</b> that is why we need to 
 * define it ourselves in order to make the project run on IE.
 * 
 */
if (!String.prototype.startsWith) {
  // eslint-disable-next-line no-extend-native
  String.prototype.startsWith = (searchString, position) => {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}
