import CommonActionsHelper from './CommonActionsHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import RenderingHelper from './RenderingHelper';
import TableHelper from './table/TableHelper';
import PathTranslationHelper from '../state/PathTranslationHelper';
import UserDefPathHelper from '../state/UserDefPathHelper';
import IndexPathHelper from '../state/IndexPathHelper';

export default class InputComponent {

  static addAttributesToInitialState(initialState, configProps) {
    StateAttributeAccess.setTextValue(initialState, configProps.text.label === undefined ? '' : configProps.text.label);
  }

  static isValidText(text, validationPattern) {
    if (validationPattern === undefined) {
      return true;
    }
    try {
      const expression = new RegExp(`${validationPattern}`, 'm');
      return expression.test(text);
    } catch (exception) {
      console.log(`Invalid regular expression ${validationPattern} on input field -> accepting input without check.`);
      return true;
    }
  }

  static onChangeHandler(component, event, userInteraction, traceType) {
    const pathState = ComponentStateHelper.getState(component);
    const oldText = StateAttributeAccess.extractTextValue(pathState);
    const newText = event.target.value;
    const { path, config, runtime, isInEditMode } = component.props;


    const { validationPattern, sourceInputPath } = config;
    const newTextIsValid = InputComponent.isValidText(newText, validationPattern);
    const newTextValue = newTextIsValid ? newText : oldText;
    const traceDetails = (userInteraction === 'ValueInputModified')
      ? {
        newValue: newText,
      }
      : {
        oldTextValue: oldText,
        newTextValue,
        origin: 'keyboard',
        validationPattern,
        invalidTextValue: newTextIsValid ? undefined : newText
      };
    // do not trace in for table cells
    if (!isInEditMode) {
      CommonActionsHelper.traceUserInteraction(
        userInteraction, path,
        traceDetails,
        event,
        {
          type: traceType,
          value: path
        },
        runtime
      );
    }

    // update our full state in state manager:
    // handle variable value input
    if (config.isVariableValueInput) {
      if (newText === '' || newText === '-') {
        component.blockedDynamicUpdateText = newText;
      } else {
        component.blockedDynamicUpdateText = undefined;
        if (InputComponent.isValidText(newText, '^[\\-]?[0-9]+$')) {
          const { taskManager, variableManager } = runtime;
          const currentTaskId = taskManager.getCurrentStatePathRoot();

          const variableName = config.text.dynamic.variable;
          const variableValue = parseInt(newText, 10);

          variableManager.setVariable(currentTaskId, variableName, variableValue, "integer", runtime);
        }
      }

    } else {
      StateAttributeAccess.setTextValue(pathState, newTextValue);
      if (sourceInputPath !== undefined) {
        runtime.eventEmitter.emit(`inputFieldUpdated-${sourceInputPath}`, newTextValue);
      }
    }
    StateAttributeAccess.setVisited(pathState, true);
    ComponentStateHelper.registerState(component, pathState);

    // trigger rendering by updating component's local state:
    RenderingHelper.triggerRendering(component);
  }

  static onClickHandler(component, event) {
    const { runtime, config, path, isInEditMode } = component.props;
    const readOnly = config.readOnly === undefined ? false : config.readOnly;
    CommonActionsHelper.processSelectedTextForCutAndPaste(path, event, readOnly, InputComponent.updateTextValue, component, runtime);
    InputComponent.registerAsInsertPosition(component, event);
    const pathState = ComponentStateHelper.getState(component);
    const currentText = StateAttributeAccess.extractTextValue(pathState);
    const traceDetails = (config.isVariableValueInput === true)
      ? {}
      : {
        currentTextValue: currentText
      }
    // do not trace in trace log for table cells
    if (!isInEditMode) {
      CommonActionsHelper.doStandardOnClick(event, traceDetails, component);
    } else {
      // when double click is made in a table cell we need to avoid propagation
      const { row, column } = config;
      const tablePath = TableHelper.buildTablePath(path);
      const tableUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(tablePath, runtime);
      const oldSelected = TableHelper.isOldSelected(tableUserDefIdPath, runtime);
      const cellTraceDetails = {
        tableUserDefIdPath,
        tableUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(tableUserDefIdPath),
        row,
        column,
        oldSelected
      }

      const cellPath = IndexPathHelper.dropIndexFromPageSegment(path);
      CommonActionsHelper.traceUserInteractionPerConfig(config, cellPath,
        cellTraceDetails,
        event, runtime);
      // avoid container tracing 
      CommonActionsHelper.stopEventPropagation(event);
    }
  }

  static onContextMenuHandler(component, event) {
    CommonActionsHelper.doContextMenuOpen(component, event);
  }

  static onSelectionHandler(component, event) {
    const { runtime, path, config } = component.props;
    const readOnly = config.readOnly === undefined ? false : config.readOnly;
    CommonActionsHelper.processSelectedTextForCutAndPaste(path, event, readOnly, InputComponent.updateTextValue, component, runtime);
    InputComponent.registerAsInsertPosition(component, event);
    CommonActionsHelper.stopEventPropagation(event);
  }

  static registerAsInsertPosition = (component, event) => {
    const { runtime, path, config } = component.props;
    const { selectionStart, selectionEnd } = event.target;
    const readOnly = config.readOnly === undefined ? false : config.readOnly;
    if (!readOnly) {
      runtime.clipboardManager.registerInsertPosition(
        path,
        contentToInsert => InputComponent.updateTextValue(component, selectionStart, selectionEnd, contentToInsert)
      );
    }
  }

  static onBlurHandler(component) {
    CommonActionsHelper.doStandardOnBlur(component);
  }

  static onFocusHandler(component) {
    CommonActionsHelper.doStandardOnFocus(component);
  }

  /* disable the cut copy paste default browser handling of events */
  static onCutCopyPaste(component, event) {
    event.preventDefault();
    return false;
  }

  static updateTextValue = (component, startPosition, endPosition, replacementText) => {
    // component = this;
    const pathState = ComponentStateHelper.getState(component);
    const oldText = StateAttributeAccess.extractTextValue(pathState);
    const newText = `${oldText.substring(0, startPosition)}${replacementText}${oldText.substring(endPosition)}`;

    const { config, runtime, path, isInEditMode } = component.props;
    const { validationPattern, trace } = config;
    const newTextIsValid = InputComponent.isValidText(newText, validationPattern);
    const eventType = trace.type === 'SingleLineInputField' ? 'SingleLineInputFieldModified' : 'InputFieldModified'

    // do not trace in table cell edit mode
    if (!isInEditMode) {
      CommonActionsHelper.traceUserInteraction(
        eventType, path,
        {
          oldTextValue: oldText,
          newTextValue: newTextIsValid ? newText : oldText,
          origin: 'cutAndPaste',
          validationPattern,
          invalidTextValue: newTextIsValid ? undefined : newText
        },
        undefined,
        undefined,
        runtime
      );
    }

    if (newTextIsValid) {
      // update our full state in state manager:
      StateAttributeAccess.setTextValue(pathState, newText);
      ComponentStateHelper.registerState(component, pathState);
      RenderingHelper.triggerRendering(component);
    }

  }

}
