import 'babel-polyfill';
import scxml from '../../node_modules/scxml/dist/scxml';
import TermEvaluator from '../eval/TermEvaluator';
import TimedEventsCatalog from './TimedEventsCatalog';
import StatemachineEventHistory from './StatemachineEventHistory';
import StateManagerHelper from './StateManagerHelper';

/**
 * A state machine for a single task.
 * 
 * Our implementation is a wrapper around the SCXML state machine 
 * provided by the SCXML library.
 * 
 * This wrapper transforms our state machine model configuration
 * to the configuration exepected by the SCXML implementation 
 * and provides additional functionality: 
 *  - state machine variables
 *  - timed events
 */
export default class Statemachine {

  /**
   * @param {*} model The statemachine model as provided by the item config structure.
   * @param {*} timedEventDefaults A map eventName -> interval of changes for event intervals that should override the intervals given in the model.
   * @param {*} statePageMapDefaults A map stateName -> {pageName, areaType, areaName} that defines an (optional) page switch assigned to each state.
   * @param {*} snapshot An (optional) snapshot to continue the state machine where we left off the last time. Use the result of a getSnapshot() call.
   * @param {*} historySnapshot An (optional) snapshot to restore the history where we left off the last time. Use the result of a getHistoryData() call.
   * @param {*} runtime The common runtime context structure.
   */
  constructor(model, timedEventDefaults, statePageMapDefaults, snapshot, historySnapshot, runtime) {
    this.interpreter = undefined;
    this.pendingEventQueue = [];
    this.pendingTaskSwitch = undefined;
    this.timedEvents = new TimedEventsCatalog();
    this.statePageMap = Statemachine.buildStatePageMap(model.statePageAssignments, statePageMapDefaults);
    this.history = new StatemachineEventHistory(historySnapshot);
    this.running = false;
    this.paused = false;
    this.eventProcessingRuns = false;
    this.runtime = runtime;

    this.buildStateMachine(model, timedEventDefaults, runtime, snapshot);
    if (snapshot === undefined || snapshot === null) {
      this.interpreter.start();
    }
  }


  // public API ---------------------------------------------------------------------------------------------------
  /**
   * Start the state machine for the first time. 
   * 
   * The method triggers the special .init. event.
   */
  start = () => {
    this.running = true;
    this.triggerEvent(".init.");
  }

  /**
   * Stop the state machine. 
   * 
   * The method stops all timed events.
   */
  stop = () => {
    this.stopTimedEvents();
    this.running = false;
  }

  /**
   * Continue state machine (after having stopped it).
   * 
   * The method will start the timed events again.
   * The method will quit a 'paused' state implicitly.
   * 
   */
  continue = () => {
    this.running = true;
    this.paused = false;
    this.getCurrentStates().forEach((state) => {
      this.startTimedEvents(state)
    });
  }

  /**
   * Pause the state machine: Pause all timed events.
   * 
   * Use the resume method to restart the state machine execution.
   */
  pause = () => {
    this.paused = true;
    this.pauseTimedEvents();
  }

  /**
   * Resume the state machine after a pause.
   * 
   * Call this after a call to pause to resume the state machine execution.
   */
  resume = () => {
    this.resumeTimedEvents();
    this.paused = false;
  }

  /**
   * Trigger the event with the given event name.
   * 
   * The method triggers the given event and all events
   * created by executing the given event:
   * Execution of the initial event might put one or more 
   * new events on the pending events queue. 
   * We will execute these new events one by one which might
   * put more events on the queue. 
   * 
   * Event execution is blocked while the statemachine
   * is stopped or 'paused'.
   * 
   * @param {String} event Name of event to be triggered.
   */
  triggerEvent = (event) => {
    if (this.running === true && this.paused === false) {
      if (this.eventProcessingRuns) {
        this.enqueueEventLastOut(event);
      } else {

        this.eventProcessingRuns = true;
        this.runSingleEventWithHistoryReports(event);
        this.eventProcessingRuns = false;

        const pendingEventFromQueue = this.pendingEventQueue.pop();
        if (pendingEventFromQueue !== undefined) {
          this.triggerEvent(pendingEventFromQueue);
        } else if (this.pendingTaskSwitch !== undefined) {
          const switchToExecute = this.pendingTaskSwitch;
          this.pendingTaskSwitch = undefined;
          Statemachine.executePendingTaskSwitch(switchToExecute, this.runtime);

        }
      }
    } else {
      console.warn(`Event ${event} ignored since statmachine is not running currently.`);
    }
  }

  /**
   * Set a task switch call to be executed as soon as the last event in the pending events queue is finished.
   * 
   * The method will not trigger any event or the pending event queue processing. 
   */
  setPostponedTaskSwitch = (switchCall) => {
    this.pendingTaskSwitch = switchCall;
  }

  /**
   * Get the timer data for a timed event.
   * 
   * @param {String} eventName The name of the timed event.
   */
  getTimerData = (eventName) => {
    const eventEntry = this.timedEvents.getEntry(eventName);
    if (eventEntry === undefined) {
      return undefined;
    }
    return TimedEventsCatalog.getEventData(eventEntry, this.getNow());
  }

  /**
   * Set the trigger interval for a timed event.
   * 
   * @param {String} eventName The name of the timed event.
  */
  setTimedEventInterval = (eventName, interval) => {
    const eventEntry = this.timedEvents.getEntry(eventName);
    if (eventEntry !== undefined) {
      TimedEventsCatalog.setTriggerIntervalInEventEntry(
        interval,
        eventEntry
      );
    }
  }

  /**
   * Get a map of the current timed event interval settings. 
   * 
   * You may use the returned structure as timed event defaults parameter
   * in our constructor to restore the timed event intervals to 
   * the point in time of this call. 
   */
  getCurrentTimerIntervals = () => this.timedEvents.getTimerIntervals();

  /**
   * Get the full map of state page assignments. 
   * 
   * You may use the returned structure as statePageMap defaults parameter
   * in our constructor to restore the state page assignments to 
   * the point in time of this call. 
   */
  getStatePageAssignments = () => StateManagerHelper.deepCopy(this.statePageMap);

  /**
   * Set the page assignment for a state.
   * 
   * @param {String} state The name of the state to assign the page to.
   * @param {String} pageName The page to assign to the state.
   * @param {String} areaType The type of the page area that will get the new page at the state switch.
   * @param {String} areaName The name of the page area that will get the new page at the state switch.
   */
  setStatePageAssignment = (state, pageName, areaType, areaName) => {
    const oldEntryIndex = this.statePageMap.findIndex(entry => entry.state === state);
    if (oldEntryIndex === -1) {
      this.statePageMap.push({
        state, pageName, areaType, areaName
      });
    } else {
      this.statePageMap[oldEntryIndex] = {
        state, pageName, areaType, areaName
      }
    }
  }

  /**
   * Get a map of all variables with their current values. 
   * 
   * You may use the returned structure as variable default values parameter
   * in our constructor to restore the variable values to 
   * the point in time of this call. 
   */
  getCurrentVariableValues = () => {
    const { taskManager, variableManager } = this.runtime;
    const currentTaskId = taskManager.getCurrentStatePathRoot();

    const currentVariableValues = variableManager.getVariableValuesMapForTask(currentTaskId);

    return StateManagerHelper.deepCopy(currentVariableValues);
  }

  /**
   * Get the history data like raised events, variable values and visited states.
   * 
   * You may use the returned structure as parameter 
   * in our constructor to restore the statemachine history to 
   * the point in time of this call. 
   */
  getHistoryData = () => this.history.getHistoryDataForSnapshot()

  /**
   * Get the currently active leaf states.
   */
  getCurrentLeafStates = () => this.interpreter.getConfiguration();

  /**
   * Get the currently active states including non-leaf states.
   */
  getCurrentStates = () => this.interpreter.getFullConfiguration().filter(state => !state.startsWith('$generated-state-'));

  /**
   * Get the currently active states including non-leaf states.
   * 
   * States returned contain also some generated by the library : "$generated-state-0", "$generated-state-1"
   */
  getAllCurrentStates = () => this.interpreter.getFullConfiguration();

  /**
   * Get an array of all events that were raised. 
   */
  getRaisedEvents = () => this.history.getRaisedEvents();

  /**
   * Get an array of all events that were raised while the given state was active.
   */
  getRaisedEventsInState = state => this.history.getRaisedEventsInState(state);

  /**
   * Get the total number of events that were raised. 
   */
  getTotalNbOfRaisedEvents = () => this.history.getTotalNbOfRaisedEvents();

  /**
   * Get an array of all states that were visited.
   */
  getVisitedStates = () => this.history.getVisitedStates();

  /**
   * Get an array of all values that a variable had before or after triggering an event.
   */
  getValuesOfVariable = variable => this.history.getValuesOfVariable(variable);


  /**
   * Get a 'snapshot' of the state machine. 
   * 
   * You may use the returned snapshot as parameter 
   * in our constructor to restore the statemachine to 
   * the point in time of the snapshot. (Make sure you use the same model value as before!)
   */
  getSnapshot = () => StateManagerHelper.deepCopy(this.interpreter.getSnapshot());


  // private stuff -------------------------------------------------------------------------------------------------
  static buildStatePageMap(modelPageAssignments, dynamicPageAssignments) {
    const result = [];
    modelPageAssignments.forEach((value) => {
      result.push({
        state: value.state,
        pageName: value.pageName,
        areaType: value.areaType,
        areaName: value.areaName
      })
    })
    if (dynamicPageAssignments !== undefined) {
      dynamicPageAssignments.forEach((dynamicAssignment) => {
        const oldAssignmentIndex = result.findIndex(existingAssignment => existingAssignment.state === dynamicAssignment.state);
        if (oldAssignmentIndex === -1) {
          result.push({
            state: dynamicAssignment.state,
            pageName: dynamicAssignment.pageName,
            areaType: dynamicAssignment.areaType,
            areaName: dynamicAssignment.areaName
          });
        } else {
          result[oldAssignmentIndex] = {
            state: dynamicAssignment.state,
            pageName: dynamicAssignment.pageName,
            areaType: dynamicAssignment.areaType,
            areaName: dynamicAssignment.areaName
          };
        }
      })
    }
    return result;
  }

  buildStateMachine = (machineModel, timedEventDefaults, runtime, snapshot) => {
    this.timedEvents = Statemachine.buildTimedEventsCatalog(machineModel.timedEvents, machineModel.states);
    Object.keys(timedEventDefaults).forEach((event) => { this.setTimedEventInterval(event, timedEventDefaults[event]); });

    this.interpreter = new scxml.scion.Statechart(this.transformMachineModel(machineModel.states, runtime), {
      snapshot
    });

    this.interpreter.on("onError", (errorInfo) => {
      console.error(`Caught statemachine error on ${errorInfo.tagname} in line/col ${errorInfo.line}/${errorInfo.column} : ${errorInfo.reason}`);
    });
  }

  /**
   * Build our interval catalog of timed events.
   * 
   * @param {[{String, Number}]} timedEventsList The list of timed events as given in the item config structure.
   * @param {[*]} statesList The list of statemachine states as given in the item config structure.
   */
  static buildTimedEventsCatalog(timedEventsList, statesList) {
    const catalog = new TimedEventsCatalog();
    timedEventsList.forEach((event) => {
      const acceptingStatesList = Statemachine.findAcceptingStates(event.name, statesList);
      catalog.putEntry(event.name, event.interval, acceptingStatesList);
    });
    return catalog;
  }

  /**
   * Find a list of states (as list of state names) that accept the given event.
   * 
   * @param {String} eventName The name of the event to find accepting states for.
   * @param {[*]} statesList The list of statemachine states as given in the item config structure.
   */
  static findAcceptingStates(eventName, statesList) {
    const result = [];
    statesList.forEach((state) => {
      if (state.transitions !== undefined && state.transitions.some(transition => transition.event === eventName)) {
        result.push(state.id);
      }
      if (state.states !== undefined) {
        const recursionResult = this.findAcceptingStates(eventName, state.states);
        recursionResult.forEach((recursionResultState) => {
          result.push(recursionResultState);
        })
      }
    })
    return result;
  }

  transformMachineModel = (originalStates, runtime) => {
    // Transform configuration machine model to SCION machine model:
    //   Copy all arrays and attributes 1:1
    //   After that change all 
    //   - cond attributes in all transition objects
    //   - action array attributes in all transition objects
    //   For these inject function definitions calling the TermEvaluator with the terms given in the original model.
    //   Add a state entry action for each state that triggers a page switch according to our state->page map. 
    const statesCopy = Statemachine.modelCopy(originalStates);
    this.transformStates(statesCopy, "", runtime);
    return {
      states: statesCopy
    };
  }


  transformStates = (stateArray, parentStatePath, runtime) => {
    stateArray.forEach((state) => {
      const hasSubstates = state.states !== undefined && state.states.length > 0;
      const statePath = Statemachine.buildStatePath(parentStatePath, state.id);
      this.transformEntryExitActions(state, hasSubstates ? Statemachine.buildInitEventName(statePath) : undefined, runtime);
      state.transitions.forEach((transition) => {
        Statemachine.transformTransition(state, transition, parentStatePath, runtime);
      });
      // recursive call for substates
      if (hasSubstates) {
        this.transformStates(state.states, statePath, runtime);
      }
    });
  }

  static buildStatePath(parentPath, stateId) {
    return `${parentPath}.${stateId}`;
  }

  static buildInitEventName(parentStatePath) {
    return `.init${parentStatePath}`;
  }

  transformEntryExitActions = (state, initEventName, runtime) => {
    state.onEntry = this.buildOnEntryFunction(state, state.onEntry, initEventName, runtime);
    if (state.onExit !== undefined) {
      state.onExit = Statemachine.buildOnExitFunction(state, state.onExit, runtime);
    }
  }

  static transformTransition(state, transition, parentStatePath, runtime) {
    if (transition.event === undefined) {
      transition.event = Statemachine.buildInitEventName(parentStatePath);
    }
    if (transition.cond !== undefined) {
      transition.cond = Statemachine.buildConditionFunction(transition.cond, runtime);
    }
    transition.onTransition = Statemachine.buildOnTransitionFunction(state, transition.target, transition.actions, runtime);
    delete transition.actions;
  }


  buildOnEntryFunction = (state, actions, initEventName, runtime) => {
    const putEventOnQueue = this.enqueueEventFirstOut;
    const startMyTimedEvents = this.startTimedEvents;
    const stateToPageMap = this.statePageMap;
    const result = (event) => {
      if (actions !== undefined) {
        actions.forEach((action, index, all) => {
          TermEvaluator.evaluateTerm(action, runtime, [], `in-action:${event.name}[${index}]`);
        })
      }
      const assignedPageSwitch = stateToPageMap
        .filter(value => value.state === state.id)
        .map(value => ({
          pageName: value.pageName, areaType: value.areaType, areaName: value.areaName
        }))
        .find(value => true);
      if (assignedPageSwitch !== undefined) {
        runtime.taskManager.switchPage(
          assignedPageSwitch.pageName,
          undefined, undefined,
          assignedPageSwitch.areaType,
          assignedPageSwitch.areaName,
          undefined, undefined, undefined, undefined
        );
      }
      if (initEventName !== undefined) {
        putEventOnQueue(initEventName);
      }
      startMyTimedEvents(state.id);
    }
    return result;
  }

  static buildOnExitFunction(state, actions, runtime) {
    const result = (event) => {
      if (actions !== undefined) {
        actions.forEach((action, index, all) => {
          TermEvaluator.evaluateTerm(action, runtime, [], `ex-action:${event.name}[${index}]`);
        })
      }
    }
    return result;
  }


  static buildOnTransitionFunction(sourceState, targetState, actions, runtime) {
    const result = (event) => {
      if (actions !== undefined) {
        actions.forEach((action, index, all) => {
          TermEvaluator.evaluateTerm(action, runtime, [], `tr-action:${event.name}[${index}]`);
        })
      }
    }
    return result;
  }

  static buildConditionFunction(term, runtime) {
    const result = event => TermEvaluator.evaluateTerm(term, runtime, [], `event:${event.name}`);
    return result;
  }

  /**
   * (Re-)start all timed events that the given state can accept.
   * 
   * The method schedules execution of the triggerEvent method
   * using window.setTimeout(...) and registers the schedule handlers in the event catalog.
   * 
   * The method overrides the 'paused' state of the affected events.
   * 
   * @param {*} timedEventsArray 
   */
  startTimedEvents = (stateName) => {

    // stop all accepted timed events that are already running:
    this.timedEvents.findScheduledEventsByState(stateName).forEach((timedEventEntry) => {
      this.timedEvents.stopTimedEvent(timedEventEntry);
    });

    // start all accepted timed events:
    this.timedEvents.findUnscheduledEventsByState(stateName).forEach((timedEventEntry) => {
      this.timedEvents.startTimedEvent(timedEventEntry, this);
    }, this);
  }

  /**
   * Stop all currently scheduled timed events. 
   * 
   * The method uses the timed events catalog to find all schedule handles to be cleared. 
   * The method overrides the 'paused' state of the events.
   */
  stopTimedEvents = () => {
    this.timedEvents.findScheduledEvents().forEach((timedEventEntry) => {
      this.timedEvents.stopTimedEvent(timedEventEntry);
    });
  }

  /**
   * Pause all currently scheduled timed events. 
   * 
   * The method uses the timed events catalog to find all schedule handles to be paused. 
   */
  pauseTimedEvents = () => {
    const now = this.getNow();
    this.timedEvents.findScheduledEvents().forEach((timedEventEntry) => {
      this.timedEvents.pauseTimedEvent(timedEventEntry, now);
    });
  }

  /**
   * Resume all currently scheduled timed events. 
   * 
   * The method uses the timed events catalog to find all schedule handles to be resumed. 
   */
  resumeTimedEvents = () => {
    this.timedEvents.findScheduledEvents().forEach((timedEventEntry) => {
      if (TimedEventsCatalog.isPaused(timedEventEntry)) {
        this.timedEvents.resumeTimedEvent(timedEventEntry, this);
      }
    }, this);
  }

  /**
   * Run a single event on the statemachine interpreter and report 
   * the before and after states/variables to our history.
   */
  runSingleEventWithHistoryReports = (event) => {
    this.history.reportBeforeEvent(
      event,
      this.getCurrentStates(),
      this.buildVariablesInfoArray()
    )

    this.interpreter.gen({
      name: event,
      data: undefined
    });

    this.history.reportAfterEvent(
      event,
      this.getCurrentStates(),
      this.buildVariablesInfoArray()
    )
  }

  /**
   * Put an event on the 'last out' positon of our pending events queue.
   * 
   * @param {*} event Name of the event to be put on the queue. 
   */
  enqueueEventLastOut = (event) => {
    this.pendingEventQueue.unshift(event);
  }

  /**
   * Put an event on the 'first out' position of our event queue.
   * 
   * @param {String} event Name of the event to put on the queue.
   */
  enqueueEventFirstOut = (event) => {
    this.pendingEventQueue.push(event);
  }

  /**
   * Build an array of variable/value pairs describing our variables.
   */
  buildVariablesInfoArray = () => {
    const { taskManager, variableManager } = this.runtime;
    const currentTaskId = taskManager.getCurrentStatePathRoot();

    return variableManager.buildVariablesInfoArrayForTask(currentTaskId);
  }


  static executePendingTaskSwitch(switchToExecute, runtime) {
    TermEvaluator.evaluateTerm(switchToExecute, runtime, [], 'postp-task');
  }

  /**
   * Build a deep copy of the given model structure.
   */
  static modelCopy(model) {
    if (model === undefined) return undefined;
    return JSON.parse(JSON.stringify(model));
  }

  /**
   * Test hook: get Date representing 'now'.
   */
  getNow = () => new Date();

}
