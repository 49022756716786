import CbaPageArea from "./CbaPageArea";
import IndexPathHelper from '../state/IndexPathHelper';
import PathTranslationHelper from '../state/PathTranslationHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import { BOOKMARKS } from '../dialog/ModalManager';
import UserDefPathHelper from "../state/UserDefPathHelper";
import Utils from "../utils/Utils";

/**
 * Helper methods to implement special actions triggered by clicks on buttons or menu items.
 */
export default class SpecialClickActionsHelper {

  /**
   * Cut the current selection to our global clipboard.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} requestingComponentPath The index path of the calling display component instance.
   * @param {Object} runtime The common runtime context structure.
   */
  static cut(triggeringType, requestingComponentPath, runtime) {
    runtime.clipboardManager.cut(triggeringType, requestingComponentPath, runtime);
  }

  /**
   * Could we perform a cut operation currently? 
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static cutEnabled(runtime) {
    return runtime.clipboardManager.cutEnabled();
  }


  /**
   * Copy the current selection to our global clipboard.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} requestingComponentPath The index path of the calling display component instance.
   * @param {Object} runtime The common runtime context structure.
   */
  static copy(triggeringType, requestingComponentPath, runtime) {
    runtime.clipboardManager.copy(triggeringType, requestingComponentPath, runtime);
  }

  /**
   * Could we perform a copy operation currently? 
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static copyEnabled(runtime) {
    return runtime.clipboardManager.copyEnabled();
  }

  /**
   * Paste the content of the global clipboard at the current caret position.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} requestingComponentPath The index path of the calling display component instance.
   * @param {Object} runtime The common runtime context structure.
   */
  static paste(triggeringType, requestingComponentPath, runtime) {
    runtime.clipboardManager.paste(triggeringType, requestingComponentPath, runtime);
  }

  /**
   * Could we perform a paste operation currently? 
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static pasteEnabled(runtime) {
    return runtime.clipboardManager.pasteEnabled();
  }


  /**
   * Switch to the next task in the global test course.
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static nextTask(runtime) {
    runtime.taskManager.switchTaskNext();
  }

  /**
   * Could we perform a switch to the next task currently? 
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static nextTaskEnabled(runtime) {
    return runtime.taskManager.nextTaskAvailable();
  }


  /**
   * Switch to the previous task in the global test course.
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static backTask(runtime) {
    runtime.taskManager.switchTaskPrevious();
  }

  /**
   * Could we perform a switch to the previous task currently? 
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static backTaskEnabled(runtime) {
    return runtime.taskManager.previousTaskAvailable();
  }

  /**
   * Cancel the current task.
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static cancelTask(runtime) {
    runtime.taskManager.cancelTask();
  }

  /**
   * Switch to fullscreen mode.
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static startFullscreen(runtime) {
    runtime.pageEventsObserver.handleFullScreen(true);
    Utils.openFullScreen(document.documentElement);
  }

  /**
   * Leave fullscreen mode.
   * 
   * @param {Object} runtime The common runtime context structure.
   */
  static stopFullscreen(runtime) {
    runtime.pageEventsObserver.handleFullScreen(false);
    Utils.closeFullScreen();
  }

  /**
   * Close the dialog window that is the parent of the given display component instance.
   * 
   * The method silently ignores the call if the parent is not a dialog window.
   * 
   * @param {*} requestingComponentPath The index path of the display component requesting the close operation.
   * @param {Object} runtime The common runtime context structure.
   */
  static close(requestingComponentPath, runtime) {
    const pageAreaType = IndexPathHelper.getPageAreaTypeFromPath(requestingComponentPath);
    if (pageAreaType === 'main') {
      console.log('Cannot close a non-dialog page area.', requestingComponentPath);
      return;
    }

    const dialog = {
      pageAreaName: IndexPathHelper.getPageAreaNameFromPath(requestingComponentPath),
      type: pageAreaType
    }

    runtime.dialogPresenter.closeDialog(dialog);
  }

  /**
   * Switch the parent CbaPageArea's page one page back in page history.
   * 
   * The method silently ignores the call if there is no CbaPageArea in the component's ancestors path.
   * 
   * @param {*} requestingComponentPath The index path of the display component requesting the switch one page back operation.
    * @param {{receiverPath: String}} actionParam The action parameter, i.e. an optional receiver path.
   * @param {Object} runtime The common runtime context structure.
   */
  static back(requestingComponentPath, actionParam, runtime) {
    SpecialClickActionsHelper.doHistoryMoveOnAncestor(requestingComponentPath, 'back', actionParam, runtime);
  }

  /**
   * Could we perform a switch back in the page history currently? 
   * 
   * @param {*} requestingComponentPath The index path of the display component requesting the switch one page back operation.
   * @param {{receiverPath: String}} actionParam The action parameter, i.e. an optional receiver path.
   * @param {Object} runtime The common runtime context structure.
   */
  static backEnabled(requestingComponentPath, actionParam, runtime) {
    return SpecialClickActionsHelper.historyMoveEnabled(requestingComponentPath, 'back', actionParam, runtime);
  }


  /**
   * Switch the parent CbaPageArea's page one page forward in page history.
   * 
   * The method silently ignores the call if there is no CbaPageArea in the component's ancestors path.
   * 
   * @param {*} requestingComponentPath The index path of the display component requesting the switch one page back operation.
    * @param {{receiverPath: String}} actionParam The action parameter, i.e. an optional receiver path.
   * @param {Object} runtime The common runtime context structure.
   */
  static forward(requestingComponentPath, actionParam, runtime) {
    SpecialClickActionsHelper.doHistoryMoveOnAncestor(requestingComponentPath, 'forward', actionParam, runtime);
  }

  /**
   * Could we perform a switch forward in the page history currently? 
   * 
   * @param {*} requestingComponentPath The index path of the display component requesting the switch one page back operation.
   * @param {{receiverPath: String}} actionParam The action parameter, i.e. an optional receiver path.
   * @param {Object} runtime The common runtime context structure.
   */
  static forwardEnabled(requestingComponentPath, actionParam, runtime) {
    return SpecialClickActionsHelper.historyMoveEnabled(requestingComponentPath, 'forward', actionParam, runtime);
  }

  /**
   * Switch the parent CbaPageArea's page to the home page in page history.
   * 
   * The method silently ignores the call if there is no CbaPageArea in the component's ancestors path.
   * 
   * @param {*} requestingComponentPath The index path of the display component requesting the switch one page back operation.
   * @param {{receiverPath: String}} actionParam The action parameter, i.e. an optional receiver path.
   * @param {Object} runtime The common runtime context structure.
   */
  static home(requestingComponentPath, actionParam, runtime) {
    SpecialClickActionsHelper.doHistoryMoveOnAncestor(requestingComponentPath, 'home', actionParam, runtime);
  }

  /**
   * Add a bookmark for the current page in the parent CbaPageArea. 
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {*} requestingComponentPath The index path of the display component requesting the operation.
   * @param {{receiverPath: String}} actionParam The action parameter, i.e. an optional receiver path.
   * @param {Object} runtime The common runtime context structure.
   */
  static addBookmark(triggeringType, requestingComponentPath, actionParam, runtime) {
    const pageAreaPath = SpecialClickActionsHelper.getPageAreaPath(requestingComponentPath, actionParam.receiverPath);
    if (pageAreaPath === undefined) {
      console.log(`Cannot add bookmark: No receiving page area found. Receiver path in action: ${actionParam.receiverPath}, requesting component path: ${requestingComponentPath}`);
      return;
    }
    CbaPageArea.addBookmark(pageAreaPath, triggeringType, requestingComponentPath, runtime);
  }

  /**
   * Show the manage bookmark view for a CbaPageArea. 
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {*} requestingComponentPath The index path of the display component requesting the operation.
   * @param {{receiverPath: String, windowTitle: String, deleteColumnHeader: String, bookmarkColumnHeader: String okButton: String, cancelButton: String}} actionParam 
   *  The action parameter, i.e. the optional receiver path and the labels to use in the bookmark management dialog.
   * @param {Object} runtime The common runtime context structure.
   */
  static manageBookmark(triggeringType, requestingComponentPath, actionParam, runtime) {
    const pageAreaPath = SpecialClickActionsHelper.getPageAreaPath(requestingComponentPath, actionParam.receiverPath);
    const triggerUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(requestingComponentPath, runtime);
    const ownerUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(pageAreaPath, runtime)
    if (pageAreaPath === undefined) {
      console.log(`Cannot open manage bookmark dialog: No receiving page area found. Receiver path in action: ${actionParam.receiverPath}, requesting component path: ${requestingComponentPath}`);
      return;
    }

    const dialogContent = {
      pageAreaIndexPath: pageAreaPath,
      requestingComponentPath,
      runtime,
      labelConfiguration: actionParam,
      testPresenter: runtime.testPresenter
    }

    runtime.testPresenter.openDialog(BOOKMARKS, actionParam.windowTitle, dialogContent);

    runtime.traceLogBuffer.reportEvent(
      'Bookmark',
      new Date(),
      {
        triggerType: triggeringType,
        triggerIndexPath: requestingComponentPath,
        triggerUserDefIdPath,
        triggerUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(triggerUserDefIdPath),
        operation: 'manage',
        ownerIndexPath: pageAreaPath,
        ownerUserDefIdPath,
        ownerUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(ownerUserDefIdPath)
      }
    );
  }

  /**
   * Trigger a search operation.
   * 
   * @param {String} searchPatternProviderPath The index path of the text input component that contains the pattern to search for.
   * @param {String} successEvent The name of a state machine event to trigger if the search found one or more matches. 
   * @param {String} failureEvent The name of a state machine event to trigger if the search did not find any match.
   * @param {Object} runtime The common runtime context structure.
   */
  static search(searchPatternProviderPath, successEvent, failureEvent, runtime) {
    const searchPatternProviderState = runtime.componentStateManager.findOrBuildStateForPathId(searchPatternProviderPath);
    const pattern = StateAttributeAccess.extractTextValue(searchPatternProviderState);
    runtime.searchManager.setSearch(
      pattern,
      searchPatternProviderPath,
      PathTranslationHelper.getUserDefPathForIndexPath(searchPatternProviderPath, runtime),
      {
        success: successEvent,
        failure: failureEvent
      }
    );
  }

  /**
   * Trigger a ScaleVariableInput increment value
   * 
   * @param {String} attachedScale The attached ScaleVariable userDefinedId. 
   * @param {Number} incrementValue The value of increment.
   * @param {Object} runtime The common runtime context structure.
   */
  static incrementScale(requestingComponentPath, attachedScale, incrementValue, runtime) {
    const scaleValueInputPath =SpecialClickActionsHelper.buildScaleValueInput(requestingComponentPath, attachedScale);
    runtime.eventEmitter.emit(`${scaleValueInputPath}-incrementScale`, incrementValue);
  }

  // private stuff -----------------------------------------------------------------------------------------------------------

  /**
   * Common kernel of the back/forward/home methods.
   */
  static doHistoryMoveOnAncestor(requestingComponentPath, historyMove, actionParam, runtime) {
    const pageAreaPath = SpecialClickActionsHelper.getPageAreaPath(requestingComponentPath, actionParam.receiverPath);
    if (pageAreaPath === undefined) {
      console.log(`Cannot switch ${historyMove} in page history: No receiving page area found. Receiver path in action: ${actionParam.receiverPath}, requesting component path: ${requestingComponentPath}`);
      return;
    }

    // TODO: What about this asymmetry: Checking enablement via CbaPageArea but doing the switch via TaskManager.
    //  We need the rerender call in the TaskManager.doHistoryMove method. Therefore we cannot use CbaPageArea.doHistoryMove here.
    //  -> Should we add enablement checking to TaskManager? Or should we move the rendered call to CbaPageArea.soHistoryMove?
    runtime.taskManager.doHistoryMove(pageAreaPath, historyMove);
  }

  /**
   * Common kernel of the backEnabled/forwardEnabled methods.
   */
  static historyMoveEnabled(requestingComponentPath, historyMove, actionParam, runtime) {
    const pageAreaPath = SpecialClickActionsHelper.getPageAreaPath(requestingComponentPath, actionParam.receiverPath);
    if (pageAreaPath === undefined) {
      return false;
    }
    return CbaPageArea.historyMoveEnabled(pageAreaPath, historyMove, runtime);

  }

  /**
   * Internal Helper: Calculate the path of the page area component a command refers to.
   * 
   * @param {String} requestingComponentPath The component triggering the command. This provides a default path.
   * @param {String} actionParamReceiverPath The path segment specified by the command configuration.
   */
  static getPageAreaPath(requestingComponentPath, actionParamReceiverPath) {
    const pageAreaPath = actionParamReceiverPath === undefined
      ? IndexPathHelper.findPageAreaAncestorPath(requestingComponentPath)
      : IndexPathHelper.appendPageSegmentsToPath(IndexPathHelper.dropPageSegmentFromPath(requestingComponentPath), actionParamReceiverPath);
    return pageAreaPath;
  }

  static buildScaleValueInput(requestingComponentPath, attachedScale) {
    const requestingComponentPathhWithoutLastPageSegment = IndexPathHelper.dropPageSegmentFromPath(requestingComponentPath);
    return IndexPathHelper.appendPageSegmentsToPath(requestingComponentPathhWithoutLastPageSegment, attachedScale);
  }

}
