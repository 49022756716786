import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Parser as FormulaParser } from 'hot-formula-parser'
import PropTypesHelper from '../PropTypesHelper';
import RenderingHelper from '../RenderingHelper';
import CbaContainer from '../CbaContainer'
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import StateManagerHelper from '../../state/StateManagerHelper';
import TableHelper from './TableHelper';

export default class CbaTable extends Component {

  constructor(props) {
    super(props)
    this.parser = new FormulaParser();
    this.configureParser = this.configureParser.bind(this);
    this.runAllAvailableFormulas = this.runAllAvailableFormulas.bind(this);
    this.notifyUpdateCellValue = this.notifyUpdateCellValue.bind(this);
    this.notifyShowContent = this.notifyShowContent.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
    const { config, path, runtime } = this.props;
    runtime.eventEmitter.addListener(`${path}-cellFocused`, this.onCellFocused.bind(this));

    if (config.isSpreadsheet) {
      // register to cell-focused and content update events if you are spreadsheet table
      runtime.eventEmitter.addListener(`${path}-contentUpdate`, this.onContentUpdate.bind(this));
      runtime.eventEmitter.addListener(`${path}-removeOldSelection`, this.onRemoveOldSelection.bind(this));

      // when the table is rendered we should see the values and not the formulas
      this.runAllAvailableFormulas(true);
    }
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
    const { runtime } = this.props;
    runtime.eventEmitter.removeAllListeners();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  /**
   * This method is used to configure the formula parser. 
   * There are two callbacks configured 'callCellValue' and 'callRangeValue', the first one is used to get the value for a specific cell
   * and the second one checks that the configuration of the current cell is in range.
   * @param {*} configProps 
   */
  configureParser(configProps) {
    const { parser } = this;
    const { runtime, path } = this.props;

    // When a formula contains a cell value, this event lets us
    // hook and return an error value if necessary
    this.parser.on('callCellValue', (cellCoord, done) => {
      const pathState = runtime.componentStateManager.findOrBuildStateForPathId(path, runtime);
      const data = StateAttributeAccess.extractTableData(pathState);
      const x = cellCoord.row.index + 1;
      const y = cellCoord.column.index + 1;

      // Check if I have that coordinates tuple in the table range
      if (x > configProps.rows - 1 || y > configProps.columns - 1) {
        throw this.parser.Error(parser.ERROR_NOT_AVAILABLE);
      }

      if (x < 1 || y < 1) {
        throw this.parser.Error(parser.ERROR_NOT_AVAILABLE);
      }

      // Check that the cell is not self referencing
      if (parser.cell.row === x && parser.cell.column === y) {
        throw new Error('REF');
      }

      if (!data[x] || !data[x][y]) {
        return done('');
      }

      // All fine
      return done(data[x][y]);
    });

    // When a formula contains a range value, this event lets us
    // hook and return an error value if necessary
    this.parser.on('callRangeValue', (startCellCoord, endCellCoord, done) => {
      const pathState = runtime.componentStateManager.findOrBuildStateForPathId(path, runtime);
      const selectedCell = StateAttributeAccess.extractSelectedCell(pathState);
      const data = StateAttributeAccess.extractTableData(pathState);
      const fragment = [];

      for (let row = startCellCoord.row.index + 1; row <= endCellCoord.row.index + 1; row += 1) {
        const rowData = data[row];
        const colFragment = [];
        if (rowData) {

          for (let col = startCellCoord.column.index + 1; col <= endCellCoord.column.index + 1; col += 1) {
            const cellData = rowData[col];
            let value;

            if (selectedCell.row === row && selectedCell.column === col) {
              throw new Error('REF');
            }

            if (TableHelper.isFormula(cellData)) {
              value = this.executeFormula({
                row, col
              }, cellData.slice(1)).result;
            } else {
              value = Number.parseFloat(cellData);
            }

            if (!Number.isNaN(value)) {
              colFragment.push(value);
            }
          }
        }
        fragment.push(colFragment);
      }

      if (fragment) {
        done(fragment);
      }
    });
  }

  /**
   * Used when component registers its state.
   * @param {*} initialState 
   * @param {*} configProps 
   */
  static addAttributesToInitialState(initialState, configProps) {
    StateAttributeAccess.setSelectable(initialState, configProps.selectable);
    if (configProps.isSpreadsheet) {
      initialState.tableData = CbaTable.buildInitialData(configProps);
      StateAttributeAccess.setComponentClassName(initialState, 'CbaTable');
    }
  }

  /**
   * Creates the initial Table data as an array of arrays. 
   * The cells value is represented as string if available or empty string for not available cell data.
   * @param {*} configProps 
   */
  static buildInitialData(configProps) {
    const data = [];
    for (let i = 0; i < configProps.rows; i += 1) {
      data.push([]);
    }

    configProps.cbaChildren.map((child, index) => {
      data[child.config.row].push(child.config.text.label !== undefined ? child.config.text.label : "");
      return false;
    });

    return data;
  }

  /**
   * This is callback method registered as a listener for content update events triggered from cells or cell editor. 
   * The method update the table state accordingly and runs all the formulas at the end as one or more formula could rely on the changed cell value.
   * @param {*} value 
   * @param {*} row 
   * @param {*} column 
   * @param {*} isReadOnly 
   */
  onContentUpdate(value, row, column, isReadOnly) {
    const pathState = ComponentStateHelper.getState(this);
    const data = StateAttributeAccess.extractTableData(pathState);

    let isAnUpdate = false;
    if (row !== undefined && column !== undefined) {
      if (value !== undefined && data[row][column] !== value) {
        isAnUpdate = true;
        data[row][column] = value;
        this.executeFormulaAndNotifyOthers(value, row, column, isReadOnly);
      }
    } else {
      const selectedCell = StateAttributeAccess.extractSelectedCell(pathState);
      if (selectedCell !== undefined && value !== undefined && data[selectedCell.row][selectedCell.column] !== value) {
        isAnUpdate = true;
        data[selectedCell.row][selectedCell.column] = value;
        this.executeFormulaAndNotifyOthers(value, selectedCell.row, selectedCell.column, isReadOnly);
      }

    }
    StateAttributeAccess.setTableData(pathState, data);
    ComponentStateHelper.registerState(this, pathState);

    if (isAnUpdate) {
      this.runAllAvailableFormulas();
    }
  }

  /**
   * Executes all the available formulas and notifies the cells when required.
   * @param {*} isInitialization 
   */
  runAllAvailableFormulas(isInitialization) {
    const pathState = ComponentStateHelper.getState(this);
    const data = StateAttributeAccess.extractTableData(pathState);

    for (let i = 1; i < data.length; i += 1) {
      for (let j = 1; j < data[i].length; j += 1) {
        if (TableHelper.isFormula(data[i][j])) {
          const { valueToUpdate, isNotValidFormula, formulaToShow, errorMessageToShow } = this.executeFormulaInternal(data[i][j], i, j);
          this.notifyUpdateCellValue({
            row: i, column: j
          }, valueToUpdate, isNotValidFormula, formulaToShow, errorMessageToShow, isInitialization);
        }
      }
    }
  }

  executeFormulaAndNotifyOthers(value, row, column, isReadOnly) {
    const { cellToUpdate, valueToUpdate, formulaToShow, isNotValidFormula, errorMessageToShow } = this.executeFormulaInternal(value, row, column);
    this.notifyUpdateCellValue(cellToUpdate, valueToUpdate, isNotValidFormula, formulaToShow, errorMessageToShow);
    this.notifyShowContent(formulaToShow, isNotValidFormula, isReadOnly, errorMessageToShow);
  }

  executeFormulaInternal(value, row, column) {
    const cellToUpdate = {
      row, column
    };
    let isNotValidFormula = false
    let valueToUpdate;
    let formulaToShow;
    let errorMessageToShow;
    if (TableHelper.isFormula(value)) {
      formulaToShow = value;
      const res = this.executeFormula(cellToUpdate, value.slice(1));

      if (res.error !== null) {
        isNotValidFormula = true;
        valueToUpdate = "###";
        errorMessageToShow = this.buildErrorMessage(res.error)
      } else {
        valueToUpdate = res.result;
      }
    } else {
      valueToUpdate = value;
      formulaToShow = value;
    }

    return {
      cellToUpdate,
      valueToUpdate,
      formulaToShow,
      isNotValidFormula,
      errorMessageToShow
    }
  }

  buildErrorMessage(error) {
    const { config } = this.props;
    const { divByZeroError, naNError, syntaxError, circularError } = config;
    switch (error) {
      case '#DIV/0!':
        return divByZeroError;
      case '#VALUE!':
        return naNError;
      case '#REF!':
        return circularError;
      default:
        return syntaxError;
    }
  }

  /**
   * This method handles a cell focused event. 
   * It will forward address and content(value or formula) to the cell editor 
   * and will properly select the headers.
   */
  onCellFocused = (row, column, address, isReadOnly) => {
    const { config, path, runtime } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    let currentSelection = StateAttributeAccess.extractSelectedCell(pathState);
    let oldSelection = StateManagerHelper.deepCopy(currentSelection);

    if (config.isSpreadsheet) {
      const tableData = StateAttributeAccess.extractTableData(pathState);
      const tableValue = tableData[row][column];
      const { cellToUpdate, valueToUpdate, formulaToShow, isNotValidFormula, errorMessageToShow } = this.executeFormulaInternal(tableValue, row, column);
      this.notifyShowAddress(address);
      this.notifyShowContent(formulaToShow, isNotValidFormula, isReadOnly, errorMessageToShow);
      this.notifyUpdateCellValue(cellToUpdate, valueToUpdate, isNotValidFormula, formulaToShow, errorMessageToShow);
    }


    // handle header selection 
    ({ currentSelection, oldSelection } = this.handleHeaderSelection(currentSelection, row, column, oldSelection));

    StateAttributeAccess.setOldSelectedCell(pathState, oldSelection);

    if (currentSelection.row !== oldSelection.row || currentSelection.column !== oldSelection.column) {
      this.notifyRemoveLastSelection(oldSelection);
    }

    StateAttributeAccess.setOldSelectedCell(pathState, oldSelection);
    StateAttributeAccess.setSelectedCell(pathState, currentSelection);

    const oldSelected = StateAttributeAccess.extractSelected(pathState);

    StateAttributeAccess.setSelected(pathState, !oldSelected);
    runtime.componentStateManager.registerStateByPathId(path, pathState);
    RenderingHelper.triggerRenderingViaPath(path, runtime);

  }

  onRemoveOldSelection() {
    const pathState = ComponentStateHelper.getState(this);
    const oldSelection = StateAttributeAccess.extractOldSelectedCell(pathState);
    this.notifyRemoveLastSelection(oldSelection);
  }

  /**
   * Executes the formula on the `value` usign the
   * FormulaParser object
  */
  executeFormula = (cell, value) => {
    this.parser.cell = cell;
    let res = this.parser.parse(value);
    if (res.error != null) {
      return res;
    }
    if (res.result.toString() === '') {
      return res;
    }
    if (res.result.toString().slice(0, 1) === '=') {
      // formula points to formula
      res = this.executeFormula(cell, res.result.slice(1));
    }

    return res;
  }

  notifyRemoveLastSelection(oldSelection) {
    const { path, runtime } = this.props;
    runtime.eventEmitter.emit(`${path}-removeLastSelection-[${oldSelection.row},${oldSelection.column}]`);
  }

  notifyShowAddress(address) {
    const { path, runtime } = this.props;
    runtime.eventEmitter.emit(`${path}-showAddress`, address);
  }

  notifyShowContent(value, isNotValidFormula, isReadOnly, errorMessageToShow) {
    const { path, runtime } = this.props;
    runtime.eventEmitter.emit(`${path}-showContent`, value, isNotValidFormula, isReadOnly, errorMessageToShow);
  }

  notifyUpdateCellValue(cellToUpdate, value, isNotValidFormula, formulaToShow, errorMessageToShow, isInitialization) {
    const { path, runtime } = this.props;
    runtime.eventEmitter.emit(`${path}-updateCellValue`, cellToUpdate, value, isNotValidFormula, formulaToShow, errorMessageToShow, isInitialization);
  }

  /**
   * When a cell is selected its coresponding header cell will be highlighted.
   * The current method is used for that purpose, it decides if row/column header selection changed and notifies them.
   * @param {*} currentSelection 
   * @param {*} row 
   * @param {*} column 
   * @param {*} oldSelection 
   */
  handleHeaderSelection(currentSelection, row, column, oldSelection) {
    if (currentSelection === undefined && row !== undefined && column !== undefined) {
      this.notifyRowHeaderUpdate(undefined, row);
      this.notifyColumnHeaderUpdate(undefined, column);
      currentSelection = {
        row, column
      };
      oldSelection = currentSelection;
    } else if (currentSelection.row !== row && currentSelection.column !== column) {
      this.notifyRowHeaderUpdate(currentSelection.row, row);
      this.notifyColumnHeaderUpdate(currentSelection.column, column);
      currentSelection = {
        row, column
      };
    } else if (currentSelection.row !== row) {
      this.notifyRowHeaderUpdate(currentSelection.row, row);
      currentSelection.row = row;
    } else if (currentSelection.column !== column) {
      this.notifyColumnHeaderUpdate(currentSelection.column, column);
      currentSelection.column = column;
    }
    return {
      currentSelection, oldSelection
    };
  }

  notifyColumnHeaderUpdate(currentColumn, newColumn) {
    const { path, runtime } = this.props;
    runtime.eventEmitter.emit(`${path}-columnHeaderUpdate`, currentColumn, newColumn);
  }

  notifyRowHeaderUpdate(currentRow, newRow) {
    const { runtime, path } = this.props;
    runtime.eventEmitter.emit(`${path}-rowHeaderUpdate`, currentRow, newRow);
  }

  render() {
    const { runtime, config, path, orientation } = this.props;
    if (config.isSpreadsheet) {
      this.configureParser(config);
    }

    return (
      <CbaContainer
        config={config}
        path={path}
        runtime={runtime}
        orientation={orientation}
      />
    );
  }

}

CbaTable.propTypes = {
  runtime: PropTypes.object.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        rows: PropTypes.number.isRequired,
        columns: PropTypes.number.isRequired,
        selectable: PropTypes.bool.isRequired
      }
    )
  ).isRequired,
  path: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired
}
